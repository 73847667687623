import { AccountSettings } from "@shared/interfaces/account";
import { Asset } from "@shared/interfaces/asset";
import { SublyPlan } from "@shared/interfaces/billing";
import { Media, MediaComment } from "@shared/interfaces/media";

import {
  Artwork,
  AspectRatioConfig,
  AssStyle,
  Cue,
  CustomLogoConfig,
  FileLanguage,
  Order,
  Transcription
} from "@getsubly/common";

export type AudioTemplateStyle = Pick<
  AssStyle,
  "alignment" | "marginL" | "marginR" | "marginV" | "colorPrimary" | "fontSize"
>;

export type AudioTemplate = AudioTemplateStyle & {
  templateId: string;
  titles: [
    {
      style: AudioTemplateStyle;
    }
  ];
  headline?: {
    style: AudioTemplateStyle;
  };
  artwork: Artwork;
  customLogo: CustomLogoConfig;
  aspectRatio: Pick<AspectRatioConfig, "color">;
  noLogoHeadline?: Pick<AssStyle, "marginL" | "marginR" | "marginV">;
};

export enum CommentsSortType {
  Recent = "Sort by most recent",
  Timeline = "Sort by timeline"
}

export interface TranscriptionMap {
  [fileId: string]: Transcription;
}

export interface MediaSummary {
  fileId: string;
  content: string;
}

export interface MediaEditor {
  media: Media;
  account: {
    plan?: SublyPlan;
    settings: AccountSettings;
  };
  assets: Asset[];
  comments: MediaComment[];
  transcriptionFiles: MediaTranscriptions;
  transcriptions: TranscriptionMap;
  summary?: MediaSummary;
  orders: Order[];
}

export interface MediaTranscriptions {
  originalId: string;
  original?: FileLanguage;
  originalTranscriptionId: string;
  originalTranscription?: FileLanguage;
  translationsIds: string[];
  translations: FileLanguage[];
}

export enum ToolSettings {
  FindAndReplace = "Find and replace",
  ReviewAssistant = "Review assistant",
  None = "None"
}

export enum EditorMode {
  Editor = "Editor",
  Viewer = "Viewer"
}

export enum EditorPanel {
  Subtitles = "Subtitles",
  Transcription = "Transcription",
  Style = "Style",
  Text = "Text",
  AudioDescription = "Audio des.",
  Summary = "Summary",
  Snippets = "Snippets",
  Comments = "Comments",
  Elements = "Elements",
  Downloads = "Downloads"
}

export enum TranslationTool {
  Subtitles = "Subtitles",
  Transcription = "Transcription",
  CreateTranslation = "Create translation",
  RerunTranslation = "Rerun translation",
  IgnoreWords = "Ignore words",
  None = "None"
}

export type UpdateCue = (id: string, cue: Partial<Cue>) => void;
