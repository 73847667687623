import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { CardPreview } from "@frontend/components/card-preview/card-preview";
import { AddPaymentMethodModal } from "@frontend/components/modals/add-payment-method-modal";
import { SettingsCard } from "@frontend/components/settings-card/settings-card";

import { useModal } from "@shared/hooks/use-modal";
import { PaymentMethod } from "@shared/interfaces/billing";
import { ModalType } from "@shared/interfaces/modal-type";
import { Button } from "@shared/primitives/button";
import { accountQuery } from "@shared/state/account";

interface PaymentMethodCardProps {
  className?: string;
}

export const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({ className }) => {
  const [card, setCard] = useState<PaymentMethod>();
  const [loading, setLoading] = useState(false);
  const [showPaymentMethodModal, hidePaymentMethodModal] = useModal(ModalType.AddPaymentMethod);

  useEffect(() => {
    const account$ = accountQuery.select(["loading", "paymentMethods"]).subscribe(({ loading, paymentMethods }) => {
      setLoading(loading);
      setCard(paymentMethods[0]);
    });

    return () => account$.unsubscribe();
  }, []);

  return (
    <SettingsCard className={className}>
      <SettingsCard.Header className="tw-flex tw-items-center tw-justify-between">Payment method</SettingsCard.Header>
      <SettingsCard.Body className="!tw-pt-0">
        {loading && <Skeleton height={150} />}
        {!loading && (!card ? <div>No payment methods</div> : <CardPreview card={card} />)}
      </SettingsCard.Body>

      {!loading && (
        <SettingsCard.Footer>
          <Button
            variant="secondary"
            type="link"
            to="#"
            onClick={() =>
              showPaymentMethodModal(
                <AddPaymentMethodModal
                  closeModal={() => {
                    hidePaymentMethodModal();
                  }}
                  disableBackgroundDismiss={false}
                />
              )
            }
            size="32"
          >
            {!card ? (
              <div>
                <b>Add payment method</b> to keep your account active.
              </div>
            ) : (
              "Update"
            )}
          </Button>
        </SettingsCard.Footer>
      )}
    </SettingsCard>
  );
};
