import axios from "axios";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { Asset, AssetType } from "@shared/interfaces/asset";
import { assetsQuery } from "@shared/state/assets/assets.query";
import { assetsStore } from "@shared/state/assets/assets.store";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface AssetsListResponse {
  assets: Asset[];
}
export const getAssets = async (): Promise<void> => {
  if (assetsQuery.getValue().loading) {
    return;
  }
  const accountId = getAccountId();

  try {
    assetsStore.update({ loading: true });

    const { data } = await axios.get<AssetsListResponse>(`/${accountId}/assets`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    assetsStore.set(data.assets);
    assetsStore.update({ loading: false });
  } catch (error) {
    handleError(error);
  }
};

interface AssetResponse {
  asset: Asset;
}
export const uploadAsset = async ({ file, assetType }: { file: File; assetType: AssetType }): Promise<Asset> => {
  const accountId = getAccountId();
  try {
    assetsStore.update({ isUploading: true });

    const formData = new FormData();
    formData.append("file", file);
    formData.append("assetType", assetType);

    const { data } = await axios.post<AssetResponse>(`/${accountId}/assets`, formData, {
      baseURL,
      headers: {
        "Content-Type": "form-data",
        "x-access-token": await getAccessToken()
      }
    });

    assetsStore.add(data.asset);

    return data.asset;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  } finally {
    assetsStore.update({ isUploading: false });
  }
};

export const deleteAsset = async ({ assetId }: { assetId: string }): Promise<void> => {
  const accountId = getAccountId();
  try {
    await axios.delete(`/${accountId}/assets/${assetId}`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    assetsStore.remove(assetId);
  } catch (error) {
    handleError(error);
  }
};
