import React from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { GoogleButton } from "@frontend/components/buttons/google-button";
import { useAnalytics } from "@frontend/contexts/analytics.context";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { useFingerprint } from "@frontend/contexts/fingerprint.context";
import { LOGIN_PATH, PRIVACY_URL, TERMS_URL } from "@frontend/routes";

import { Logo } from "@shared/components/logo";
import { useQuery } from "@shared/hooks/use-query";
import { Alert } from "@shared/primitives/alert";
import { Button } from "@shared/primitives/button";
import { Divider } from "@shared/primitives/divider";
import { Input } from "@shared/primitives/input";
import { TextButton } from "@shared/primitives/text-button";
import { isEmbeddedWebView } from "@shared/utils/browser";
import { SublyCouponCode } from "@shared/utils/plans";
import { emailPattern } from "@shared/utils/regex-patterns";

import { ErrorMessage } from "@hookform/error-message";
import { RiKeyFill } from "@remixicon/react";

import { EmailSentView } from "./views/email-sent-view";
import { EmbeddedWebviewMsg } from "./embedded-webview-msg";

interface QueryState {
  invite?: string;
  redirect?: string;
  alertMessage?: string;
  coupon?: SublyCouponCode;
  skipFingerprint?: "true" | "false";
  email?: string;
}

export const SignUpForm: React.FC = () => {
  const { state, search } = useLocation();
  const { queryParams } = useQuery<QueryState>();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { getFingerprint } = useFingerprint();
  const { signUpWithMagicLink } = useAuthProvider();
  const { trackEvent } = useAnalytics();
  const [loading, setLoading] = React.useState(false);
  const [submittedEmail, setSubmittedEmail] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState(queryParams.alertMessage || state?.alertMessage);
  const [alertTitle, setAlertTitle] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues
  } = useForm<{ email: string; givenName: string; familyName: string }>({
    defaultValues: {
      email: queryParams.email ?? "",
      givenName: "",
      familyName: ""
    }
  });
  const [recaptchaToken, setRecaptchaToken] = React.useState("");

  const isAcceptingInvite = Boolean(queryParams.invite);

  const ssoLoginHref = React.useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("mode", "sso");
    return `${LOGIN_PATH}?${searchParams.toString()}`;
  }, []);

  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    setRecaptchaToken(await executeRecaptcha());
  }, [executeRecaptcha]);

  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleSignUp = async () => {
    // show loader
    setLoading(true);

    // reset alert message
    setAlertMessage("");

    // get recaptcha token
    await handleReCaptchaVerify();

    // get form values
    const formValues = getValues();

    // get data to submit
    const email = formValues.email;
    const givenName = formValues.givenName?.trim();
    const familyName = formValues.familyName?.trim();
    const name = `${formValues.givenName} ${formValues.familyName}`;
    const redirect = queryParams.redirect;
    const fingerprint = String(queryParams.skipFingerprint) === "true" || false ? "" : await getFingerprint();

    // only allow subly emails to use "+" in email
    const [localPart, provider] = email.split("@");
    const includesPlus = localPart.includes("+");
    const isSublyEmail = provider.includes("getsubly.com");
    if (includesPlus && !isSublyEmail) {
      setLoading(false);
      setAlertMessage(`Special characters like "+" are not allowed in the email`);
      return;
    }

    const { success, alertMessage, status } = await signUpWithMagicLink({
      email,
      name,
      givenName,
      familyName,
      redirect,
      fingerprint,
      recaptchaToken
    });

    if (success) {
      setSubmittedEmail(email);
      trackEvent("Complete Sign up request");
    } else if ([401, 409, 422].includes(status)) {
      setError("email", { type: "custom", message: alertMessage }, { shouldFocus: true });
    } else {
      setAlertMessage(alertMessage);
    }
    setLoading(false);
  };

  const handleRenderError = (message: string) => {
    if (message.startsWith("User already exists.")) {
      return (
        <p className="tw-mt-2 tw-text-sm tw-text-destructive-600">
          User already exists. Please{" "}
          <TextButton
            type="link"
            to={{ pathname: LOGIN_PATH, search }}
            className="!tw-font-normal !tw-text-primary-500"
          >
            log in.
          </TextButton>
        </p>
      );
    }

    return <p className="tw-mt-2 tw-text-sm tw-text-destructive-600">{message}</p>;
  };

  return submittedEmail ? (
    <EmailSentView email={submittedEmail} onClickResend={handleSubmit(handleSignUp)} />
  ) : (
    <div className="tw-mx-6 tw-my-auto tw-flex tw-h-screen tw-flex-col tw-items-center tw-justify-center">
      <div className="tw-rounded-xl tw-border tw-border-neutral-200 tw-p-8 tw-shadow-[0_6px_12px_0_#2F2B431A]">
        {alertMessage && (
          <Alert
            danger
            className="tw-w-full tw-max-w-sm"
            title={alertTitle || "Something went wrong!"}
            closable
            onClose={() => {
              setAlertMessage("");
              setAlertTitle("");
            }}
          >
            {alertMessage}
          </Alert>
        )}
        <Logo color="black" size="lg" className="tw-mx-auto tw-mb-4 tw-p-2" />
        <div className="tw-mb-6">
          <h2 className="tw-mb-1 tw-text-center tw-text-h2 tw-font-semibold tw-text-neutral-900">
            {isAcceptingInvite ? (
              <>
                You're Invited to Join a<br />
                Workspace
              </>
            ) : (
              "Start free trial!"
            )}
          </h2>
          <p className="tw-mx-auto tw-max-w-[312px] tw-text-center tw-text-md tw-font-normal tw-text-neutral-700">
            {isAcceptingInvite ? (
              <>Sign in or create an account to accept your invitation and start collaborating.</>
            ) : (
              "Join Subly, #1 Media Accessibility solution for WCAG, EAA and ADA compliance."
            )}
          </p>
        </div>

        <div className="tw-max-w-sm">
          {isEmbeddedWebView() ? (
            <EmbeddedWebviewMsg />
          ) : (
            <>
              <div className="tw-flex tw-flex-col tw-gap-4">
                <GoogleButton action="sign-up" />
                <Button variant="secondary" className="tw-flex" icon={<RiKeyFill />} type="link" to={ssoLoginHref}>
                  Single sign-on (SSO)
                </Button>
              </div>

              <Divider>or</Divider>
            </>
          )}

          <form onSubmit={handleSubmit(handleSignUp)} data-rewardful="true">
            <div className="tw-flex tw-gap-3">
              <div className="tw-mb-4 tw-flex-1">
                <Input
                  id="givenName"
                  placeholder="John"
                  hasError={Boolean(errors.givenName)}
                  autoCapitalize="word"
                  {...register("givenName", { required: true })}
                />
              </div>

              <div className="tw-mb-4 tw-flex-1">
                <Input
                  id="familyName"
                  placeholder="Smith"
                  hasError={Boolean(errors.familyName)}
                  autoCapitalize="word"
                  {...register("familyName", { required: true })}
                />
              </div>
            </div>
            <div className="tw-mb-4">
              <Input
                id="email"
                placeholder="Enter work email"
                hasError={Boolean(errors.email)}
                autoCapitalize="off"
                type="email"
                {...register("email", {
                  required: true,
                  pattern: emailPattern
                })}
              />
              <ErrorMessage errors={errors} name="email" render={({ message }) => handleRenderError(message)} />
            </div>

            <Button
              className="tw-w-full tw-bg-primary-500 !tw-text-sm tw-text-neutral-50 hover:tw-bg-primary-600"
              type="submit"
              loading={loading}
            >
              Sign up with email
            </Button>
            <div className="tw-mx-[53px] tw-mt-3 tw-text-center tw-text-xs tw-font-medium tw-text-neutral-500">
              By proceeding, you agree to the{" "}
              <a href={TERMS_URL} target="_blank" rel="noopener noreferrer" className="tw-italic tw-text-primary-600">
                Terms of Use
              </a>{" "}
              and{" "}
              <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer" className="tw-italic tw-text-primary-600">
                Privacy Policy
              </a>
            </div>

            <div className="tw-mt-16 tw-text-center tw-text-sm tw-text-neutral-700">
              Already have an account?{" "}
              <TextButton
                type="link"
                to={{ pathname: LOGIN_PATH, search }}
                className="!tw-text-sm !tw-text-primary-500"
              >
                Log in
              </TextButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
