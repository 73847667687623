import { Input } from "@shared/primitives/input";

interface CharactersPerSecondControlProps {
  value: number;
  onChange: (value: number) => void;
}

export const MaxCharsPerSecondControl = ({ value, onChange }: CharactersPerSecondControlProps) => {
  return <Input value={value} type="number" onChange={({ target }) => onChange(Number(target.value))} after="CPS" />;
};
