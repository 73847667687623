import axios, { AxiosResponse } from "axios";
import jwt from "jsonwebtoken";

import config from "@frontend/config";

import { User, UserAuth, UserSettings } from "@shared/interfaces/user";
import { authQuery } from "@shared/state/auth/auth.query";
import { authStore } from "@shared/state/auth/auth.store";
import { msToSec } from "@shared/utils/time";

import * as Sentry from "@sentry/react";

import { handleError } from "./handle-error";

interface RefreshTokenResponse {
  message: string;
  data: {
    auth: {
      accessToken: string;
      expiresIn: number;
      tokenType: string;
    };
  };
}

export const isExpired = (token: string): boolean => {
  const decode = jwt.decode(token);

  if (!decode || typeof decode === "string" || !decode.exp) {
    return false;
  }

  const nowTimestamp = Math.floor(msToSec(new Date().getTime()));
  const expTimestamp = decode.exp;

  return nowTimestamp > expTimestamp;
};

export const getAccessToken = async (): Promise<string> => {
  let accessToken = authQuery.accessToken;

  if (!accessToken || isExpired(accessToken)) {
    accessToken = await refreshAccessToken();
  }

  return accessToken ?? "";
};

// API

const baseURL = `${config.apiUrl}/api/v1`;

export interface SignInResponse {
  data: {
    user: User;
    auth: UserAuth;
  };
}

export const loginWithPassword = async (params: {
  email: string;
  password: string;
  restore?: boolean;
}): Promise<AxiosResponse<SignInResponse>> => {
  return await axios.post<SignInResponse>(`${baseURL}/auth/login-with-password`, params, { withCredentials: true });
};

export type SignUpWithMagicLinkParams = {
  email: string;
  givenName: string;
  familyName: string;
  name: string;
  redirect?: string;
  fingerprint?: string;
  recaptchaToken?: string;
};

export const signUpWithMagicLink = async (
  params: SignUpWithMagicLinkParams
): Promise<AxiosResponse<SignInResponse>> => {
  return await axios.post<SignInResponse>(`${baseURL}/auth/signup-with-magic-link`, params, { withCredentials: true });
};

export const requestMagicLink = async (params: {
  email: string;
  restore?: boolean;
  sso?: boolean;
}): Promise<AxiosResponse<SignInResponse>> => {
  return await axios.post<SignInResponse>(`${baseURL}/auth/request-magic-link`, params, { withCredentials: true });
};

export const loginWithMagicLinkToken = async (params: {
  email: string;
  token: string;
}): Promise<AxiosResponse<SignInResponse>> => {
  const { email, token } = params;
  return await axios.post<SignInResponse>(
    `${baseURL}/auth/login-with-magic-link-token`,
    { email, token },
    { withCredentials: true }
  );
};

export const triggerEmailVerification = async (userId: string): Promise<AxiosResponse> => {
  return axios.put(`${baseURL}/auth/verify/email/${userId}`);
};

export const getUser = async (): Promise<void> => {
  const { data, status } = await axios.get<User>(`${baseURL}/auth/user`, {
    headers: { "x-access-token": await getAccessToken() }
  });

  if (status !== 200) {
    throw new Error(`Get user failed with status ${status}`);
  }

  Sentry.setUser({ id: data.id, email: data.email });
  authStore.setUser(data);
};

export const loginWithOAuth2 = async (params: {
  code: string;
  redirect_uri: string;
  fingerprint?: string;
}): Promise<AxiosResponse<SignInResponse>> => {
  return await axios.post<SignInResponse>(`${baseURL}/auth/login-with-oauth2-code`, params, { withCredentials: true });
};

export interface VerifyUserParams {
  code: string;
  id: string;
  password?: string;
}

export const verifyUser = async ({ code, id, password }: VerifyUserParams): Promise<AxiosResponse<unknown>> => {
  return await axios.post(`${baseURL}/auth/verify/${id}`, { verification_code: code, password });
};

export const updateUserSettings = async (
  settings: Partial<UserSettings>,
  showError = true
): Promise<User | undefined> => {
  try {
    const user = authStore.getValue().user;
    if (!user) {
      throw new Error("Could not update user settings. User not found.");
    }
    const { data } = await axios.put<UserSettings>(`${baseURL}/auth/settings`, settings, {
      headers: { "x-access-token": await getAccessToken() }
    });

    authStore.setUser({
      ...user,
      settings: data
    });

    return {
      ...user,
      settings: data
    };
  } catch (e) {
    if (showError) {
      handleError(e);
    }
  }
};

export interface ResetPasswordParams {
  id: string;
  code: string;
  password: string;
  passwordConfirm: string;
}
export const resetPassword = async (params: ResetPasswordParams): Promise<AxiosResponse<unknown>> => {
  return await axios.post(`${baseURL}/auth/reset`, params);
};

export interface ChangePasswordParams {
  email: string;
  password: string;
}

export interface ForgotPasswordParams {
  email: string;
}

export const forgotPassword = async (params: ForgotPasswordParams): Promise<AxiosResponse<unknown>> => {
  return axios.post(`${baseURL}/auth/forget-password`, params);
};

export const changePassword = async ({ email, password }: ChangePasswordParams): Promise<AxiosResponse<unknown>> => {
  return await axios.post(`${baseURL}/auth/change-password`, {
    email,
    password
  });
};

export const verificationResend = async (id: string): Promise<AxiosResponse<unknown>> => {
  return await axios.get(`${baseURL}/auth/verify/resend/${id}`);
};

export const verifyEmailResend = async (): Promise<AxiosResponse<unknown>> => {
  return await axios.get(`${baseURL}/auth/verify-email/resend`, {
    baseURL,
    headers: { "x-access-token": await getAccessToken() }
  });
};

export const verifyEmailCode = async (code: string): Promise<AxiosResponse<unknown>> => {
  return axios.post(
    `${baseURL}/auth/verify-email`,
    { verification_code: code },
    { baseURL, headers: { "x-access-token": await getAccessToken() } }
  );
};

export const refreshAccessToken = async (): Promise<string | undefined> => {
  const response = await axios.post<RefreshTokenResponse>(`${config.apiUrl}/api/v1/auth/refresh-token`, null, {
    withCredentials: true
  });

  if (response.status !== 200) {
    throw new Error(`Refresh token failed with status ${response.status}`);
  }

  const accessToken = response.data?.data?.auth?.accessToken;
  authStore.setAccessToken(accessToken);

  return accessToken;
};
