import React from "react";

import { LockFeature } from "@shared/components/lock-feature/lock-feature";
import { AccountTemplateStyles } from "@shared/interfaces/account";
import { SublyPlan } from "@shared/interfaces/billing";
import { useActiveMediaConfigState } from "@shared/state/editor/editor.hooks";
import { parseMediaConfig } from "@shared/utils/presets";

import { AspectRatio } from "@getsubly/common";
import { PresetsDropdown } from "@media-editor/components/dropdowns/presets-dropdown/presets-dropdown";
import { useMediaEditor } from "@media-editor/contexts/media-editor.context";

export const TextPresetControl: React.FC = () => {
  // This state is used to delete presets
  const [selectedPresetIds, setSelectedPresetIds] = React.useState<string[]>([]);

  const { handleUpdateStyles } = useMediaEditor();
  const mediaConfig = useActiveMediaConfigState();

  if (!mediaConfig) {
    return null;
  }

  const currentRatio = mediaConfig.aspectRatio?.ratio ?? AspectRatio.Original;

  const updateTemplate = (updatedValues: Partial<AccountTemplateStyles>) => {
    const styles = parseMediaConfig({ ...mediaConfig, ...updatedValues });
    handleUpdateStyles(styles);
  };

  return (
    <div className="tw-flex tw-flex-col">
      <p className="tw-mb-1 tw-text-sm tw-font-medium">Styled presets</p>
      <LockFeature minPermission={SublyPlan.Pro}>
        <PresetsDropdown
          currentRatio={currentRatio}
          value={mediaConfig}
          onChange={updateTemplate}
          templateId={mediaConfig.templateId}
          selectedIds={selectedPresetIds}
          setSelectedIds={setSelectedPresetIds}
        />
      </LockFeature>
    </div>
  );
};
