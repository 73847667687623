import { Input } from "@shared/primitives/input";

interface MaxCharsPerLineControlProps {
  value: number;
  onChange: (value: number) => void;
}

export const MaxCharsPerLineControl = ({ value, onChange }: MaxCharsPerLineControlProps) => {
  return <Input type="number" value={value} onChange={({ target }) => onChange(Number(target.value))} after="CPL" />;
};
