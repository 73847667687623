import React from "react";
import classNames from "classnames";

import { ChevronDownIcon, ChevronUpIcon } from "@shared/components/icons";

import { Input, InputProps } from "../input";

interface NumberInputProps extends Omit<InputProps, "onChange" | "value" | "type"> {
  value?: number;
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
}

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  ({ value, onChange, min, max, step = 1, hasArrows, ...props }, ref) => {
    // Keep internal string state for incomplete inputs like "-" or "1."
    const [internalValue, setInternalValue] = React.useState<string>(value?.toString() ?? "");

    // Update internal value when prop changes
    React.useEffect(() => {
      setInternalValue(value?.toString() ?? "");
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setInternalValue(newValue);

      // Only call onChange for valid numbers
      const parsed = parseFloat(newValue);
      if (!isNaN(parsed)) {
        onChange?.(parsed);
      }
    };

    const handleIncrement = () => {
      const current = parseFloat(internalValue) || 0;
      const newValue = current + step;
      if (!max || newValue <= max) {
        setInternalValue(newValue.toString());
        onChange?.(newValue);
      }
    };

    const handleDecrement = () => {
      const current = parseFloat(internalValue) || 0;
      const newValue = current - step;
      if (!min || newValue >= min) {
        setInternalValue(newValue.toString());
        onChange?.(newValue);
      }
    };

    const ArrowControls = hasArrows ? (
      <div className="tw-flex tw-shrink-0 tw-select-none tw-flex-col tw-items-center">
        <ChevronUpIcon
          className={classNames("tw-h-3 tw-w-3 tw-cursor-pointer", {
            "tw-pointer-events-none tw-cursor-not-allowed tw-opacity-20":
              props.disabled || (max !== undefined && parseFloat(internalValue) >= max)
          })}
          onClick={handleIncrement}
        />
        <ChevronDownIcon
          className={classNames("tw-h-3 tw-w-3 tw-cursor-pointer", {
            "tw-pointer-events-none tw-cursor-not-allowed tw-opacity-20":
              props.disabled || (min !== undefined && parseFloat(internalValue) <= min)
          })}
          onClick={handleDecrement}
        />
      </div>
    ) : null;

    return (
      <Input
        {...props}
        ref={ref}
        type="number"
        value={internalValue}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
        after={ArrowControls}
      />
    );
  }
);
