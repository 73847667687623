import { ToolTip } from "@shared/primitives/tooltip";

import { AssStyle, MediaConfig } from "@getsubly/common";
import { ToolbarButton } from "@media-editor/components/buttons";
import { RiAlignCenter, RiAlignLeft, RiAlignRight } from "@remixicon/react";

interface TextAlignmentControlProps {
  value: AssStyle;
  onUpdate: (value: Partial<MediaConfig>) => void;
  disableTabIndex?: boolean;
  hideLabels?: boolean;
}
export const TextAlignmentControl: React.FC<TextAlignmentControlProps> = ({
  value,
  onUpdate,
  disableTabIndex,
  hideLabels
}) => {
  const alignmentValue = value.alignment - Math.floor((value.alignment - 1) / 3) * 3;
  const positionValue = Math.floor((value.alignment - 1) / 3);

  const updateValues = (values: Partial<AssStyle>) => {
    onUpdate({ ...value, ...values });
  };

  const handlePosition = (alignment?: number, location?: number) => {
    if (!alignment && alignment !== 0) {
      alignment = alignmentValue;
    }

    if (!location && location !== 0) {
      location = positionValue;
    }

    const value = alignment + location * 3;

    updateValues({
      alignment: value
    });
  };

  return (
    <div className="tw-flex tw-flex-col">
      {!hideLabels && <p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Alignment</p>}
      <div className="tw-flex tw-flex-row tw-gap-1 tw-rounded-md tw-border tw-border-neutral-200 tw-p-1">
        <ToolTip text="Text align left">
          <ToolbarButton
            active={alignmentValue === 1}
            aria-labelledby="Text align left"
            onClick={() => handlePosition(1)}
            disableTabIndex={disableTabIndex}
          >
            <RiAlignLeft className="tw-h-[18px] tw-w-[18px]" />
          </ToolbarButton>
        </ToolTip>
        <ToolTip text="Text align center">
          <ToolbarButton
            active={alignmentValue === 2}
            aria-labelledby="Text align center"
            onClick={() => handlePosition(2)}
            disableTabIndex={disableTabIndex}
          >
            <RiAlignCenter className="tw-h-[18px] tw-w-[18px]" />
          </ToolbarButton>
        </ToolTip>
        <ToolTip text="Text align right">
          <ToolbarButton
            active={alignmentValue === 3}
            aria-labelledby="Text align right"
            onClick={() => handlePosition(3)}
            disableTabIndex={disableTabIndex}
          >
            <RiAlignRight className="tw-h-[18px] tw-w-[18px]" />
          </ToolbarButton>
        </ToolTip>
      </div>
    </div>
  );
};
