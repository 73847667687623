import React from "react";
import classNames from "classnames";

import { Toggle } from "@shared/primitives/toggle";
import {
  useActiveMediaConfigState,
  useActiveMediaIdState,
  useActiveMediaOverlayState
} from "@shared/state/editor/editor.hooks";
import { editorStateRepository } from "@shared/state/editor/editor.state";

import {
  AspectRatio,
  MediaConfig,
  SocialMediaOverlayId,
  socialMediaOverlaysMap,
  Template,
  TemplateId
} from "@getsubly/common";
import { findTemplate } from "@getsubly/common/dist/assParser/subtitle.text";
import { useMediaEditor } from "@media-editor/contexts/media-editor.context";

import { TemplateControl } from "./template-control";

type AspectRatioControlProps = {
  className?: string;
  templatePickerClassName?: string;
  touchSides?: boolean;
  touchSidesPadding?: number;
};

export const AspectRatioControl: React.FC<AspectRatioControlProps> = (props) => {
  const { touchSidesPadding = 24 } = props;

  const mediaId = useActiveMediaIdState();
  const mediaConfig = useActiveMediaConfigState();

  const { handleUpdateConfig } = useMediaEditor();

  const currentOverlay = useActiveMediaOverlayState();

  const activeTemplate = React.useMemo(() => {
    return findTemplate(mediaConfig?.aspectRatio?.format);
  }, [mediaConfig?.aspectRatio]);

  // handlers

  const handleChangeFormat = async (template: Template) => {
    const latestMediaConfig = editorStateRepository.assConfig;

    if (!latestMediaConfig || !mediaId) {
      return;
    }

    const overlay = template.socialOverlay
      ? socialMediaOverlaysMap[template.socialOverlay]
      : socialMediaOverlaysMap.NoOverlay;

    // If it is the original ratio, then we want to setup the ratio on the mediaConfig to the original ratio.
    // This will help on the download and media service on what to do.
    const isOriginal = template.id === TemplateId.Original;

    let aspectRatio = template.aspectRatio;
    if (isOriginal && aspectRatio?.ratio) {
      aspectRatio = {
        ...aspectRatio,
        ratio: latestMediaConfig.originalRatio ?? AspectRatio.Original
      };
    }

    const updatedConfig: MediaConfig = {
      ...latestMediaConfig,

      templateConfig: undefined,
      overlay,
      aspectRatio
    };

    handleUpdateConfig(updatedConfig);
  };

  const handleToggleSafeZone = () => {
    if (!activeTemplate?.socialOverlay || !mediaConfig) return;

    const activeTemplateSocialOverlayId = activeTemplate.socialOverlay;

    const overlay =
      activeTemplateSocialOverlayId !== currentOverlay?.id
        ? socialMediaOverlaysMap[activeTemplateSocialOverlayId]
        : socialMediaOverlaysMap.NoOverlay;

    handleUpdateConfig({ ...mediaConfig, overlay });
  };

  return (
    <div className={classNames("tw-min-h-[112px]", props.className)}>
      <TemplateControl
        onChange={handleChangeFormat}
        className={props.templatePickerClassName}
        touchSides={props.touchSides}
        touchSidesPadding={props.touchSidesPadding}
      />
      {activeTemplate?.socialOverlay && (
        <Toggle
          checked={currentOverlay?.id !== SocialMediaOverlayId.NoOverlay}
          onChange={handleToggleSafeZone}
          label="Show safe zone"
          containerClassName="tw-mt-6"
          containerStyle={{ marginLeft: props.touchSides ? `${touchSidesPadding}px` : undefined }}
        />
      )}
    </div>
  );
};
