import React from "react";
import classNames from "classnames";

import { loadFolders } from "@frontend/api/dashboard.service";
import { updateFolder } from "@frontend/api/folders.service";
import { AddFolderButton } from "@frontend/components/buttons/add-folder-button";

import {
  BuildingOfficeIcon,
  ChevronDownIcon,
  HeroCheckIcon as CheckIcon,
  PencilIcon,
  UserOutlineIcon as UserIcon
} from "@shared/components/icons";
import { useAccounts } from "@shared/hooks/use-accounts";
import { useDashboard } from "@shared/hooks/use-dashboard";
import { useOnClickOutside } from "@shared/hooks/use-on-click-outside";
import { usePlan } from "@shared/hooks/use-plan";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@shared/primitives/dropdown";
import { Input } from "@shared/primitives/input";
import { Folder } from "@shared/state/dashboard/dashboard.store";

interface ProjectLocationDropdownProps {
  folder?: Folder | null;
  setFolder: (folder?: Folder) => void;
  hidePrivateFolders?: boolean;
  showInPortal?: boolean;
  dropdownClassName?: string;
}
export const ProjectLocationDropdown: React.FC<ProjectLocationDropdownProps> = ({
  folder,
  setFolder,
  hidePrivateFolders,
  showInPortal = true,
  dropdownClassName
}) => {
  const [isPrivateOpen, setIsPrivateOpen] = React.useState(false);
  const [isSharedOpen, setIsSharedOpen] = React.useState(false);
  const [editingId, setEditingId] = React.useState<string | null>(null);
  const { isPremium, isBusiness } = usePlan();
  const { activeFolder, privateFolders, teamspaces } = useDashboard();
  const { isViewer } = useAccounts();
  const defaultFileLabel = activeFolder ? activeFolder.name : "All media";
  const [label, setLabel] = React.useState(defaultFileLabel);

  React.useEffect(() => {
    if (!folder) {
      setLabel("All media");
      setIsPrivateOpen(true);
    } else {
      setLabel(folder.name);
      if (folder.isPublic) {
        setIsSharedOpen(true);
      } else {
        setIsPrivateOpen(true);
      }
    }
  }, [folder]);

  const renderPrivateFolders = privateFolders.map((f) => {
    return (
      <DropdownFolderItem
        key={f.id}
        selected={f.id === folder?.id}
        isEditing={f.id === editingId}
        onEdit={!!f.parentId ? () => setEditingId(f.id) : undefined}
        onClick={() => setFolder(f)}
        isOpen={isPrivateOpen}
        folder={f}
        onSave={(name) => {
          setFolder(f);
          setLabel(name);
          setEditingId(null);
        }}
      />
    );
  });

  const hasSharedFolders = isPremium || isBusiness;
  const renderTeamspaces = teamspaces.map((f) => {
    return (
      <DropdownFolderItem
        key={f.id}
        selected={f.id === folder?.id}
        isEditing={f.id === editingId}
        onEdit={!!f.parentId ? () => setEditingId(f.id) : undefined}
        onClick={() => setFolder(f)}
        isOpen={isSharedOpen}
        folder={f}
        onSave={(name) => {
          setFolder(f);
          setLabel(name);
          setEditingId(null);
        }}
      />
    );
  });

  return (
    <div>
      <Dropdown>
        <DropdownButton className="!tw-w-full" variant="secondary" disabled={isViewer}>
          <div className="tw-overflow-hidden tw-text-ellipsis">{label}</div>
        </DropdownButton>
        <DropdownMenu
          className={classNames("!tw-z-[1499] tw-min-w-[450px]", dropdownClassName)}
          placement="bottom-start"
          showInPortal={showInPortal}
        >
          {!hidePrivateFolders && (
            <>
              <DropdownFolderHeader
                handleToggle={(isOpen) => setIsPrivateOpen(isOpen)}
                isOpen={isPrivateOpen}
                title="Private"
                isPrivateFolder
              />

              <div className="tw-max-h-[20vh] tw-overflow-y-auto">
                {isPrivateOpen && (
                  <DropdownItem selected={!folder?.id} onClick={() => setFolder()}>
                    All media
                  </DropdownItem>
                )}

                {renderPrivateFolders}
              </div>
            </>
          )}
          {hasSharedFolders && (
            <>
              <DropdownFolderHeader
                handleToggle={(isOpen) => setIsSharedOpen(isOpen)}
                isOpen={isSharedOpen}
                title="Workspace"
              />
              <div className="tw-max-h-[20vh] tw-overflow-y-auto">{renderTeamspaces}</div>
            </>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

interface DropdownFolderHeaderProps {
  handleToggle: (isOpen: boolean) => void;
  isOpen: boolean;
  title: string;
  isPrivateFolder?: boolean;
}
const DropdownFolderHeader: React.FC<DropdownFolderHeaderProps> = ({
  handleToggle,
  isOpen,
  title,
  isPrivateFolder
}) => {
  const icon = isPrivateFolder ? (
    <UserIcon className="tw-w-5 tw-stroke-2" />
  ) : (
    <BuildingOfficeIcon className="tw-w-5 tw-stroke-2" />
  );

  return (
    <div
      className="tw-flex tw-cursor-pointer tw-items-center tw-gap-3 tw-truncate tw-px-3 tw-py-2"
      onClick={() => handleToggle(!isOpen)}
    >
      {icon}

      <p className="tw-mr-auto tw-text-sm tw-font-semibold">{title}</p>

      <AddFolderButton folderCategory={title} toolTipPlace="right" />

      <ChevronDownIcon
        className={classNames("tw-w-5 tw-stroke-2", {
          "tw-rotate-180": isOpen
        })}
      />
    </div>
  );
};

interface DropdownFolderItemProps {
  selected: boolean;
  isEditing: boolean;
  onEdit?: () => void;
  onClick: () => void;
  isOpen: boolean;
  folder: Folder;
  onSave: (name: string) => void;
}
export const DropdownFolderItem: React.FC<DropdownFolderItemProps> = ({
  selected,
  isEditing,
  onEdit,
  onClick,
  isOpen,
  folder,
  onSave
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <DropdownItem selected={selected} onClick={onClick} className="tw-group" keepOpenOnClick={isEditing}>
      {!isEditing ? <FolderItem folder={folder} onEdit={onEdit} /> : <FolderEditor folder={folder} onSave={onSave} />}
    </DropdownItem>
  );
};

interface FolderItemProps {
  folder: Folder;
  onEdit?: () => void;
}

export const FolderItem: React.FC<FolderItemProps> = ({ folder, onEdit }) => {
  return (
    <>
      <span className="tw-truncate">{folder.name}</span>
      {onEdit && (
        <PencilIcon
          className="tw-w-5 tw-stroke-2 tw-opacity-0 hover:!tw-opacity-100 group-hover:tw-opacity-50"
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
        />
      )}
    </>
  );
};

interface FolderEditorProps {
  folder: Folder;
  onSave?: (name: string) => void;
}

export const FolderEditor: React.FC<FolderEditorProps> = ({ folder, onSave }) => {
  const [name, setName] = React.useState(folder.name);
  const ref = React.useRef<HTMLInputElement | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    saveName();
  };

  const saveName = async () => {
    if (!name) {
      return;
    }
    onSave?.(name);
    await updateFolder({ ...folder, name });
    await loadFolders();
  };

  useOnClickOutside(ref, saveName);

  return (
    <form className="tw-w-full" onSubmit={handleSubmit}>
      <Input
        type="text"
        name="name"
        className="tw-h-6 tw-bg-transparent"
        autoFocus
        ref={ref}
        value={name}
        onChange={(e) => setName(e.target.value)}
        rightIcon={
          <CheckIcon
            className="tw-w-4 tw-cursor-pointer tw-stroke-2 tw-opacity-50 hover:!tw-opacity-100"
            onClick={() => setName(name)}
          />
        }
      />
    </form>
  );
};
