import React from "react";
import classNames from "classnames";

import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@shared/primitives/dropdown";

import { BackgroundStyle } from "@getsubly/common";

const EFFECTS = [
  { style: BackgroundStyle.None, text: "No effects" },
  { style: BackgroundStyle.DropShadow, text: "Drop shadow" },
  { style: BackgroundStyle.Background, text: "Background" },
  { style: BackgroundStyle.Outline, text: "Outline" },
  { style: BackgroundStyle.FullWidth, text: "Full width" }
];

const DEFAULT_EFFECT: {
  style: BackgroundStyle;
  outline: number;
} = {
  style: BackgroundStyle.None,
  outline: 1
};

interface EffectPresetDropdownProps {
  effect?: { style: BackgroundStyle; outline: number };
  onChange: (effect: { style: BackgroundStyle; outline: number }) => void;
  className?: string;
  includeFullWidthEffect?: boolean;
}

export const EffectPresetDropdown = (props: EffectPresetDropdownProps) => {
  const [activeStyle, setStyle] = React.useState(props.effect?.style || DEFAULT_EFFECT.style);
  const outline = React.useMemo(() => props.effect?.outline || DEFAULT_EFFECT.outline, [props.effect?.outline]);

  React.useEffect(() => {
    if (props.effect?.style && props.effect.style !== activeStyle) {
      setStyle(props.effect.style);
    }
  }, [props.effect?.style]);

  const handleSelect = (style: BackgroundStyle) => {
    if (style !== activeStyle) {
      setStyle(style);
      props.onChange({ style, outline });
    }
  };

  const effects = props.includeFullWidthEffect ? EFFECTS : EFFECTS.slice(0, -1);

  return (
    <Dropdown>
      <DropdownButton className={classNames("!tw-w-full", props.className)}>{activeStyle}</DropdownButton>
      <DropdownMenu className="!tw-w-full">
        {effects.map(({ style, text }, i) => (
          <DropdownItem selected={activeStyle === style} onClick={() => handleSelect(style)} key={i}>
            {text}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
