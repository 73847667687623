import { Input } from "@shared/primitives/input";

interface WordsPerLineControlProps {
  value: number;
  onChange: (value: number) => void;
}

export const MaxWordsPerLineControl = ({ value, onChange }: WordsPerLineControlProps) => {
  return <Input type="number" value={value} onChange={({ target }) => onChange(Number(target.value))} />;
};
