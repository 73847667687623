import React from "react";

import { FlagSymbol } from "@shared/components/symbols/flag-symbol";
import { MoreSymbol } from "@shared/components/symbols/more-symbol";
import { SymbolGroup } from "@shared/primitives/symbol-group/symbol-group";

type FlagGroupSize = "12" | "16" | "20" | "24";

interface FlagGroupProps {
  flags: { flagCode: string; label: string }[]; // this should be an array of flag country codes. e.g. ["us", "gb", "ca", "mx"], see for reference: https://flagicons.lipis.dev/
  maxPreview?: number; // this should be the max number of flags to show before the "more" indicator is shown.
  size?: FlagGroupSize; // this should be the max number of flags to show before the "more" indicator is shown.
}

export const FlagGroup: React.FC<FlagGroupProps> = ({ flags = [], maxPreview = 3, size = "20" }) => {
  const previewFlags = flags.slice(0, maxPreview);
  const moreFlags = flags.slice(maxPreview);

  return (
    <SymbolGroup size={size}>
      {previewFlags.map((flag, i) => {
        return (
          <SymbolGroup.Item key={i}>
            <FlagSymbol size={size} flag={flag} tooltip={flag.label} />
          </SymbolGroup.Item>
        );
      })}
      {moreFlags.length > 0 && (
        <SymbolGroup.Item>
          <MoreSymbol size={size} amount={moreFlags.length} tooltip={moreFlags.map((flag) => flag.label).join(", ")} />
        </SymbolGroup.Item>
      )}
    </SymbolGroup>
  );
};
