import React from "react";
import { useResizeDetector } from "react-resize-detector";

import { fontsForLatin } from "@shared/assets/fonts";
import { useAssets } from "@shared/hooks/use-assets";

import { MediaConfig } from "@getsubly/common";
import { ImageElements } from "@media-player/components/image-elements";
import { SubtitlesCanvas } from "@media-player/components/subtitle-canvas";

const PREVIEW_TEXT = [
  {
    id: "preview",
    start: 0,
    end: 10,
    children: [{ text: "Preview Text", start: 0, end: 10 }]
  }
];

interface PreviewProps {
  mediaConfig: MediaConfig;
  showSubtitles?: boolean;
  showLogo?: boolean;
}

export const Preview: React.FC<PreviewProps> = ({ mediaConfig, showSubtitles, showLogo }) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const videoUrl = new URL("@frontend/assets/videos/preview.mp4", import.meta.url).href;

  const { fontAssets } = useAssets();
  const fonts = React.useMemo(() => {
    return [...fontAssets.map((asset) => asset.publicUrl ?? ""), ...fontsForLatin().map((font) => font.path)];
  }, [fontAssets.length]);

  // Use resize detector to get actual container dimensions
  const { width: containerWidth, height: containerHeight, ref: containerRef } = useResizeDetector<HTMLDivElement>();

  // Calculate canvas dimensions based on container size
  const [canvasWidth, canvasHeight] = React.useMemo(() => {
    if (!containerWidth || !containerHeight) {
      return [480, 270]; // fallback dimensions
    }

    const aspectRatio = 16 / 9; // or get from video metadata
    const width = containerWidth;
    const height = width / aspectRatio;

    return [width, height];
  }, [containerWidth, containerHeight]);

  return (
    <div ref={containerRef} className="tw-relative tw-aspect-video tw-w-full">
      <video
        ref={videoRef}
        src={videoUrl}
        className="tw-h-full tw-w-full tw-rounded-12"
        muted
        playsInline
        loop
        onLoadedMetadata={() => setIsLoaded(true)}
      />
      {isLoaded && fonts.length && showSubtitles && (
        <SubtitlesCanvas
          src={videoUrl}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          isLoaded={isLoaded}
          hasArtwork={true}
          isAudio={false}
          currentTime={0}
          mediaConfig={mediaConfig}
          transcription={PREVIEW_TEXT}
          fonts={fonts}
        />
      )}
      {showLogo && (
        <ImageElements
          canvasHeight={canvasHeight}
          canvasWidth={canvasWidth}
          scaledWidth={canvasWidth}
          scaledHeight={canvasHeight}
          isAudio={false}
          hasArtwork={true}
          hasWatermark={mediaConfig.showWatermark}
          customLogo={mediaConfig.customLogo}
        />
      )}
    </div>
  );
};
