import React from "react";
import classNames from "classnames";

import { PremiumBadge } from "@frontend/components/badges/premium-badge";

import { LockFeature } from "@shared/components/lock-feature/lock-feature";
import { usePlanPermissions } from "@shared/hooks/use-plan-permissions";
import { SublyPlan } from "@shared/interfaces/billing";
import { Badge } from "@shared/primitives/badge";
import { Button } from "@shared/primitives/button";

import styles from "./zoom.module.scss";

enum ZoomTabs {
  NotImported,
  Imported,
  Archived
}

interface ZoomHeaderProps {
  showTabs?: boolean;
  tab: ZoomTabs;
  setTab: React.Dispatch<React.SetStateAction<ZoomTabs>>;
  archiveFiles?: () => void;
  importFiles?: () => void;
  showImport: boolean;
  disableImport: boolean;
  showArchive: boolean;
  disableArchive: boolean;
}
export const ZoomHeader: React.FC<ZoomHeaderProps> = ({
  showTabs,
  tab,
  setTab,
  archiveFiles,
  importFiles,
  showImport,
  disableImport,
  disableArchive,
  showArchive
}) => {
  const { hasPermission } = usePlanPermissions(SublyPlan.Premium);

  const renderTabs = (
    <ul className={styles["zoom-tabs"]}>
      <li
        className={classNames(styles["zoom-tab"], {
          [styles["zoom-tab--active"]]: tab === ZoomTabs.NotImported
        })}
        onClick={() => setTab(ZoomTabs.NotImported)}
        role="button"
      >
        {/* <FolderAddIcon style={{ height: 14, width: 14 }} /> */}
        Not imported
        <Badge className="tw-ml-1">4</Badge>
      </li>
      <li
        className={classNames(styles["zoom-tab"], {
          [styles["zoom-tab--active"]]: tab === ZoomTabs.Imported
        })}
        onClick={() => setTab(ZoomTabs.Imported)}
        role="button"
      >
        Imported
        <Badge className="tw-ml-1">4</Badge>
      </li>

      <li
        className={classNames(styles["zoom-tab"], {
          [styles["zoom-tab--active"]]: tab === ZoomTabs.Archived
        })}
        onClick={() => setTab(ZoomTabs.Archived)}
        role="button"
      >
        {/* <ArchiveIcon /> */}
        Archived
        <Badge className="tw-ml-1">4</Badge>
      </li>
    </ul>
  );

  return (
    <div className="tw-mb-4 tw-flex tw-flex-row">
      {showTabs && renderTabs}
      <div className="tw-flex tw-w-full tw-justify-end">
        {showArchive && (
          <Button variant="secondary" onClick={archiveFiles} className="tw-mr-4" disabled={disableArchive} size="36">
            {/* <TrashIcon /> */}
            Archive
          </Button>
        )}
        {showImport && (
          <div className="tw-flex tw-items-center">
            {!hasPermission && <PremiumBadge className={styles["badges"]} />}
            <LockFeature minPermission={SublyPlan.Premium}>
              <Button variant="primary" onClick={importFiles} disabled={disableImport} size="36">
                <div className={styles.buttonIcon}>Import</div>
              </Button>
            </LockFeature>
          </div>
        )}
      </div>
    </div>
  );
};
