import React from "react";

import { Position, PositionIcon } from "@shared/components/icons/position-icon";
import { Button } from "@shared/primitives/button";
import { Dropdown, DropdownButton, DropdownMenu } from "@shared/primitives/dropdown";
import { Input } from "@shared/primitives/input";
import { Select } from "@shared/primitives/select";
import { accountQuery } from "@shared/state/account";

import { BurnPosition, CustomLogoConfig, DEFAULT_CUSTOM_LOGO } from "@getsubly/common";

const MARGIN_LABELS = {
  [BurnPosition.TopLeft]: { y: "Top", x: "Left" },
  [BurnPosition.TopRight]: { y: "Top", x: "Right" },
  [BurnPosition.BottomLeft]: { y: "Bottom", x: "Left" },
  [BurnPosition.BottomRight]: { y: "Bottom", x: "Right" },
  [BurnPosition.BottomCenter]: { y: "Bottom", x: "Center" }
};

type LogoPositionControlProps = {
  customLogo: CustomLogoConfig;
  onChange: (customLogo: CustomLogoConfig) => void;
};
export const LogoPositionControl: React.FC<LogoPositionControlProps> = (props) => {
  const handleChange = <Key extends keyof CustomLogoConfig, Value extends CustomLogoConfig[Key]>(
    key: Key,
    value: Value
  ) => {
    props.onChange({ ...props.customLogo, [key]: value });
  };

  return (
    <div className="tw-flex tw-flex-row tw-gap-2">
      <Select
        dropdownClassName="!tw-border-transparent"
        value={props.customLogo.position}
        options={[
          { label: "Top left", value: BurnPosition.TopLeft },
          { label: "Top right", value: BurnPosition.TopRight },
          { label: "Bottom left", value: BurnPosition.BottomLeft },
          { label: "Bottom right", value: BurnPosition.BottomRight }
        ]}
        formatInputSelectedLabel={(option) => (
          <>
            <PositionIcon position={option.value as Position} />
            <p className="tw-text-md tw-font-medium">{option.label}</p>
          </>
        )}
        formatOptionLabel={(option) => (
          <>
            <PositionIcon position={option.value as Position} />
            <p className="tw-text-md tw-font-medium">{option.label}</p>
          </>
        )}
        onChange={(value) => handleChange("position", value as BurnPosition)}
      />

      <Dropdown>
        <DropdownButton
          className="tw-flex tw-items-center tw-whitespace-nowrap !tw-py-1 tw-px-3 tw-font-medium tw-text-neutral-700"
          showArrow={false}
        >
          Adjust position
        </DropdownButton>
        <DropdownMenu placement="bottom-start">
          <div className="tw-flex tw-flex-col tw-gap-5 tw-p-4">
            <div className="tw-flex tw-flex-row tw-gap-6">
              <Input
                type="number"
                className="!tw-w-24"
                label={MARGIN_LABELS[props.customLogo.position].y}
                value={props.customLogo.padding.y}
                onChange={({ target }) => handleChange("padding", { x: props.customLogo.padding.x, y: +target.value })}
                after="px"
              />

              <Input
                type="number"
                className="!tw-w-24"
                label={MARGIN_LABELS[props.customLogo.position].x}
                value={props.customLogo.padding.x}
                onChange={({ target }) => handleChange("padding", { x: +target.value, y: props.customLogo.padding.y })}
                after="px"
              />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2">
              <Button
                variant="ghost"
                onClick={() =>
                  handleChange("padding", {
                    x:
                      accountQuery.settings?.defaultMediaConfig?.customLogo?.padding.x ?? DEFAULT_CUSTOM_LOGO.padding.x,
                    y: accountQuery.settings?.defaultMediaConfig?.customLogo?.padding.y ?? DEFAULT_CUSTOM_LOGO.padding.y
                  })
                }
                size="36"
              >
                Reset to default
              </Button>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
