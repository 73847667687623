import React from "react";

import { updateAccountSettings } from "@frontend/api/account.service";

import { Button } from "@shared/primitives/button";
import { Checkbox } from "@shared/primitives/checkbox";
import { Modal } from "@shared/primitives/modal";
import { ratioLabel } from "@shared/utils/aspect-ratio";
import { pluralize } from "@shared/utils/strings";

import { AspectRatio } from "@getsubly/common";

interface DeletePresetModalProps {
  currentRatio: AspectRatio;
  selectedItems: number;
  onDelete: () => Promise<void>;
  onDismiss: () => void;
}
export const DeletePresetModal: React.FC<DeletePresetModalProps> = (props) => {
  return (
    <Modal onDismiss={props.onDismiss} className="tw-p-6">
      <DeletePresetModalContent {...props} />
    </Modal>
  );
};

const DeletePresetModalContent: React.FC<DeletePresetModalProps> = ({
  currentRatio,
  selectedItems,
  onDismiss,
  onDelete
}) => {
  const [reminder, setReminder] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    setLoading(true);

    if (reminder) {
      await updateAccountSettings({ hasSeenTemplateDeleteModal: true });
    }

    await onDelete();
    onDismiss();
  };

  return (
    <>
      <h5 className="tw-text-h5">
        Delete {ratioLabel(currentRatio)} {pluralize(selectedItems, "preset")}
      </h5>
      <p>
        Are you sure you would like to permanently delete {pluralize(selectedItems, "this", "these")}{" "}
        {pluralize(selectedItems, "preset")}?
      </p>

      <div className="tw-mt-2 tw-flex tw-flex-row">
        <Checkbox checked={reminder} onChange={setReminder} label="Don't remind me again" />
      </div>

      <div className="tw-mt-4 tw-flex">
        <Button variant="secondary" onClick={onDismiss} className="tw-ml-auto tw-mr-2" size="36">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleDelete} className="tw-mx-2" loading={loading} size="36">
          Delete
        </Button>
      </div>
    </>
  );
};
