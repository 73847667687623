import React from "react";
import { cloneDeep, set } from "lodash-es";
import { v4 } from "uuid";

import { PencilIcon, PlusIcon, TrashIcon, UpdateIcon } from "@shared/components/icons";
import { useModal } from "@shared/hooks/use-modal";
import { AccountTemplate, AccountTemplateStyles } from "@shared/interfaces/account";
import { ModalType } from "@shared/interfaces/modal-type";
import { Button, IconButton } from "@shared/primitives/button";
import { Checkbox } from "@shared/primitives/checkbox";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownMenu
} from "@shared/primitives/dropdown";
import { notificationSuccess } from "@shared/primitives/notification";
import { accountQuery } from "@shared/state/account";
import { useActiveMediaIdState } from "@shared/state/editor/editor.hooks";
import { editorStateRepository } from "@shared/state/editor/editor.state";
import { useActiveMediaSettingsPresetsState } from "@shared/state/editor-ui";
import { ratioLabel } from "@shared/utils/aspect-ratio";
import { assStyleToStyleConvert, parseMediaConfig, presetHasChanged } from "@shared/utils/presets";

import { AspectRatio } from "@getsubly/common";
import { useMediaEditor } from "@media-editor/contexts/media-editor.context";

import { DeletePresetModal } from "./delete-preset-modal";
import { RenamePresetModal } from "./rename-preset-modal";

interface PresetsDropdownProps {
  value: AccountTemplateStyles;
  templateId?: string;
  currentRatio: AspectRatio;
  onChange: (values: Partial<AccountTemplateStyles>) => void;
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
}
export const PresetsDropdown: React.FC<PresetsDropdownProps> = ({
  value,
  templateId,
  currentRatio,
  onChange,
  selectedIds,
  setSelectedIds
}) => {
  const { handleUpdatePresets } = useMediaEditor();
  const mediaId = useActiveMediaIdState();

  const [loading, setLoading] = React.useState(false);
  const [isDeleting, setDeleting] = React.useState(false);
  const presets = useActiveMediaSettingsPresetsState();

  if (!mediaId) {
    return null;
  }

  const filteredPresets = presets?.filter((t) => t.ratio === currentRatio || t.ratio === undefined);

  const currentPreset = presets?.find((t) => t.id === templateId);
  const shouldUpdatePreset = presetHasChanged(currentPreset, value);

  const handleSaveNewPreset = async (name: string) => {
    try {
      setLoading(true);
      const newPreset = {
        ...parseMediaConfig(value),
        id: v4(),
        name,
        ratio: currentRatio
      };
      const updatedPresets = [...presets, newPreset];
      await handleUpdatePresets(updatedPresets);

      notificationSuccess(
        <p className="tw-m-0">
          Your preset <strong>{name}</strong> has been created and is ready to use.
        </p>
      );

      onChange({
        templateId: newPreset.id
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPreset = (id: string) => {
    const newTemplate = cloneDeep(presets.find((t) => t.id === id));

    if (!newTemplate) {
      return;
    }

    if (value.headline?.text) {
      set(newTemplate, "headline.text", value.headline.text);
    }

    if (!newTemplate.customLogo) {
      set(newTemplate, "customLogo", null);
    }

    onChange({
      ...newTemplate,
      templateId: id
    });
  };

  const handleUpdatePreset = async () => {
    try {
      setLoading(true);

      const newTemplates = presets.map((t) => {
        if (t.id === templateId) {
          return { ...t, ...parseMediaConfig(value), ratio: currentRatio };
        }

        return t;
      });

      await handleUpdatePresets(newTemplates);

      notificationSuccess(
        <p className="tw-m-0">
          Your preset <strong>{currentPreset?.name}</strong> has been updated.
        </p>
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleClearPreset = () => {
    if (templateId) {
      handleSelectPreset(templateId);
    }
  };

  const handleDeletePreset = async (ids: string[]) => {
    try {
      setDeleting(true);

      const updatedTemplates = presets.filter((t) => !ids.includes(t.id));

      await handleUpdatePresets(updatedTemplates);

      if (currentPreset?.id && ids.includes(currentPreset?.id)) {
        onChange({
          templateId: undefined
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setDeleting(false);
    }
  };

  const dropdownButtonTitle = filteredPresets.length > 0 ? "Styled presets" : "Save as a preset";

  return (
    <Dropdown>
      <DropdownButton className="!tw-w-full">{dropdownButtonTitle}</DropdownButton>

      <DropdownMenu className="tw-flex !tw-w-full tw-flex-col tw-gap-4">
        <DropdownHeader>
          {ratioLabel(currentRatio)} Styled presets
          <p className="tw-text-sm tw-font-normal tw-text-neutral-600">Styled presets are aspect ratios specific</p>
        </DropdownHeader>
        <DropdownDivider />
        <PresetsContainer
          presets={filteredPresets}
          allPresets={presets}
          selectedPreset={currentPreset}
          currentRatio={currentRatio}
          shouldUpdate={shouldUpdatePreset}
          isDeleting={isDeleting}
          onUpdate={handleUpdatePreset}
          onClear={handleClearPreset}
          selectTemplate={handleSelectPreset}
          deleteTemplates={handleDeletePreset}
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
        />
        <div className="tw-p-4">
          <Button
            variant="secondary"
            className="tw-flex tw-w-full tw-gap-2"
            onClick={() => handleSaveNewPreset("Untitled")}
            loading={loading}
            size="36"
          >
            <PlusIcon className="tw-h-3 tw-w-3" />
            {shouldUpdatePreset ? "Save changes as new preset" : "Create new preset"}
          </Button>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

interface PresetsContainerProps {
  presets: AccountTemplate[];
  allPresets: AccountTemplate[];
  selectedPreset?: AccountTemplate;
  shouldUpdate: boolean;
  isDeleting: boolean;
  currentRatio: AspectRatio;
  selectTemplate: (id: string) => void;
  deleteTemplates: (ids: string[]) => Promise<void>;
  onUpdate: () => void;
  onClear: () => void;
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
}
const PresetsContainer: React.FC<PresetsContainerProps> = ({
  presets,
  allPresets,
  selectedPreset,
  shouldUpdate,
  isDeleting: propsDeleting,
  currentRatio,
  selectTemplate,
  deleteTemplates,
  onUpdate,
  onClear,
  selectedIds,
  setSelectedIds
}) => {
  const [edit, setEdit] = React.useState(false);
  const [isDeleting, setDeleting] = React.useState(propsDeleting);
  const [showModal, hideModal] = useModal(ModalType.DeleteStylesTemplate);

  const hasSeenDeleteModal = accountQuery.getValue().settings.hasSeenTemplateDeleteModal;

  const handleDelete = async () => {
    setDeleting(true);

    await deleteTemplates(selectedIds);
    setSelectedIds([]);
    setEdit(false);

    setDeleting(false);
  };

  const showRemoveModal = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (selectedIds.length === 0) {
      setEdit(false);
      return;
    }

    if (!hasSeenDeleteModal) {
      showModal(
        <DeletePresetModal
          onDismiss={hideModal}
          currentRatio={currentRatio}
          onDelete={handleDelete}
          selectedItems={selectedIds.length}
        />
      );
    } else {
      await handleDelete();
    }
  };

  const togglePreset = (templateId: string) => {
    if (selectedIds.includes(templateId)) {
      setSelectedIds((ids) => [...ids].filter((id) => id !== templateId));
    } else {
      setSelectedIds((ids) => [...ids, templateId]);
    }
  };

  if (!presets.length) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center tw-py-7 tw-text-sm tw-text-neutral-400">
        <span>No presets created yet</span>
      </div>
    );
  }

  const renderPresets = presets.map((t) => {
    return (
      <Preset
        allPresets={allPresets}
        preset={t}
        style={t}
        selected={selectedPreset?.id === t.id}
        shouldUpdate={shouldUpdate}
        selectPreset={selectTemplate}
        onUpdate={onUpdate}
        onClear={onClear}
        togglePreset={togglePreset}
        selectedIds={selectedIds}
        edit={edit}
        key={t.id}
      />
    );
  });

  return (
    <>
      <div className="tw-flex tw-flex-row tw-justify-end tw-gap-2 tw-px-6">
        <Button
          variant="secondary"
          size="32"
          onClick={() => {
            if (edit) {
              setSelectedIds([]);
              setEdit(false);
            } else {
              setEdit(true);
            }
          }}
        >
          {edit ? "Done" : "Edit"}
        </Button>
        {Boolean(edit) && (
          <IconButton
            variant="destructive-outline"
            size="32"
            className="!tw-h-auto"
            onClick={selectedIds.length ? showRemoveModal : undefined}
            disabled={!selectedIds.length}
            loading={isDeleting || propsDeleting}
            icon={<TrashIcon className="tw-h-4 tw-w-4" strokeWidth="2" />}
          />
        )}
      </div>

      <div className="tw-max-h-[300px] tw-overflow-y-auto">{renderPresets}</div>
    </>
  );
};

interface PresetProps {
  allPresets: AccountTemplate[];
  preset: AccountTemplate;
  style?: AccountTemplateStyles;
  selected?: boolean;
  shouldUpdate?: boolean;
  edit: boolean;
  selectedIds: string[];
  togglePreset: (templateId: string) => void;
  selectPreset?: (id: string) => void;
  onUpdate: () => void;
  onClear: () => void;
}
const Preset: React.FC<PresetProps> = ({
  allPresets,
  preset,
  selected,
  shouldUpdate,
  edit,
  selectedIds,
  togglePreset,
  selectPreset,
  onUpdate,
  onClear
}) => {
  const { handleUpdatePresets } = useMediaEditor();

  const [showRenameModal, hideRenameModal] = useModal(ModalType.RenameStylesTemplate);

  const isSelected = selectedIds.includes(preset.id);
  const cssStyle = assStyleToStyleConvert(preset);

  const handleSelectTemplate = () => {
    if (!preset.id || !selectPreset) {
      return;
    }

    selectPreset(preset.id);
  };

  const handleSaveRename = async (name: string) => {
    const mediaId = editorStateRepository.mediaId;

    if (!preset?.id || !mediaId) {
      return;
    }

    const updatedPresets = allPresets.map((p) => {
      if (p.id === preset.id) {
        return {
          ...p,
          name
        };
      }

      return p;
    });

    await handleUpdatePresets(updatedPresets);
  };

  const showRenameUpdateModal = (e: React.MouseEvent) => {
    if (!preset.id) {
      return;
    }

    e.stopPropagation();

    showRenameModal(<RenamePresetModal name={preset.name} onSave={handleSaveRename} onDismiss={hideRenameModal} />);
  };

  if (edit) {
    return (
      <DropdownItem className="!tw-p-0" keepOpenOnClick selected={selected}>
        <Checkbox
          className="tw-w-full tw-px-4 tw-py-[15px]"
          checked={isSelected}
          onChange={() => togglePreset(preset.id)}
          position="right"
          large
        >
          <p className="tw-w-full tw-truncate">{preset.name}</p>
        </Checkbox>
      </DropdownItem>
    );
  }

  if (shouldUpdate && selected) {
    return (
      <DropdownItem className="tw-group tw-w-full" selected>
        <p className="tw-truncate">{preset.name}</p>
        <PencilIcon
          className="tw-mr-auto tw-w-5 tw-flex-shrink-0 tw-stroke-2 tw-opacity-0 hover:!tw-opacity-100 group-hover:tw-opacity-50"
          onClick={showRenameUpdateModal}
        />

        <div className="tw-flex tw-flex-col tw-gap-2">
          <Button className="tw-justify-start tw-gap-2" size="32" onClick={onUpdate}>
            <UpdateIcon className="tw-w-4" /> Update existing preset
          </Button>
          <Button variant="secondary" className="tw-justify-start tw-gap-2" size="32" onClick={onClear}>
            <TrashIcon className="tw-w-4" />
            Remove all changes
          </Button>
        </div>
      </DropdownItem>
    );
  }

  return (
    <DropdownItem className="tw-group tw-w-full" selected={selected} onClick={handleSelectTemplate}>
      <p className="tw-truncate">{preset.name}</p>
      <PencilIcon
        className="tw-mr-auto tw-w-5 tw-flex-shrink-0 tw-stroke-2 tw-opacity-0 hover:!tw-opacity-100 group-hover:tw-opacity-50"
        onClick={showRenameUpdateModal}
      />

      <div
        className="tw-flex-shrink-0 tw-text-center tw-text-lg"
        style={{
          justifyContent: cssStyle.justifyContent
        }}
      >
        <span className="tw-inline-block tw-p-2" style={cssStyle}>
          Ab Cc Dd
        </span>
      </div>
    </DropdownItem>
  );
};
