import React from "react";
import classNames from "classnames";

import { SettingsCard } from "@frontend/components/settings-card/settings-card";

type BrandDefaultsCardProps = {
  children?: React.ReactNode;
  className?: string;
};

export const BrandDefaultsCard: React.FC<BrandDefaultsCardProps> = (props) => {
  return (
    <SettingsCard className={classNames("tw-w-[405px]", props.className)}>
      <SettingsCard.Header>
        <div>
          <div className="tw-mb-1">Defaults</div>
          <p className="tw-text-sm tw-font-normal tw-text-neutral-600">
            Set Defaults on subtitles, guidelines and logo
          </p>
        </div>
      </SettingsCard.Header>
      <SettingsCard.Body className="tw-flex tw-flex-col !tw-px-2 !tw-pt-0">{props.children}</SettingsCard.Body>
    </SettingsCard>
  );
};
