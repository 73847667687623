import React from "react";
import classNames from "classnames";

import {
  FacebookLogo,
  InstagramLogo,
  LinkedInLogo,
  OriginalLogo,
  TikTokLogo,
  VimeoLogo,
  YouTubeLogo
} from "@shared/components/icons/platform-logos";
import { useActiveMediaConfigState } from "@shared/state/editor/editor.hooks";

import { AspectRatio, Template, TemplateId } from "@getsubly/common";
import { findTemplate, TEMPLATES } from "@getsubly/common/dist/assParser/subtitle.text";

interface TemplateControlProps {
  className?: string;
  touchSides?: boolean;
  touchSidesPadding?: number;
  onChange: (value: Template) => void;
}

export const TemplateControl: React.FC<TemplateControlProps> = ({
  className,
  touchSides,
  touchSidesPadding,
  onChange
}) => {
  const mediaConfig = useActiveMediaConfigState();

  const isActive = (template: Template) => {
    return template.label === findTemplate(mediaConfig?.aspectRatio?.format ?? TemplateId.Original)?.label;
  };

  return (
    <ul
      className={classNames("tw-flex tw-items-end tw-gap-3 tw-overflow-auto", className)}
      style={{ padding: touchSides ? `${touchSidesPadding}px` : undefined }}
    >
      {TEMPLATES.map((template) => (
        <TemplateControlOption
          key={template.label}
          template={template}
          isActive={isActive(template)}
          ratio={template.aspectRatio?.ratio}
          id={template.id}
          onSelect={onChange}
        />
      ))}
    </ul>
  );
};

interface TemplateControlOptionProps {
  id: TemplateId;
  template: Template;
  ratio?: AspectRatio;
  isActive: boolean;
  onSelect: (template: Template) => void;
}

const TemplateControlOption: React.FC<TemplateControlOptionProps> = ({ id, template, isActive, ratio, onSelect }) => {
  const handleClick = () => {
    if (!isActive) {
      onSelect(template);
    }
  };

  const sizeClasses = {
    [AspectRatio.Original]: "tw-h-[57px] tw-w-[99px]",
    [AspectRatio.Portrait]: "tw-h-[89px] tw-w-[64px]",
    [AspectRatio.Square]: "tw-h-[70px] tw-w-[70px]",
    [AspectRatio.Landscape]: "tw-h-[54px] tw-w-[96px]",
    [AspectRatio.Vertical]: "tw-h-[65px] tw-w-[52px]"
  };

  return (
    <li onClick={handleClick} className="tw-flex tw-cursor-pointer tw-flex-col tw-items-center">
      <button
        className={classNames(
          "tw-relative tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border-2 tw-border-neutral-200 tw-bg-neutral-50 hover:tw-bg-aux-50",
          ratio && sizeClasses[ratio],
          { "!tw-border-2 !tw-border-aux-600 !tw-bg-aux-50": isActive }
        )}
      >
        <div>
          <Logo id={id} isActive={isActive} />
          <p className="tw-font-md tw-text-center tw-text-xs tw-text-neutral-900">
            {ratio === AspectRatio.Original ? "" : ratio}
          </p>
        </div>
      </button>
      <p className="tw-font-md tw-text-center tw-text-xs tw-text-neutral-900">{template.label}</p>
    </li>
  );
};

interface LogoProps {
  id: TemplateId;
  isActive: boolean;
}

const Logo: React.FC<LogoProps> = ({ id }) => {
  switch (id) {
    case TemplateId.Original:
      return <OriginalLogo variant="colour" />;
    case TemplateId.StoryOrReel:
      return <InstagramLogo variant="colour" />;
    case TemplateId.IGPost:
      return <InstagramLogo variant="colour" />;
    case TemplateId.Instagram:
      return <InstagramLogo variant="colour" />;
    case TemplateId.TikTok:
      return <TikTokLogo variant="colour" />;
    case TemplateId.FBPost:
      return <FacebookLogo variant="colour" />;
    case TemplateId.Youtube:
      return <YouTubeLogo variant="colour" />;
    case TemplateId.YTShorts:
      return <YouTubeLogo variant="colour" />;
    case TemplateId.LinkedIn:
      return <LinkedInLogo variant="colour" />;
    case TemplateId.Vimeo:
      return <VimeoLogo variant="colour" />;
    default:
      return null;
  }
};
