import { useObservable } from "@mindspace-io/react";

import { assetsQuery } from "./assets.query";

export const useAssetsUploadingState = () => {
  const [uploading] = useObservable(
    assetsQuery.select((state) => state?.isUploading),
    false
  );

  return uploading ?? false;
};
