import React from "react";
import classNames from "classnames";

import { PlusIcon, TrashCanIcon } from "@shared/components/icons";
import { DEFAULT_PRIMARY_COLORS_V2 } from "@shared/interfaces/account";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@shared/primitives/dropdown";
import { Input } from "@shared/primitives/input";
import { compareStrings } from "@shared/utils/strings";

import { hexToRgba, isValidHex, rgbaToHex } from "@getsubly/common/dist/assParser/colors";
import { RgbaColor, RgbaColorPicker } from "@react-colorful";

import styles from "./color-picker.module.scss";

export interface ColorPickerProps {
  color: string; // Expect hex color
  onChange: (color: string) => void;
  showCustomColors?: boolean;
  customColors?: string[];
  addCustomColor?: (color: string) => void;
  deleteCustomColor?: (color: string) => void;
  showDefaultColors?: boolean;
  showAlpha?: boolean;
}
export const ColorPicker: React.FC<ColorPickerProps> = ({
  color: propsColor,
  onChange,
  showCustomColors,
  customColors: additionalColors,
  addCustomColor,
  deleteCustomColor,
  showDefaultColors = true,
  showAlpha
}) => {
  const [color, setColor] = React.useState(propsColor || "#000000");
  const [edit, setEdit] = React.useState(false);
  const [isValid, setIsValid] = React.useState(true);

  const handleChange = (rgba: RgbaColor) => {
    const hex = rgbaToHex(rgba);
    const newHex = showAlpha ? hex : hex.slice(0, 7);
    handleHexChange(newHex);
  };

  const handleHexChange = (hex: string) => {
    setColor(hex);
  };

  React.useEffect(() => {
    if (propsColor !== color) {
      if (isValidHex(color)) {
        onChange(color);
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [color]);

  const toggleEdit = () => {
    if (edit) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  };

  const handleAddColor = () => {
    if (!isValid) return;

    addCustomColor?.(color);
  };

  const renderDefaultColors = React.useMemo(() => {
    return (
      <div className="tw-mx-[5px] tw-grid tw-grid-cols-8 tw-justify-center tw-gap-2">
        {DEFAULT_PRIMARY_COLORS_V2?.map((c) => {
          return (
            <ColorChip
              key={c}
              color={c}
              selected={compareStrings(c, color?.slice(0, 7))}
              onClick={() => handleHexChange(c)}
            />
          );
        })}
      </div>
    );
  }, [color]);

  const renderCustomColors = React.useMemo(() => {
    if (!showCustomColors) {
      return null;
    }

    const customColors = additionalColors?.filter(
      (c) => isValidHex(c) && !DEFAULT_PRIMARY_COLORS_V2.includes(c.slice(0, 7))
    );

    return (
      <div className="tw-mx-[5px] tw-grid tw-grid-cols-8 tw-justify-center tw-gap-2">
        {addCustomColor && <AddColorButton onClick={handleAddColor} disabled={!isValid || edit} />}
        {customColors?.map((c) => {
          return (
            <ColorChip
              key={c}
              color={c}
              selected={compareStrings(c, color?.slice(0, 7))}
              onClick={() => handleHexChange(c)}
              onDelete={() => deleteCustomColor?.(c)}
              trash={edit}
            />
          );
        })}
      </div>
    );
  }, [additionalColors, color, isValid, edit, addCustomColor]);

  return (
    <div className="tw-flex tw-w-[270px]">
      <div
        className={classNames(
          styles["react-colorful"],
          {
            [styles["hide-alpha"]]: !showAlpha
          },
          "tw-flex tw-w-[270px] tw-flex-col tw-justify-center tw-gap-4 tw-p-4"
        )}
      >
        <RgbaColorPicker color={hexToRgba(color)} onChange={handleChange} />

        <ColorInputs color={color} onChange={(c) => setColor(c.color)} showAlpha={showAlpha} />

        {Boolean(showDefaultColors) && renderDefaultColors}

        {showCustomColors && (
          <div className="tw-mx-[2px] tw-flex tw-items-center tw-justify-between tw-text-xs">
            <p className="tw-text-xs tw-font-medium tw-text-neutral-800">Saved colors:</p>
            <p
              className="tw-cool-gray-500 tw-cursor-pointer tw-text-xs tw-font-medium tw-text-neutral-500"
              onClick={toggleEdit}
            >
              {edit ? "Done" : "Edit"}
            </p>
          </div>
        )}

        {renderCustomColors}
      </div>
    </div>
  );
};

interface ColorInputsProps {
  color: string;
  onChange: ({ color, isValid }: { color: string; isValid: boolean }) => void;
  showAlpha?: boolean;
  defaultSystem?: ColorSystem;
}
export const ColorInputs: React.FC<ColorInputsProps> = ({
  color,
  onChange,
  showAlpha,
  defaultSystem = ColorSystem.HEX
}) => {
  const [colorSystem, setColorSystem] = React.useState<ColorSystem>(defaultSystem);
  const [hex, setHex] = React.useState<string>(color);
  const [rgba, setRgba] = React.useState(hexToRgba(color));
  const [opacity, setOpacity] = React.useState<number>(hexToRgba(color).a || 1);

  const handleChange = (hexColor: string) => {
    onChange({ color: hexColor, isValid: isValidHex(hexColor) });
  };

  const handleChangeHex = (color: string) => {
    setHex(color);
    setRgba(hexToRgba(color));
    handleChange(color);
  };

  const handleChangeRgb = (type: "r" | "g" | "b", value: number) => {
    const updatedRgba = {
      ...rgba,
      [type]: value,
      a: showAlpha ? opacity : 1
    };

    setRgba(updatedRgba);
    setHex(rgbaToHex(updatedRgba));
    handleChange(rgbaToHex(updatedRgba));
  };

  const handleChangeOpacity = (value: number) => {
    setOpacity(value / 100);

    const updatedRgba = {
      ...rgba,
      a: showAlpha ? value / 100 : 1
    };

    setRgba(updatedRgba);
    setHex(rgbaToHex(updatedRgba));
    handleChange(rgbaToHex(updatedRgba));
  };

  React.useEffect(() => {
    setHex(color);
    setRgba(hexToRgba(color));
    setOpacity(hexToRgba(color).a ?? 1);
  }, [color]);

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
      <ColorSystemDropdown system={colorSystem} onChange={setColorSystem} className="!tw-px-0.5 tw-py-0" />

      {colorSystem === ColorSystem.HEX && (
        <Input
          type="text"
          sizeH="32"
          value={hex.slice(0, 7)}
          onChange={(c) => handleChangeHex(c.target.value)}
          maxLength={7}
          className="tw-h-7 tw-px-1.5 tw-text-xs focus:!tw-px-1.5 "
        />
      )}

      {colorSystem === ColorSystem.RGB && (
        <div className="tw-flex tw-flex-row tw-gap-2">
          <Input
            type="number"
            sizeH="32"
            className="!tw-px-1"
            value={rgba.r}
            onChange={({ target }) => {
              handleChangeRgb("r", Number(target.value));
            }}
            min={0}
            max={255}
          />
          <Input
            type="number"
            sizeH="32"
            className="!tw-px-1"
            value={rgba.g}
            onChange={({ target }) => handleChangeRgb("g", Number(target.value))}
            min={0}
            max={255}
          />
          <Input
            type="number"
            sizeH="32"
            className="!tw-px-1"
            value={rgba.b}
            onChange={({ target }) => handleChangeRgb("b", Number(target.value))}
            min={0}
            max={255}
          />
        </div>
      )}

      {showAlpha && (
        <Input
          type="number"
          sizeH="32"
          className="!tw-px-1"
          value={Math.round(opacity * 100)}
          onChange={({ target }) => handleChangeOpacity(Number(target.value))}
          min={0}
          max={100}
        />
      )}
    </div>
  );
};

export enum ColorSystem {
  HEX = "HEX",
  RGB = "RGB"
}

interface ColorSystemProps {
  system: ColorSystem;
  onChange: (system: ColorSystem) => void;
  className?: string;
}
export const ColorSystemDropdown: React.FC<ColorSystemProps> = ({ system, onChange, className }) => {
  return (
    <Dropdown>
      <DropdownButton className={className} size="32">
        <p className="tw-text-xs">{system}</p>
      </DropdownButton>
      <DropdownMenu className="tw-w-full tw-overflow-y-auto">
        {Object.values(ColorSystem).map((s) => (
          <DropdownItem
            key={s}
            onClick={() => onChange(s)}
            selected={s === system}
            className="tw-flex tw-items-center tw-justify-between"
          >
            <p className="tw-text-sm tw-font-normal">{s}</p>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

interface AddColorButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}
export const AddColorButton: React.FC<AddColorButtonProps> = ({ onClick, disabled = false }) => {
  return (
    <div
      className={classNames(
        "tw-relative tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-neutral-200",
        {
          "tw-cursor-pointer hover:tw-border-2": !disabled,
          "tw-cursor-not-allowed": disabled
        }
      )}
      onClick={disabled ? undefined : onClick}
    >
      <PlusIcon className="tw-w-[10px tw-h-[10px]" />
    </div>
  );
};

import { best } from "wcag-color";

interface ColorChipProps {
  color?: string;
  selected?: boolean;
  trash?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}
export const ColorChip: React.FC<ColorChipProps> = ({ color, selected, trash, onClick, onDelete }) => {
  const contrastColor = color?.match(/#(([0-9a-fA-F]{2}){3,4}|([0-9a-fA-F]){3,4})/g)
    ? best("#000000", "#FFFFFF", color.slice(0, 7))
    : "#000000";

  return (
    <div
      className={classNames("tw-relative tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full", {
        "tw-ring-2 tw-ring-[#0472E7] tw-ring-offset-2": selected,
        "tw-border tw-border-neutral-200": !selected,
        "tw-cursor-pointer hover:tw-border-2 hover:tw-border-neutral-900": Boolean(onClick || onDelete)
      })}
      style={{
        backgroundColor: color
      }}
      onClick={() => {
        if (trash && onDelete) {
          onDelete();
        } else {
          onClick?.();
        }
      }}
    >
      {!color && (
        <div className="tw-absolute -tw-left-[1px] tw-h-full tw-w-full tw-translate-x-1/2">
          <div className="tw-h-full tw-w-[2px] -tw-rotate-45 tw-bg-destructive-600" />
        </div>
      )}
      {trash && <TrashCanIcon className="tw-h-[10px] tw-w-[10px]" stroke={contrastColor?.slice(0, 7)} />}
    </div>
  );
};
