import React from "react";

import { Range } from "@shared/primitives/range/range";

import { CustomLogoConfig, CustomLogoSize } from "@getsubly/common";

const LOGO_SIZE_SCALES: Record<CustomLogoSize, number> = {
  [CustomLogoSize.Small]: 0.1,
  [CustomLogoSize.Medium]: 0.2,
  [CustomLogoSize.Large]: 0.3
} as const;

const LOGO_SIZE_LABELS: Record<CustomLogoSize, string> = {
  [CustomLogoSize.Small]: "S",
  [CustomLogoSize.Medium]: "M",
  [CustomLogoSize.Large]: "L"
} as const;

type LogoAppearanceControlProps = {
  customLogo: CustomLogoConfig;
  onChange: (customLogo: CustomLogoConfig) => void;
};
export const LogoAppearanceControl: React.FC<LogoAppearanceControlProps> = (props) => {
  const handleChange = <Key extends keyof CustomLogoConfig, Value extends CustomLogoConfig[Key]>(
    key: Key,
    value: Value
  ) => {
    props.onChange({ ...props.customLogo, [key]: value });
  };

  return (
    <div className="tw-grid tw-flex-col tw-divide-y tw-divide-neutral-200 tw-rounded-md tw-border tw-border-neutral-200">
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-px-6 tw-py-4">
        <p className="tw-w-[52px] tw-shrink-0 tw-text-sm tw-font-medium">Size</p>
        <div className="tw-flex tw-w-full tw-flex-row tw-gap-3">
          <Range
            value={LOGO_SIZE_SCALES[props.customLogo.size]}
            min={0.1}
            max={0.3}
            step={0.1}
            onChange={(value: number) => {
              handleChange(
                "size",
                { [0.1]: CustomLogoSize.Small, [0.2]: CustomLogoSize.Medium, [0.3]: CustomLogoSize.Large }[value]!
              );
            }}
            onClick={(e) => e.stopPropagation()}
            trackColor="#B9DAFE"
          />
          <span className="tw-w-10 tw-text-center tw-text-sm tw-font-medium tw-text-neutral-600">
            {LOGO_SIZE_LABELS[props.customLogo.size]}
          </span>
        </div>
      </div>
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-px-6 tw-py-4">
        <p className="tw-w-[52px] tw-text-sm tw-font-medium">Opacity</p>
        <Range
          className="tw-gap-3 !tw-text-sm tw-font-medium tw-text-neutral-600"
          hasLabel
          value={props.customLogo.opacity}
          onChange={(opacity) => handleChange("opacity", opacity)}
          trackColor="#B9DAFE"
        />
      </div>
    </div>
  );
};
