import React from "react";

import { ToolTip } from "@shared/primitives/tooltip";

import { LetterCase } from "@getsubly/common";
import { ToolbarButton } from "@media-editor/components/buttons/toolbar-button";

interface LetterCaseControlProp {
  value?: null | LetterCase;
  onChange: (value: null | LetterCase) => void;
}
export const LetterCaseControl: React.FC<LetterCaseControlProp> = ({ value, onChange }) => {
  return (
    <div className="tw-inline-flex tw-w-fit tw-gap-1 tw-rounded tw-border tw-border-neutral-200 tw-p-1">
      <ToolTip text="Apply none">
        <ToolbarButton active={!value} onClick={() => onChange(null)}>
          -
        </ToolbarButton>
      </ToolTip>
      <ToolTip text="Uppercase">
        <ToolbarButton active={value === LetterCase.Uppercase} onClick={() => onChange?.(LetterCase.Uppercase)}>
          AG
        </ToolbarButton>
      </ToolTip>
      <ToolTip text="Lowercase">
        <ToolbarButton active={value === LetterCase.Lowercase} onClick={() => onChange?.(LetterCase.Lowercase)}>
          aa
        </ToolbarButton>
      </ToolTip>
      <ToolTip text="Sentence case">
        <ToolbarButton active={value === LetterCase.Capitalise} onClick={() => onChange?.(LetterCase.Capitalise)}>
          Ag
        </ToolbarButton>
      </ToolTip>
    </div>
  );
};
