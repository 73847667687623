import React from "react";

import { CookiePreferences } from "@frontend/components/buttons/cookie-preferences";
import { ProfileCard } from "@frontend/components/settings/profile-card/profile-card";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";

export const ProfilePage: React.FC = () => {
  return (
    <div className="tw-flex tw-flex-col">
      <SettingsPageHeader title="Profile" description="Manage your profile" className="tw-mb-6" />
      <div className="tw-flex tw-max-w-lg tw-flex-col tw-gap-6">
        <ProfileCard />
        <CookiePreferences />
      </div>
    </div>
  );
};
