import React from "react";
import { useForm } from "react-hook-form";

import { Button } from "@shared/primitives/button";
import { Fieldset } from "@shared/primitives/fieldset";
import { Input } from "@shared/primitives/input";
import { Modal } from "@shared/primitives/modal";

type RenameForm = { name: string };

interface RenamePresetModalProps {
  name: string;
  onSave: (name: string) => Promise<void>;
  onDismiss: () => void;
}

export const RenamePresetModal: React.FC<RenamePresetModalProps> = (props) => {
  return (
    <Modal onDismiss={props.onDismiss} disableBackgroundDismiss>
      <RenamePresetContent {...props} />
    </Modal>
  );
};

export const RenamePresetContent: React.FC<RenamePresetModalProps> = ({ name, onSave, onDismiss }) => {
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RenameForm>({
    defaultValues: { name }
  });

  const handleSave = async ({ name }: RenameForm) => {
    try {
      setLoading(true);

      await onSave(name);

      onDismiss();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h5 className="tw-mb-4 tw-text-h5">Rename preset</h5>
      <form onSubmit={handleSubmit(handleSave)}>
        <Fieldset className="tw-mb-4">
          <Input {...register("name", { required: true })} hasError={!!errors.name} autoFocus />
        </Fieldset>

        <div className="tw-flex tw-justify-end">
          <Button variant="secondary" onClick={onDismiss} size="36">
            Cancel
          </Button>
          <Button variant="primary" className="tw-ml-2 tw-px-4" type="submit" loading={loading} size="36">
            Rename
          </Button>
        </div>
      </form>
    </>
  );
};
