import React from "react";

import { updateAccountSettings } from "@frontend/api/account.service";
import { getAssets } from "@frontend/api/assets.service";
import { BrandDefaultsCard } from "@frontend/components/settings/brand-defaults/brand-defaults-card";
import { DefaultGuidelines } from "@frontend/components/settings/brand-defaults/default-guidelines";
import { DefaultLogo } from "@frontend/components/settings/brand-defaults/default-logo";
import { DefaultStyle } from "@frontend/components/settings/brand-defaults/default-style";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";

import { LockFeature } from "@shared/components/lock-feature/lock-feature";
import { SublyPlan } from "@shared/interfaces/billing";
import { notificationSuccess } from "@shared/primitives/notification";
import { accountQuery, accountStore } from "@shared/state/account";

import { BBCGuidelinePreset, BurnPosition, CustomLogoSize, GuidelinePreset, MediaConfig } from "@getsubly/common";
import { DEFAULT_CONFIG } from "@getsubly/common/dist/assParser/subtitle.text";

const DEFAULT_MEDIA_CONFIG = {
  customLogo: {
    visible: true,
    id: "",
    url: "",
    position: BurnPosition.TopRight,
    opacity: 75,
    size: CustomLogoSize.Medium,
    dimensions: { width: 0, height: 0 },
    padding: { x: 10, y: 10 }
  },
  ...DEFAULT_CONFIG
};

export const BrandDefaultsPage: React.FC = () => {
  const [defaultMediaConfig, setDefaultMediaConfig] = React.useState<MediaConfig>({
    ...DEFAULT_MEDIA_CONFIG,
    ...accountQuery.settings?.defaultMediaConfig
  });
  const [defaultGuidelinePreset, setDefaultGuidelinePreset] = React.useState<GuidelinePreset>(accountQuery.defaultGuidelinePreset ?? BBCGuidelinePreset); // eslint-disable-line

  const handleSaveDefaultStyle = async (updatedDefaultMediaConfig: MediaConfig) => {
    updateAccountSettings({ defaultMediaConfig: updatedDefaultMediaConfig });
    await accountStore.updateAccountSettings({ defaultMediaConfig: updatedDefaultMediaConfig });
    setDefaultMediaConfig(updatedDefaultMediaConfig);
    notificationSuccess("Default styles saved");
  };

  const handleSaveDefaultGuideline = async (updatedDefaultGuidelinePreset: GuidelinePreset) => {
    updateAccountSettings({ defaultGuidelinePreset: updatedDefaultGuidelinePreset });
    await accountStore.updateAccountSettings({ defaultGuidelinePreset: updatedDefaultGuidelinePreset });
    setDefaultGuidelinePreset(updatedDefaultGuidelinePreset);
    notificationSuccess("Default guidelines saved");
  };

  const handleSaveDefaultLogo = async (updatedMediaConfig?: MediaConfig) => {
    updateAccountSettings({ defaultMediaConfig: { ...defaultMediaConfig, ...updatedMediaConfig } });
    await accountStore.updateAccountSettings({ defaultMediaConfig: { ...defaultMediaConfig, ...updatedMediaConfig } });
    setDefaultMediaConfig({ ...defaultMediaConfig, ...updatedMediaConfig });
    notificationSuccess("Default logo saved");
  };

  React.useEffect(() => {
    getAssets();
  }, []);

  return (
    <LockFeature minPermission={SublyPlan.Personal}>
      <SettingsPageHeader
        title="Defaults"
        description="Manage your default subtitle styles and global settings"
        className="tw-mb-6"
      />
      <BrandDefaultsCard>
        <DefaultStyle defaultMediaConfig={defaultMediaConfig} onSave={handleSaveDefaultStyle} /> {/* eslint-disable-line */}
        <div className="tw-my-2 tw-block tw-h-px tw-bg-neutral-200" />
        <DefaultGuidelines defaultGuidelinePreset={defaultGuidelinePreset} onSave={handleSaveDefaultGuideline} /> {/* eslint-disable-line */}
        <div className="tw-my-2 tw-block tw-h-px tw-bg-neutral-200" />
        <DefaultLogo defaultMediaConfig={defaultMediaConfig} onSave={handleSaveDefaultLogo} />
      </BrandDefaultsCard>
    </LockFeature>
  );
};
