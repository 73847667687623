import React from "react";
import classNames from "classnames";

import { BackgroundStyle } from "@getsubly/common";
import defaultImage from "@media-editor/assets/effect-bg.png";

import { GalleryPicker } from "../../primitives/gallery-picker";
import { GalleryPickerItem } from "../../primitives/gallery-picker/gallery-picker-item";

const EFFECTS = [
  { style: BackgroundStyle.None, label: "None", text: "No effects" },
  { style: BackgroundStyle.DropShadow, label: "Drop shadow", text: "Drop shadow", effectClassName: "[text-shadow:_0px_2.61157px_2.61157px_rgba(0,_0,_0,_0.54)]" }, // eslint-disable-line
  { style: BackgroundStyle.Background, label: "Background", text: "Background", effectClassName: "tw-bg-black tw-px-1" }, // eslint-disable-line
  { style: BackgroundStyle.Outline, label: "Outline", text: "Outline", effectClassName: "[text-shadow:_rgb(0,_0,_0)_-1px_1px_0px,_rgb(0,_0,_0)_1px_1px_0px,_rgb(0,_0,_0)_1px_-1px_0px,_rgb(0,_0,_0)_-1px_-1px_0px]" }, // eslint-disable-line
  { style: BackgroundStyle.FullWidth, label: "Full Width", text: "Full width", effectClassName: "!tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-h-8 tw-items-center tw-justify-center tw-rounded-b-[3px] tw-bg-black" } // eslint-disable-line
];

const DEFAULT_EFFECT: {
  style: BackgroundStyle;
  outline: number;
} = {
  style: BackgroundStyle.None,
  outline: 1
};

interface EffectPresetGalleryPickerProps {
  effect?: { style: BackgroundStyle; outline: number };
  onChange: (effect: { style: BackgroundStyle; outline: number }) => void;
  className?: string;
  includeFullWidthEffect?: boolean;
}

export const EffectPresetGalleryPicker: React.FC<EffectPresetGalleryPickerProps> = (props) => {
  const [activeStyle, setStyle] = React.useState(props.effect?.style || DEFAULT_EFFECT.style);
  const outline = React.useMemo(() => props.effect?.outline || DEFAULT_EFFECT.outline, [props.effect?.outline]);

  React.useEffect(() => {
    if (props.effect?.style && props.effect.style !== activeStyle) {
      setStyle(props.effect.style);
    }
  }, [props.effect?.style]);

  const handleSelect = (style: BackgroundStyle) => {
    if (style !== activeStyle) {
      setStyle(style);
      props.onChange({ style, outline });
    }
  };

  const effects = props.includeFullWidthEffect ? EFFECTS : EFFECTS.slice(0, -1);

  return (
    <GalleryPicker className={props.className}>
      {effects.map(({ style, label, text, effectClassName }) => (
        <GalleryPickerItem
          key={style}
          className="tw-width-[106.5px] tw-min-w-[106.5px]"
          previewClassName="tw-aspect-[164/154]"
          selected={activeStyle === style}
          onClick={() => handleSelect(style)}
          image={defaultImage}
          label={label}
        >
          <span className={classNames(`tw-absolute tw-bottom-2 tw-z-20 tw-text-sm tw-font-semibold tw-text-white`, effectClassName)}> {/* eslint-disable-line */}
            {text}
          </span>
        </GalleryPickerItem>
      ))}
    </GalleryPicker>
  );
};
