// Dashboard
export const DASHBOARD_PATH = "/";
export const DASHBOARD_ALL_MEDIA_PATH = "/all-media";
export const DASHBOARD_SHARED_WITH_ME_PATH = "/shared-with-me";
export const ORDERS_PATH = "/orders";

// Authentication
export const LOGIN_PATH = "/login";
export const SIGN_IN_PATH = "/signin";
export const SIGN_UP_PATH = "/signup";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_PATH = "/reset/:id/:code";
export const VERIFICATION_PATH = "/verify/:id/:code";
export const EXTERNAL_VERIFICATION_PATH = "/verify/external/:id/:code";
export const EMAIL_VERIFICATION_PATH = "/verify-email/:id/:code";
export const MAGIC_LINK_PATH = "/magic-link";
export const OAUTH2_GOOGLE_CALLBACK_PATH = "/auth/google/callback";
export const OAUTH2_MICROSOFT_CALLBACK_PATH = "/auth/microsoft/callback";

// Settings
export const SETTINGS_PROFILE_PATH = "/settings";
export const SETTINGS_PROFILE_EDIT_PATH = "/settings/profile/edit";
export const SETTINGS_WORKSPACE_PATH = "/settings/workspace";
export const SETTINGS_API_PATH = "/settings/api";
export const SETTINGS_BRAND_DEFAULTS_PATH = "/settings/brand-defaults";
export const SETTINGS_BILLING_PATH = "/settings/billing";
export const SETTINGS_BILLING_DETAILS_PATH = "/settings/billing/edit";
export const SETTINGS_BILLING_INVOICES_PATH = "/settings/billing/invoices";
export const SETTINGS_USERS_PATH = "/settings/users";
export const SETTINGS_GROUPS_PATH = "/settings/groups";
export const SETTINGS_GLOSSARY_PATH = "/settings/glossary";
export const SETTINGS_APPS_PATH = "/settings/apps";
export const SETTINGS_APP_PATH = "/settings/apps/:appId";
export const SETTINGS_USAGE_PATH = "/settings/usage";
export const SETTINGS_USAGE_TRANSACTIONS_PATH = "/settings/usage/transactions";

// Media
export const MEDIA_PATH = "/media";
export const MEDIA_EDITOR_PATH = "/media/:mediaId";
export const OLD_MEDIA_EDITOR_PATH = "/videos/:mediaId";

// Folders
export const FOLDERS_PATH = "/folders";
export const FOLDER_PATH = "/folders/:folderId";
export const getFolderPath = (folderId: string) => "/folders/" + folderId;

// Workspace
export const CREATE_OR_JOIN_PATH = "/create-or-join";
export const OPEN_WORKSPACE_PATH = "/open-workspace/:workspaceId";

// Zoom
export const ZOOM_CALLBACK_PATH = "/callback/zoom";

// Preview video
export const PREVIEW_VIDEO_PATH = "/preview/:publicShareId";

// SublyClick Microsoft
export const SUBLY_CLICK_PATH = "/subly-click";
export const SUBLY_CLICK_INSTALL_PATH = "/subly-click-install";

// Public website
export const WEBSITE_URL = "https://getsubly.com/";
export const FAQ_URL = "https://getsublyhelp.zendesk.com/hc/en-us";
export const PRIVACY_URL = "https://www.getsubly.com/legal/privacy-policy";
export const TERMS_URL = "https://www.getsubly.com/legal/terms-of-service";
export const SALES_CONTACT_URL = "https://www.getsubly.com/contact/sales";
export const ENTERPRISE_URL = "https://www.getsubly.com/enterprise";
export const AUDIO_DESCRIPTIONS_URL = "https://www.getsubly.com/features/audio-descriptions";

// API Docs
export const API_DOCS_URL = "https://docs.getsubly.com";

// Help & Updates
export const WHATS_NEW_URL = "https://getsublyhelp.zendesk.com/hc/en-us/categories/17966351603345-New-Releases";
export const ZENDESK_HELP_URL = "https://getsublyhelp.zendesk.com/hc/en-us";
export const AUDIO_DESCRIPTIONS_INFO_URL =
  "https://getsublyhelp.zendesk.com/hc/en-us/articles/26564384021649--Human-Services-what-does-Subly-offer#h_01J0QY5W4FN56DWNZ1RVFEP30A";
export const HUMAN_SERVICES_INFO_URL =
  "https://getsublyhelp.zendesk.com/hc/en-us/articles/26564384021649--Human-Services-what-does-Subly-offer";
export const HUMAN_SERVICES_TALK_TO_US_URL = "https://calendly.com/subly-training/subly-human-services-exploration";
export const SUMMARY_SERVICES_LEARN_MORE_URL =
  "https://getsublyhelp.zendesk.com/hc/en-us/articles/27884383505169-Automatic-Notes-and-Summaries-for-Meetings-Videos-and-Webinars";

// Expired paths
export const EXPIRED_APPS_PATH = "/apps";
