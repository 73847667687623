import React from "react";
import classNames from "classnames";

import defaultImage from "@media-editor/assets/effect-bg.png";

interface GalleryPickerItemProps {
  selected?: boolean;
  image?: string;
  label?: string;
  children?: React.ReactNode;
  previewClassName?: string;
  className?: string;
  onClick?: () => void;
}

export const GalleryPickerItem: React.FC<GalleryPickerItemProps> = (props) => {
  return (
    <div
      className={classNames(
        "tw-group tw-flex tw-flex-1 tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center",
        props.className
      )}
      onClick={props.onClick}
    >
      <div
        className={classNames(
          classNames(
            "tw-relative tw-flex tw-w-full tw-select-none tw-flex-row tw-items-center tw-justify-center tw-rounded-md tw-border-[3px] tw-border-transparent !tw-bg-neutral-50 tw-p-4",
            props.previewClassName
          ),
          "tw-opacity-60 group-hover:tw-opacity-80",
          { "!tw-border-aux-600 !tw-bg-aux-600 !tw-opacity-100 group-hover:!tw-opacity-100": props.selected }
        )}
        style={{ backgroundImage: defaultImage }}
      >
        {props.image && (
          <img
            src={props.image}
            alt="Effect image"
            className="tw-absolute tw-top-0 tw-z-10 tw-h-full tw-w-full tw-overflow-hidden tw-rounded tw-object-cover"
          />
        )}
        {props.children}
      </div>
      {props.label && (
        <p
          className={classNames(
            "tw-mt-2 tw-w-20 tw-text-center tw-text-xs",
            "tw-opacity-60 group-hover:tw-opacity-100",
            { "!tw-opacity-100": props.selected }
          )}
        >
          {props.label}
        </p>
      )}
    </div>
  );
};
