import axios, { AxiosResponse } from "axios";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import {
  AccountMember,
  AccountSettings,
  AccountTemplate,
  DomainAccount,
  Invitation,
  ITransaction,
  UserAccount
} from "@shared/interfaces/account";
import { CurrencyCode, SublyPlan } from "@shared/interfaces/billing";
import { User } from "@shared/interfaces/user";
import { accountStore, DEFAULT_ACCOUNT_SETTINGS } from "@shared/state/account";
import { authStore } from "@shared/state/auth/auth.store";
import { editorUiStateRepository } from "@shared/state/editor-ui";

import { GuidelinePreset } from "@getsubly/common";

import { getAccessToken } from "./auth.service";
import { AccountType } from "./billing.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface AccountTeamResponse {
  members: AccountMember[];
  invitations: Invitation[];
  planCapacity: number;
  additionalSeats: number;
}
export const _getAccountTeam = async (accountId: string): Promise<AccountTeamResponse> => {
  try {
    return axios
      .get<AccountTeamResponse>(`/accounts/${accountId}/team`, {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      })
      .then((response) => response.data);
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const getAccountTeam = async (): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    const data = await _getAccountTeam(accountId);

    accountStore.update((s) => ({
      ...s,
      planTeamCapacity: data.planCapacity,
      additionalSeats: data.additionalSeats,
      accountTeam: {
        loading: false,
        loaded: true,
        capacity: data.planCapacity,
        invitations: data.invitations,
        members: data.members,
        additionalSeats: data.additionalSeats
      }
    }));
  } catch (error) {
    handleError(error);
  }
};

interface AccountSettingsResponse {
  settings: AccountSettings;
}
export const getAccountSettings = async (): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    const { data } = await axios.get<AccountSettingsResponse>(`/${accountId}/settings`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    accountStore.updateWithShowReleaseModal(data.settings);
  } catch (error) {
    handleError(error);
  }
};

interface UpdateAccountSettingsResponse {
  settings: AccountSettings;
}
export const updateAccountSettings = async (settings: Partial<AccountSettings>): Promise<AccountSettings> => {
  try {
    const { data } = await axios.put<UpdateAccountSettingsResponse>(
      `/${getAccountId()}/settings`,
      { settings },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    accountStore.update({
      settings: { ...DEFAULT_ACCOUNT_SETTINGS, ...data.settings }
    });

    editorUiStateRepository.updateState({
      accountSettings: { ...DEFAULT_ACCOUNT_SETTINGS, ...data.settings }
    });

    return data.settings;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const saveAccountPresets = async (templates: AccountTemplate[]): Promise<void> => {
  await updateAccountSettings({ templates });
};

export const updateAccountColours = async (
  colours: Partial<Pick<AccountSettings, "primaryColors" | "outlineColors" | "aspectRatioColors" | "borderColors">>
): Promise<void> => {
  await updateAccountSettings({ ...colours });
};

interface IssueReport {
  accountId?: string;
  mediaId?: string;
  message?: string;
}
export const reportIssue = async (issue: IssueReport): Promise<void> => {
  try {
    await axios.post(`/${getAccountId()}/report-issue`, issue, {
      baseURL,
      headers: {
        "x-access-token": await getAccessToken()
      }
    });
  } catch (e) {
    handleError(e);
  }
};

export const startCheckout = async (plan: SublyPlan): Promise<void> => {
  try {
    await axios.post(
      `/plans/${getAccountId()}/start-checkout`,
      { plan },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export interface EditUserProfileParams {
  givenName: string;
  familyName: string;
  email: string;
  name: string;
  password: string;
  newPassword: string;
  passwordConfirm: string;
}
interface UserProfileResponse {
  data: {
    user: User;
  };
}
export const editUserProfile = async (data: EditUserProfileParams): Promise<AxiosResponse<UserProfileResponse>> => {
  return await axios.put<UserProfileResponse>(`${baseURL}/auth/update`, data, {
    headers: { "x-access-token": await getAccessToken() }
  });
};

export const editUserProfilePicture = async (file: File): Promise<AxiosResponse<UserProfileResponse>> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  return await axios.put<UserProfileResponse>(`${baseURL}/auth/update-picture`, bodyFormData, {
    headers: {
      "Content-Type": "form-data",
      "x-access-token": await getAccessToken()
    }
  });
};

export const removeUserProfilePicture = async (): Promise<AxiosResponse<unknown>> => {
  return await axios.put(
    `${baseURL}/auth/remove-picture`,
    {},
    {
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );
};

export const deleteUser = async (): Promise<AxiosResponse<unknown>> =>
  await axios.delete(`${baseURL}/auth/user`, {
    headers: {
      "x-access-token": await getAccessToken()
    }
  });

export interface NewsletterFormData {
  givenName: string;
  familyName: string;
  email: string;
}
export const subscribeNewsletter = async (data: NewsletterFormData): Promise<AxiosResponse<unknown>> => {
  return await axios.post(`${baseURL}/marketing/newsletter`, data);
};

interface ICreateAccountData {
  accountName: string;
  accountType?: AccountType;
}
interface CreateAccountResponse {
  account: UserAccount;
}

export const createAccount = async (data: ICreateAccountData): Promise<UserAccount> => {
  const {
    data: { account }
  } = await axios.post<CreateAccountResponse>(`/accounts`, data, {
    baseURL,
    headers: {
      "x-access-token": await getAccessToken()
    }
  });

  return account;
};

interface CreateBusinessAccount {
  accountName: string;
}
export const createBusinessAccount = async (data: CreateBusinessAccount): Promise<UserAccount> => {
  const businessAccount = await createAccount({
    ...data,
    accountType: AccountType.Business
  });

  addAccountToStore(businessAccount);

  return businessAccount;
};

export const addAccountToStore = (account: UserAccount): void => {
  authStore.update((s) => {
    if (!s.user?.accounts) {
      return s;
    }

    return {
      ...s,
      user: { ...s.user, accounts: [...s.user.accounts, account] },
      accountId: account.accountId
    };
  });
};

export const updateAccountInStore = (account: UserAccount): void => {
  authStore.update((s) => {
    if (!s.user?.accounts) {
      return s;
    }

    const updatedAccounts = [...s.user.accounts.filter((a) => a.accountId !== account.accountId), account];

    return {
      ...s,
      user: { ...s.user, accounts: [...updatedAccounts] },
      accountId: account.accountId
    };
  });
};

export const updateAccountName = async (name: string): Promise<void> => {
  const accountId = getAccountId();

  try {
    await axios.put(
      `/accounts/${accountId}/rename`,
      { name },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    accountStore.update({ accountName: name });
    authStore.updateAccount(accountId, { accountName: name });
  } catch (error) {
    handleError(error);
  }
};

interface UpdateAccountPictureResponse {
  data: {
    account: UserAccount;
  };
}

export const updateAccountPicture = async (file: File): Promise<void> => {
  const accountId = getAccountId();

  try {
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);

    const response = await axios.put<UpdateAccountPictureResponse>(
      `/accounts/${accountId}/update-picture`,
      bodyFormData,
      {
        baseURL,
        headers: {
          "Content-Type": "form-data",
          "x-access-token": await getAccessToken()
        }
      }
    );
    const account = response.data.data.account;

    accountStore.update({ accountPicture: account.accountPicture });
    authStore.updateAccount(accountId, { accountPicture: account.accountPicture });
  } catch (error) {
    handleError(error);
  }
};

export const scheduleAccountDeletion = async (
  cancelDeletion?: boolean,
  initiatorName?: string,
  initiatorEmail?: string
): Promise<void> => {
  const accountId = getAccountId();

  const { data } = await axios.put(
    `/accounts/${accountId}/schedule-deletion`,
    { cancelDeletion, initiatorName, initiatorEmail },
    {
      baseURL,
      headers: {
        "x-access-token": await getAccessToken()
      }
    }
  );

  accountStore.update({
    scheduledDeletionDate: data.updatedAccount.scheduledDeletionDate
  });
};

export const updateAccountCurrency = async (currency: CurrencyCode): Promise<void> => {
  const accountId = getAccountId();

  try {
    await axios.put(
      `/accounts/${accountId}/currency`,
      { currency },
      {
        baseURL,
        headers: {
          "x-access-token": await getAccessToken()
        }
      }
    );

    accountStore.update({ currency });
  } catch (error) {
    handleError(error);
  }
};

export const updateAccountDefaultGuidelinePreset = async (defaultGuidelinePreset: GuidelinePreset): Promise<void> => {
  await updateAccountSettings({ defaultGuidelinePreset });
};

export const getAPITransactions = async (): Promise<ITransaction[]> => {
  const accountId = getAccountId();

  const { data } = await axios.get<ITransaction[]>(`/${accountId}/transactions`, {
    baseURL,
    headers: {
      "x-access-token": await getAccessToken()
    }
  });

  return data;
};

/**
 * Gets all email domains that this workspace has been marked "discoverable" for.
 */
export const getConfiguredDiscoveryDomains = async (
  accountId: string
): Promise<{ accountId: string; domains: string[] }> => {
  try {
    const { data } = await axios.get<{ accountId: string; domains: string[] }>(
      `/accounts/${accountId}/discovery/domains`,
      { baseURL, headers: { "x-access-token": await getAccessToken() } }
    );
    return data;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

/**
 * Updates the list of domains that this workspace has been made discoverable to.
 */
export const updateDiscoveryDomains = async (
  accountId: string,
  domains: string[]
): Promise<{ accountId: string; domains: string[] }> => {
  try {
    const { data } = await axios.put<{ accountId: string; domains: string[] }>(
      `/accounts/${accountId}/discovery/domains`,
      { domains },
      { baseURL, headers: { "x-access-token": await getAccessToken() } }
    );
    return data;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

/**
 * Fetches all email domains that the admin can make the workspace discoverable to.
 * These domains are taken from users in the workspace, excluding known public domains like gmail and hotmail.
 */
export const getAvailableDiscoveryDomains = async (
  accountId: string
): Promise<{ accountId: string; domains: string[] }> => {
  try {
    const { data } = await axios.get<{ accountId: string; domains: string[] }>(
      `/accounts/${accountId}/discovery/domains/available`,
      { baseURL, headers: { "x-access-token": await getAccessToken() } }
    );
    return data;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

/**
 * Fetches a list of accounts that have been marked as discoverable by domain.
 */
export const getDiscoverableAccounts = async (domain: string): Promise<DomainAccount[]> => {
  try {
    const { data } = await axios.get<{ accounts: DomainAccount[] }>(`/accounts/discovery/available?domain=${domain}`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
    return data.accounts;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

/**
 * Fetches a list of accounts that have been marked as discoverable by domain.
 */
export const joinAccount = async (
  accountId: string,
  userId: string
): Promise<{ accountId: string; accountName: string }> => {
  try {
    const { data } = await axios.get<{ accountId: string; accountName: string }>(
      `/accounts/${accountId}/discovery/join?userId=${userId}`,
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
    return data;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};
