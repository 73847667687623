import classNames from "classnames";

interface GalleryPickerProps {
  className?: string;
  children: React.ReactNode;
}
export const GalleryPicker: React.FC<GalleryPickerProps> = (props) => {
  return (
    <div className={classNames("tw-relative tw-flex tw-gap-2 tw-overflow-x-auto", props.className)}>
      {props.children}
    </div>
  );
};
