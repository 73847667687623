import React from "react";
import classNames from "classnames";

import { TitleDeleteModal } from "@frontend/components/modals/title-delete-modal";

import { ClockIcon, FilmIcon, TrashCanOutlinePauseIcon } from "@shared/components/icons";
import { useModal } from "@shared/hooks/use-modal";
import { ModalType } from "@shared/interfaces/modal-type";
import { ToolTip } from "@shared/primitives/tooltip";
import { sortTitlesAsc } from "@shared/utils/arrays";
import { formatTime, getTimeObject, msToSec, parseSecondsToText } from "@shared/utils/time";

import { MediaConfig, TitleConfig } from "@getsubly/common";

type SavedTextsSectionProps = {
  titles: TitleConfig[];
  mediaConfig: MediaConfig;
  mediaLength: number;
  activeTitleId?: string;
  onClickTitle: (titleId: string) => void;
  onDeleteTitle: (titleId: string) => void;
};
export const SavedTitlesSection: React.FC<SavedTextsSectionProps> = ({
  titles,
  mediaConfig,
  mediaLength,
  activeTitleId,
  onClickTitle,
  onDeleteTitle
}) => {
  return (
    <section className="tw-px-4">
      <h3 className="tw-mb-3 tw-text-md tw-font-semibold tw-text-neutral-900">Saved texts</h3>
      <div className="tw-m-0 tw-flex tw-w-full tw-flex-col tw-items-center tw-text-center">
        <div className="tw-w-full">
          <TitleList
            titles={titles}
            mediaConfig={mediaConfig}
            mediaLength={mediaLength}
            activeTitleId={activeTitleId}
            onClickTitle={onClickTitle}
            onDeleteTitle={onDeleteTitle}
          />
        </div>
      </div>
    </section>
  );
};

interface TitleListProps {
  mediaConfig: MediaConfig;
  mediaLength: number;
  titles: TitleConfig[];
  activeTitleId?: string;
  onClickTitle: (titleId: string) => void;
  onDeleteTitle: (titleId: string) => void;
}

const TitleList: React.FC<TitleListProps> = ({
  mediaConfig,
  mediaLength,
  titles,
  activeTitleId,
  onDeleteTitle,
  onClickTitle
}) => {
  const sortedTitles = [...titles].sort(sortTitlesAsc);

  return (
    <ul>
      {sortedTitles.map((title) => (
        <li key={title.id} className="tw-mb-3">
          <Title
            title={title}
            mediaConfig={mediaConfig}
            mediaLength={mediaLength}
            isActive={activeTitleId === title.id}
            onClick={onClickTitle}
            onDelete={onDeleteTitle}
          />
        </li>
      ))}
    </ul>
  );
};

interface TitleProps {
  title: TitleConfig;
  isActive: boolean;
  mediaConfig: MediaConfig;
  mediaLength: number;
  onClick: (titleId: string) => void;
  onDelete: (titleId: string) => void;
}

const Title: React.FC<TitleProps> = ({ title, mediaConfig, mediaLength, isActive, onClick, ...props }) => {
  const [showDeleteTitleModal, hideDeleteTitleModal] = useModal(ModalType.DeleteTitleModal);
  const showFullTime = title.showFullTime;
  const startTime = formatTime(getTimeObject(msToSec(showFullTime ? 0 : title.start)));
  const endTime = formatTime(getTimeObject(msToSec(showFullTime ? mediaLength : title.end)));

  const titleLength = Math.abs(title.end - title.start);
  const humanLength = showFullTime
    ? "Display entire time"
    : parseSecondsToText(Math.floor(msToSec(titleLength)), true, true);

  const onDelete = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.stopPropagation();
    showDeleteTitleModal(
      <TitleDeleteModal
        mediaConfig={mediaConfig}
        title={title}
        closeModal={() => {
          hideDeleteTitleModal();
        }}
        onDeleteTitle={() => props.onDelete(title.id)}
      />
    );
  };

  return (
    <div
      className={classNames(
        "tw-m-0 tw-flex tw-cursor-pointer tw-content-center  tw-justify-between tw-rounded-md tw-border tw-border-neutral-100 tw-bg-neutral-50 tw-px-2 tw-py-[7px] hover:tw-border-neutral-200 hover:tw-bg-neutral-100",
        {
          "!tw-border-neutral-200 !tw-bg-neutral-100": isActive
        }
      )}
      onClick={() => onClick(title.id)}
    >
      <div className="tw-flex tw-flex-col tw-items-start tw-justify-start">
        <h4 className="tw-mb-1 tw-w-100 tw-truncate tw-text-left tw-text-sm tw-font-semibold tw-text-neutral-900">
          {title.text}
        </h4>
        <ul className="tw-text-xs tw-font-medium">
          <li className="tw-mb-1 tw-flex tw-items-center tw-leading-[14px]">
            <ClockIcon className="-tw-my-1 tw-mr-1.5 tw-w-5 tw-text-neutral-600" />
            {humanLength}
          </li>
          <li className="tw-mb-1 tw-flex tw-items-center tw-leading-[14px]">
            <FilmIcon className="-tw-my-1 tw-mr-1.5 tw-w-5 tw-text-neutral-600" />
            {startTime} - {endTime}
          </li>
        </ul>
      </div>
      <div
        className="tw-flex tw-h-7 tw-w-7 tw-content-center tw-justify-start !tw-text-neutral-900 hover:tw-text-neutral-50 active:tw-text-neutral-300"
        onClick={onDelete}
      >
        <ToolTip className="tw-h-max tw-w-max" text="Delete text">
          <TrashCanOutlinePauseIcon className="tw-h-4 tw-w-4" />
        </ToolTip>
      </div>
    </div>
  );
};
