import React from "react";
import classNames from "classnames";

type SectionIconProps = {
  className?: string;
  children?: React.ReactNode;
};
export const SectionIcon: React.FC<SectionIconProps> = ({ className, children }) => {
  const classes = classNames(
    "tw-mr-3 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-neutral-200",
    className
  );

  return <div className={classes}>{children}</div>;
};
