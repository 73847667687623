import React from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";

import { inviteMembers } from "@frontend/api/invitation.service";
import { RoleNameDropdown } from "@frontend/components/dropdowns/role-name-dropdown";

import { PaperPlaneIcon } from "@shared/components/icons";
import { useAccounts } from "@shared/hooks/use-accounts";
import { useAnalyticsWithAuth } from "@shared/hooks/use-analytics-with-auth";
import { Button } from "@shared/primitives/button";
import { Input } from "@shared/primitives/input";
import { Table } from "@shared/primitives/table";
import { emailPattern } from "@shared/utils/regex-patterns";
import { compareStrings } from "@shared/utils/strings";

import { RoleName } from "@getsubly/common";

import { MemberColumnId } from "./members-table";

interface InviteUserRowProps {
  hasAvailableSeats?: boolean;
}

type EmailForm = {
  email: string;
};
export const InviteUserRow: React.FC<InviteUserRowProps> = ({ hasAvailableSeats }) => {
  const [roleNameValue, setRoleNameValue] = React.useState<RoleName>(
    !hasAvailableSeats ? RoleName.Viewer : RoleName.Editor
  );
  const { user, accountTeam } = useAccounts();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm<EmailForm>({
    mode: "all"
  });
  const [isLoading, setLoading] = React.useState(false);

  const allMembers = accountTeam?.members ?? [];
  const allInvitations = accountTeam?.invitations ?? [];
  const allUsers = [...allMembers, ...allInvitations];

  const onSubmit = async ({ email }: EmailForm) => {
    setLoading(true);

    try {
      await inviteMembers([{ id: "", email: email.toLowerCase(), role: roleNameValue }]);
      trackEventWithAuth(`Settings / Send invite`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    setValue("email", "");
    setRoleNameValue(!hasAvailableSeats ? RoleName.Viewer : RoleName.Editor);
  };

  const onChangeRole = (role: RoleName) => {
    setRoleNameValue(role);
  };

  const hasEmail = Boolean(watch().email);
  const canSendInvite = !errors.email && hasEmail;

  return (
    <Table.Row className="tw-min-h-[66px]">
      <Table.Cell columnId={MemberColumnId.AVATAR} className="tw-w-[56px]" />
      <Table.Cell columnId={MemberColumnId.NAME} className="!tw-pl-0">
        <form method="GET" id="invite_user_form" onSubmit={handleSubmit(onSubmit)} />

        <Input
          className="tw-h-9 tw-rounded-md"
          placeholder="name@email.com"
          type="email"
          form="invite_user_form"
          {...register("email", {
            required: true,
            pattern: emailPattern,
            validate: {
              isOwner: (email: string) => {
                return !compareStrings(email, user?.email) || "You can't invite yourself";
              },
              hasInvited: (email: string) => {
                return !allInvitations.some((i) => compareStrings(email, i.email)) || "You already invited this user";
              },
              hasMember: (email: string) => {
                return !allUsers.some((i) => email === i.email) || "You already invited this user";
              }
            }
          })}
        />
        {Boolean(errors.email?.message) && (
          <p className="tw-mt-1 tw-text-sm tw-text-destructive-600">{errors.email?.message}</p>
        )}
      </Table.Cell>
      <Table.Cell columnId={MemberColumnId.ROLE} className="!tw-align-top">
        <RoleNameDropdown
          value={roleNameValue}
          onChange={onChangeRole}
          buttonClassName="!tw-w-[160px]"
          bordered
          showIconOnButton={false}
          canSelectAdmin={hasAvailableSeats}
          canSelectManager={hasAvailableSeats}
          canSelectEditor={hasAvailableSeats}
        />
      </Table.Cell>
      <Table.Cell columnId={MemberColumnId.ACTIVE_SINCE}></Table.Cell>
      <Table.Cell columnId={MemberColumnId.SEATS} className="tw-p-3 tw-text-right !tw-align-top">
        <Button
          disabled={!canSendInvite}
          variant="secondary"
          className={classNames("tw-ml-auto tw-h-9 tw-w-fit tw-whitespace-nowrap tw-px-2", {
            "!tw-border-neutral-400 !tw-bg-neutral-300 !tw-text-neutral-400": !canSendInvite
          })}
          type="submit"
          form="invite_user_form"
          loading={isLoading}
          size="36"
        >
          Send invite
          <PaperPlaneIcon className="tw-ml-1.5 tw-h-5 tw-w-5" />
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};
