import React, { useState } from "react";
import classNames from "classnames";

import { getCustomVocabularyWords } from "@frontend/api/vocabulary.service";
import { CustomGlossaryInput } from "@frontend/components/custom-glossary/custom-glossary";

import { useAccounts } from "@shared/hooks/use-accounts";
import { usePlan } from "@shared/hooks/use-plan";
import { uploadStore } from "@shared/state/upload";

import { RiArrowDownSLine, RiArrowUpSLine } from "@remixicon/react";

import { SrtUploader } from "./srt-uploader";

type AdvancedOptionsSectionProps = {
  className?: string;
};

export const AdvancedOptionsSection: React.FC<AdvancedOptionsSectionProps> = ({ className }) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [customWords, setCustomWords] = React.useState<string[]>([]);
  const { customVocabularyWords } = useAccounts();
  const { isViewer } = useAccounts();
  const { isBusinessOrHigher, isTrial } = usePlan();
  const showSrtUploader = isBusinessOrHigher && !isTrial;

  // retrieve global custom vocab and set as default input
  React.useEffect(() => {
    getCustomVocabularyWords();
  }, []);

  React.useEffect(() => {
    setCustomWords(customVocabularyWords?.map((word) => word.word) || []);
    uploadStore.addWordsToVocabulary(customWords);
  }, [customVocabularyWords]);

  const handleChangeCustomWords = async (customWords: string[]) => {
    setCustomWords(customWords);
    uploadStore.addWordsToVocabulary(customWords);
  };

  return (
    <div className={className}>
      {showOptions && (
        <div className="tw-my-4 tw-flex tw-flex-col tw-gap-5">
          {showSrtUploader && <SrtUploader />}
          <CustomGlossaryInput
            className="tw-mb-0"
            value={customWords}
            onChange={handleChangeCustomWords}
            disabled={isViewer}
            variant="outline"
          />
        </div>
      )}
      <a
        className={classNames(
          "tw-flex tw-w-fit tw-text-sm tw-font-medium tw-text-primary-500 hover:tw-text-primary-600",
          { "tw-mb-2": showOptions }
        )}
        onClick={(e) => {
          e.preventDefault();
          setShowOptions(!showOptions);
        }}
        href="#"
      >
        {showOptions ? (
          <RiArrowUpSLine className="tw-h-5 tw-w-5 tw-min-w-0" />
        ) : (
          <RiArrowDownSLine className="tw-h-5 tw-w-5 tw-min-w-0" />
        )}
        {showOptions ? "Show less options" : "Advanced options"}
      </a>
    </div>
  );
};
