import { BatchType, IBatchFile } from "@frontend/api/media.service";

import { ActiveState, EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface BatchState {
  mediaId: string;
  batchId: string;
  files: QueueFile[];
  visible?: boolean;
  notifyUserId?: string;
}

export enum QueueFileStatus {
  Complete = "",
  Processing = "Processing...",
  Downloading = "Downloading...",
  Cancelling = "Cancelling...",
  Cancelled = "Cancelled."
}

export interface QueueFile {
  id?: string;
  status: QueueFileStatus;
  type: BatchType;
  name: string;
  progress: number;
  hasDownloaded: boolean;
  isProcessing: boolean;
  batchFile: IBatchFile;
  outputFileId?: string;
}

interface AddFiles {
  mediaId: string;
  batchId: string;
  files?: QueueFile[];
}

export interface DownloadQueueState extends EntityState<BatchState, string>, ActiveState {}

@StoreConfig({ name: "download-queue", idKey: "mediaId", resettable: true })
class DownloadQueueStore extends EntityStore<DownloadQueueState> {
  constructor() {
    super({});
  }

  addFiles({ mediaId, batchId, files = [] }: AddFiles) {
    if (!files.length) {
      return;
    }

    this.upsert(mediaId, (s) => {
      const queueFiles = (s as BatchState)?.files ?? [];

      // Add files to the queue
      const updatedFiles = [...queueFiles, ...files];

      return { files: updatedFiles, batchId, visible: true };
    });
  }

  updateQueueJob(mediaId: string, jobId: string, updates: Partial<QueueFile> = {}) {
    this.update(mediaId, (mediaState) => {
      if (!mediaState?.files?.length) {
        return mediaState;
      }

      const files = mediaState.files.map((f) => {
        if (f.id === jobId) {
          const progress = updates.progress ?? 0;
          const isProcessing = f.isProcessing || (progress ?? 0) > 0;

          return { ...f, isProcessing, ...updates };
        }

        return f;
      });

      const hasProcessingFiles = files.some((f) => !f.hasDownloaded);

      if (hasProcessingFiles) {
        return { files };
      }

      // Close pop up when all files have been downloaded and reset batchId and reset files
      return { files: [], visible: false, batchId: "" };
    });
  }

  completeQueueJob(mediaId: string, jobId: string) {
    this.updateQueueJob(mediaId, jobId, {
      progress: 100,
      status: QueueFileStatus.Complete,
      hasDownloaded: true,
      isProcessing: false
    });
  }

  updateDownloadNotifyUser(mediaId: string, notifyUserId: string) {
    this.update(mediaId, { notifyUserId });
  }

  setVisible(mediaId: string, visible: boolean) {
    this.update(mediaId, () => {
      return { visible };
    });
  }
}

export const downloadQueueStore = new DownloadQueueStore();
