import React from "react";
import classNames from "classnames";

type SectionContentProps = {
  className?: string;
  children?: React.ReactNode;
};
export const SectionContent: React.FC<SectionContentProps> = ({ className, children }) => {
  return <div className={classNames("tw-flex-1", className)}>{children}</div>;
};
