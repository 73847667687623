import React from "react";
import classNames from "classnames";

import { LetterCaseControl, MaxLinesControl } from "@shared/components/controls";
import { MaxCharsPerLineControl } from "@shared/components/controls/max-chars-per-line-control";
import { MaxCharsPerSecondControl } from "@shared/components/controls/max-chars-per-second-control";
import { MaxDurationControl } from "@shared/components/controls/max-duration-control";
import { MaxWordsPerLineControl } from "@shared/components/controls/max-words-per-line-control";
import { GuidelinePresetGalleryPicker } from "@shared/components/guideline-preset-gallery-picker";
import { InformationCircleIcon } from "@shared/components/icons";
import { useModal } from "@shared/hooks/use-modal";
import { ModalType } from "@shared/interfaces/modal-type";
import { Button } from "@shared/primitives/button";
import { NewFieldset } from "@shared/primitives/new-fieldset";
import { NewModal } from "@shared/primitives/new-modal";
import { Toggle } from "@shared/primitives/toggle";
import { ToolTip } from "@shared/primitives/tooltip";
import { msToSec, secToMs } from "@shared/utils/time";

import { getGuidelinePresetName, GuidelinePreset, GuidelineRules } from "@getsubly/common";
import { RiArrowRightLine, RiFileTextLine } from "@remixicon/react";

import { SectionContent } from "./ui/section-content";
import { SectionIcon } from "./ui/section-icon";

type DefaultGuidelinesProps = {
  className?: string;
  defaultGuidelinePreset: GuidelinePreset;
  onSave: (updatedGuidelinePreset: GuidelinePreset) => Promise<void>;
};
export const DefaultGuidelines: React.FC<DefaultGuidelinesProps> = (props) => {
  const [showModal, hideModal] = useModal(ModalType.DefaultGuidelinePresetModal);

  const handleSave = async (updatedDefaultGuidelinePreset: GuidelinePreset) => {
    await props.onSave(updatedDefaultGuidelinePreset);
    hideModal();
  };

  const openModal = () => {
    showModal(
      <DefaultGuidelineModal
        closeModal={hideModal}
        defaultGuidelinePreset={props.defaultGuidelinePreset}
        onSave={handleSave}
      />
    );
  };

  return (
    <Button
      variant="ghost"
      className={classNames("tw-flex !tw-h-min tw-items-center !tw-gap-0 !tw-p-2", props.className)}
      onClick={openModal}
    >
      <SectionIcon className="tw-h-10 tw-w-10">
        <RiFileTextLine className="tw-h-5 tw-w-5 tw-text-neutral-500" />
      </SectionIcon>
      <SectionContent className="tw-text-left">
        <h3 className="tw-font-medium tw-text-neutral-900">Guidelines</h3>
        <p className="tw-text-sm tw-text-neutral-500">{props.defaultGuidelinePreset.name}</p>
      </SectionContent>
      <div className="tw-pointer-events-none tw-inline-flex tw-p-2">
        <RiArrowRightLine className="tw-h-4 tw-w-4" />
      </div>
    </Button>
  );
};

interface DefaultGuidelineModalProps {
  closeModal: () => void;
  defaultGuidelinePreset: GuidelinePreset;
  onSave: (defaultGuidelinePreset: GuidelinePreset) => Promise<void>;
}

export const DefaultGuidelineModal: React.FC<DefaultGuidelineModalProps> = (props) => {
  const [isSaving, setSaving] = React.useState<boolean>(false);
  const [currentGuidelinePreset, setCurrentGuidelinePreset] = React.useState<GuidelinePreset>(
    props.defaultGuidelinePreset
  );

  const handleSubmit = async () => {
    setSaving(true);
    await props.onSave(currentGuidelinePreset);
    setSaving(false);
  };

  const updateValue = <Key extends keyof GuidelineRules, Value extends GuidelineRules[Key]>(key: Key, value: Value) => {
    const newGuidelineRules = {
      ...currentGuidelinePreset?.rules,
      [key]: value
    };

    const newGuidelinePreset: GuidelinePreset = {
      name: currentGuidelinePreset?.name ?? "Custom",
      rules: newGuidelineRules
    };

    newGuidelinePreset.name = getGuidelinePresetName(newGuidelinePreset);

    setCurrentGuidelinePreset(newGuidelinePreset);
  };

  const currentPreset = currentGuidelinePreset?.name ?? "Custom";
  const isCustomPreset = currentPreset === "Custom";

  return (
    <NewModal
      title="Default Guidelines"
      description="Adjust how subtitles will look on the video"
      showCloseButton
      tertiaryAction="Cancel"
      onClickTertiary={props.closeModal}
      primaryActionLoading={isSaving}
      primaryAction="Save"
      onClickPrimary={handleSubmit}
      onDismiss={props.closeModal}
      size="930"
    >
      <div className="tw-mb-8 tw-mt-2 tw-flex tw-w-full tw-flex-col tw-gap-4 tw-overflow-auto">
        <div className="tw-flex tw-flex-col tw-gap-1">
          <NewFieldset
            title="Subtitle guidelines"
            tooltipText="Pick preset templates to \nautomatically set guideline subtitles,\nor choose custom to customize."
          >
            <GuidelinePresetGalleryPicker
              value={currentPreset}
              isCustom={isCustomPreset}
              onSelect={setCurrentGuidelinePreset}
            />
            <p className="tw-text-sm tw-text-neutral-500">
              Pick a template to automatically format subtitles or choose custom.
            </p>
          </NewFieldset>
        </div>
        <div className="tw-col-1 tw-grid tw-gap-1">
          <NewFieldset title="Maximum lines" tooltipText="Set maximum subtitle lines.">
            <MaxLinesControl
              value={currentGuidelinePreset?.rules.maxLines ?? 0}
              onChange={(value) => updateValue("maxLines", value)}
            />
          </NewFieldset>
        </div>

        <div className="tw-grid tw-grid-cols-2 tw-gap-x-6">
          <NewFieldset
            title="Max. chars per line"
            tooltipText="Set the maximum characters allowed per line.\nRecommended length is between\n40-50 characters per line."
          >
            <MaxCharsPerLineControl
              value={currentGuidelinePreset?.rules.maxCharsLine ?? 0}
              onChange={(maxCharsLine) => updateValue("maxCharsLine", maxCharsLine)}
            />
            <p className="tw-text-sm tw-text-neutral-500">Recommended length is 40-50</p>
          </NewFieldset>
          <NewFieldset title="Max. words per line" tooltipText="Set the maximum words per line.">
            <MaxWordsPerLineControl
              value={currentGuidelinePreset?.rules.maxLineWords ?? 0}
              onChange={(maxLineWords) => updateValue("maxLineWords", maxLineWords)}
            />
          </NewFieldset>
        </div>

        <div className="tw-grid tw-grid-cols-2 tw-gap-x-6">
          <NewFieldset title="Max. chars per second" tooltipText="Set the maximum characters allowed per second.">
            <MaxCharsPerSecondControl
              value={currentGuidelinePreset?.rules.maxCharsSec ?? 0}
              onChange={(maxCharsSec) => updateValue("maxCharsSec", maxCharsSec)}
            />
          </NewFieldset>
          <NewFieldset title="Max. duration (seconds)" tooltipText="Maximum duration of a cue per second.">
            <MaxDurationControl
              value={msToSec(currentGuidelinePreset?.rules.maxCueDuration) ?? 0}
              onChange={(maxCueDuration) => updateValue("maxCueDuration", secToMs(maxCueDuration))}
            />
          </NewFieldset>
        </div>

        <NewFieldset title="Transform text" tooltipText="Change the case of subtitles.">
          <LetterCaseControl
            value={currentGuidelinePreset?.rules.transformLetterCase}
            onChange={(value) => updateValue("transformLetterCase", value)}
          />
        </NewFieldset>

        <div className="tw-flex tw-items-center">
          <Toggle
            label="Create a new line after punctuation"
            checked={Boolean(currentGuidelinePreset?.rules.newLineAfterPunctuation)}
            onChange={(e) => updateValue("newLineAfterPunctuation", e.target.checked)}
          />

          <div className="tw-flex tw-items-center">
            <ToolTip
              text="Add line breaks after period(.), exclamation mark(!), question mark(?), ellipsis(...)"
              place="top"
            >
              <InformationCircleIcon
                className="tw-ml-2 tw-cursor-pointer tw-stroke-neutral-500"
                width={16}
                height={16}
              />
            </ToolTip>
          </div>
        </div>
      </div>
    </NewModal>
  );
};
