import React from "react";
import classNames from "classnames";

import { PlusIcon, TrashIcon } from "@shared/components/icons";
import { Asset } from "@shared/interfaces/asset";
import { IconButton } from "@shared/primitives/button";
import { FileInput } from "@shared/primitives/file-input";
import { Loader } from "@shared/primitives/loader";

import { RiProhibitedLine } from "@remixicon/react";

interface LogoIconControlProps {
  className?: string;
  assets: Asset[];
  selectedId?: string;
  isAdding?: boolean;
  setSelectedId: (id?: string) => void;
  onAdd: (file: File) => void;
  onRemove: (id: string) => void;
  onImageError?: (id: string) => void;
}

export const LogoIconControl: React.FC<LogoIconControlProps> = ({
  className,
  assets,
  selectedId,
  isAdding,
  setSelectedId,
  onAdd,
  onRemove,
  onImageError
}) => {
  return (
    <div className={classNames("tw-flex tw-flex-row tw-gap-2 tw-overflow-x-auto tw-py-1", className)}>
      {Boolean(onAdd) && (
        <FileInput accept="image/png" onChange={onAdd}>
          <div className="tw-flex tw-h-[90px] tw-w-[90px] tw-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-transparent tw-bg-neutral-100 hover:tw-bg-neutral-50">
            {isAdding ? <Loader className="tw-h-5 tw-w-5" /> : <PlusIcon className="tw-h-5 tw-w-5" />}
          </div>
        </FileInput>
      )}
      <div
        className={classNames(
          "tw-flex tw-h-[90px] tw-w-[90px] tw-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-neutral-200",
          { "!tw-border-primary-500 !tw-ring-[3px]": !selectedId }
        )}
        onClick={() => setSelectedId(undefined)}
      >
        <RiProhibitedLine className="tw-w-14 tw-text-neutral-300" />
      </div>
      {assets.map((asset) => (
        <div
          key={asset.id}
          className={classNames(
            "tw-group tw-relative tw-flex tw-h-[90px] tw-w-[90px] tw-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-border tw-transition-all",
            {
              "tw-border-primary-500 tw-ring-[3px]": selectedId === asset.id,
              "tw-border-neutral-200": selectedId !== asset.id
            }
          )}
          onClick={() => setSelectedId(asset.id)}
        >
          <img
            src={asset.publicUrl || asset.s3Uri}
            alt={asset.name}
            loading="lazy"
            className={classNames("tw-h-full tw-w-full tw-rounded-lg tw-object-cover tw-transition-all", {
              "tw-opacity-50 hover:tw-opacity-75": selectedId !== asset.id,
              "tw-opacity-100": selectedId === asset.id
            })}
            onError={onImageError ? () => onImageError(asset.id) : undefined}
          />
          <IconButton
            variant="destructive"
            size="24"
            onClick={() => onRemove(asset.id)}
            className="tw-absolute tw-right-1 tw-top-1 !tw-hidden group-hover:!tw-flex"
            icon={<TrashIcon color="white" width={16} height={16} />}
          />
        </div>
      ))}
    </div>
  );
};
