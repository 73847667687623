import React from "react";
import classNames from "classnames";

import { SelectedFilesProvider, useSelectedFiles } from "@frontend/contexts/selected-files.context";

import { useDashboard } from "@shared/hooks/use-dashboard";
import { useIntersection } from "@shared/hooks/use-intersection";
import { useModal } from "@shared/hooks/use-modal";
import { useQuery } from "@shared/hooks/use-query";
import { EnrichedMediaListItem } from "@shared/interfaces/media";
import { ModalType } from "@shared/interfaces/modal-type";
import { Loader } from "@shared/primitives/loader";
import { Folder } from "@shared/state/dashboard/dashboard.store";

import { AccessibilityModal } from "../../modals/accessibility-modal";
import { DashboardFileActionToolBar } from "../file-action-toolbar/file-action-toolbar";

import { FoldersGrid } from "./folders-grid";
import { MediaGrid } from "./media-grid";
import { MediaTable } from "./media-table";

type MediaViewProps = {
  mediaList: EnrichedMediaListItem[];
  folders: Folder[];
  onLoadMoreMedia: () => void;
};

export const MediaView: React.FC<MediaViewProps> = ({ mediaList, folders = [], onLoadMoreMedia }) => {
  return (
    <SelectedFilesProvider>
      <MediaViewInner mediaList={mediaList} folders={folders} onLoadMoreMedia={onLoadMoreMedia} />
    </SelectedFilesProvider>
  );
};

export const MediaViewInner: React.FC<MediaViewProps> = ({ mediaList, folders = [], onLoadMoreMedia }) => {
  const [loadMoreRef, setLoadMoreRef] = React.useState<HTMLDivElement | null>(null);

  const { media, totalMediaCount, isLoadingFolderMedia } = useDashboard();
  const { queryParams } = useQuery();

  useIntersection(loadMoreRef, "100%", () => onLoadMoreMedia());

  const showLoadMore = Boolean(totalMediaCount > media.length);

  const { selectedFiles, setSelectedFiles } = useSelectedFiles();
  const [showModal, hideModal] = useModal(ModalType.MakeAccessible);

  const handleDeselectAllTheFiles = () => {
    setSelectedFiles([]);
  };

  const handleSelectAll = () => {
    setSelectedFiles(mediaList.map((media) => media.mediaId));
  };

  const handleMakeAccessible = () => {
    const selectedMediaList = mediaList.filter((media) => selectedFiles.includes(media.mediaId));

    const handleComplete = () => {
      hideModal();
      setSelectedFiles([]);
    };

    showModal(<AccessibilityModal mediaList={selectedMediaList} onDismiss={hideModal} onComplete={handleComplete} />);
  };

  const hasItems = mediaList?.length > 0 || folders?.length > 0;

  const viewType = queryParams.viewType ?? "list";

  return (
    <>
      <div className={classNames("tw-max-w-full")}>
        {hasItems && folders?.length > 0 && <FoldersGrid className="tw-mb-4" folders={folders} />}
        {hasItems && viewType === "grid" && <MediaGrid mediaList={mediaList} />}
        {hasItems && viewType === "list" && <MediaTable mediaList={mediaList} />}
        {!hasItems && <div className="tw-text-sm tw-italic">No results</div>}
      </div>
      {showLoadMore && (
        <div ref={setLoadMoreRef} className="tw-flex tw-w-full tw-justify-center">
          {isLoadingFolderMedia}
          <Loader className="tw-mt-4" />
        </div>
      )}
      <DashboardFileActionToolBar
        selectedFiles={selectedFiles}
        handleDeselectAllTheFiles={() => handleDeselectAllTheFiles()}
        onSelectAll={handleSelectAll}
        onMakeAccessible={handleMakeAccessible}
      />
    </>
  );
};
