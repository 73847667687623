import React from "react";
import classNames from "classnames";
import { uniqueId } from "lodash-es";

import { deleteAsset, uploadAsset } from "@frontend/api/assets.service";

import { ColorChipInput } from "@shared/components/color-chip-input/color-chip-input";
import { ArrowUpTrayIcon, CloseIcon } from "@shared/components/icons";
import { useAssets } from "@shared/hooks/use-assets";
import { AssetType } from "@shared/interfaces/asset";
import { FileButton } from "@shared/primitives/file-input";
import { Loader } from "@shared/primitives/loader";
import { Radio } from "@shared/primitives/radio";
import { SegmentedControl } from "@shared/primitives/segmented-control";
import { useActiveMediaConfigState } from "@shared/state/editor/editor.hooks";
import { DEFAULT_BACKGROUND_IMAGE } from "@shared/utils/presets";

import { AspectFit, AspectRatioConfig, BackgroundImageConfig } from "@getsubly/common";

interface TextBackgroundControlProps {
  value: AspectRatioConfig;
  onUpdate: (value: Partial<AspectRatioConfig>) => void;
  aspectRatioColors: string[];
  addColor: (type: "aspectRatioColors", color: string) => void;
  removeColor: (type: "aspectRatioColors", color: string) => void;
}

export const TextBackgroundControl: React.FC<TextBackgroundControlProps> = ({
  value,
  onUpdate,
  aspectRatioColors,
  addColor,
  removeColor
}) => {
  const aspectFit = value?.fit ?? AspectFit.Border;

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <FitStyles value={aspectFit} onUpdate={(fit) => onUpdate({ fit })} />
      {aspectFit === AspectFit.Border && (
        <BackgroundOptionStyles
          value={value}
          onUpdate={onUpdate}
          aspectRatioColors={aspectRatioColors}
          addColor={addColor}
          removeColor={removeColor}
        />
      )}
    </div>
  );
};

interface FitStylesProps {
  value: AspectFit;
  onUpdate: (value: AspectFit) => void;
}

export const FitStyles: React.FC<FitStylesProps> = ({ value, onUpdate }) => {
  const options = [
    { value: AspectFit.Crop, text: "Fit and crop" },
    { value: AspectFit.Border, text: "Fit to border" }
  ];

  return (
    <SegmentedControl>
      {options.map((option) => {
        return (
          <SegmentedControl.Option
            key={option.value}
            selected={option.value === value}
            onClick={() => onUpdate(option.value)}
          >
            {option.text}
          </SegmentedControl.Option>
        );
      })}
    </SegmentedControl>
  );
};

export const BackgroundOptionStyles: React.FC<TextBackgroundControlProps> = ({
  value,
  onUpdate,
  aspectRatioColors,
  addColor,
  removeColor
}) => {
  if (!value) {
    return null;
  }

  const backgroundImageConfig: BackgroundImageConfig = {
    ...DEFAULT_BACKGROUND_IMAGE,
    ...value?.backgroundImage
  };

  const disabled = value.fit !== AspectFit.Border;

  const isBackgroundImage = !disabled && value?.backgroundImage?.visible === true;
  const isBackgroundFill = !disabled && !isBackgroundImage;

  const handleSelectBackgroundType = (backgroundType: "COLOUR" | "IMAGE") => {
    const backgroundImage = {
      ...backgroundImageConfig,
      visible: backgroundType === "IMAGE" ? true : false
    };
    onUpdate({ backgroundImage });
  };

  const handleImageChange = (backgroundImage?: BackgroundImageConfig) => {
    onUpdate({
      backgroundImage: backgroundImage ? { ...value.backgroundImage, ...backgroundImage } : undefined
    });
  };

  return (
    <div className="tw-flex tw-flex-col tw-divide-y tw-divide-neutral-200 tw-rounded-md tw-border tw-border-neutral-200">
      <div className="tw-group tw-relative tw-flex tw-flex-row tw-justify-between hover:tw-bg-neutral-50">
        <Radio
          name={`Background${uniqueId()}`}
          id={`Background.${uniqueId()}`}
          checked={isBackgroundFill}
          onChange={() => handleSelectBackgroundType("COLOUR")}
          disabled={disabled}
          className={classNames(
            "tw-h-[56px] tw-w-full tw-cursor-pointer tw-select-none tw-p-3",
            !isBackgroundFill && "tw-opacity-70 group-hover:tw-opacity-80"
          )}
        >
          <p className="tw-pointer tw-text-sm tw-font-medium ">Background</p>
        </Radio>
        <div
          className={classNames(
            "tw-absolute tw-inset-y-2 tw-right-1 tw-top-1/2 tw-z-10 tw-flex tw--translate-y-1/2 tw-transform tw-flex-row tw-items-center tw-gap-2",
            disabled && "tw-pointer-events-none"
          )}
        >
          <p className="tw-text-sm tw-text-neutral-600">{value.color.slice(0, 7)}</p>
          <ColorChipInput
            color={value.color}
            onChange={(color) => onUpdate({ color })}
            onClick={() => {
              handleSelectBackgroundType("COLOUR");
            }}
            showAlpha
            eyeDropperIcon
            showCustomColors
            customColors={aspectRatioColors}
            addCustomColor={(color) => addColor("aspectRatioColors", color)}
            deleteCustomColor={(color) => removeColor("aspectRatioColors", color)}
            className="!tw-p-1"
          />
        </div>
      </div>
      <div className="tw-group tw-relative tw-flex tw-flex-row tw-justify-between hover:tw-bg-neutral-50">
        <Radio
          name={`Background${uniqueId()}`}
          id={`Background.${uniqueId()}`}
          checked={isBackgroundImage}
          onChange={() => handleSelectBackgroundType("IMAGE")}
          disabled={disabled}
          className={classNames(
            "tw-h-[56px] tw-w-full tw-cursor-pointer tw-select-none tw-p-3",
            !isBackgroundImage && "tw-opacity-70 group-hover:tw-opacity-80"
          )}
        >
          <p className="tw-cursor-pointer tw-text-sm tw-font-medium">Image</p>
        </Radio>

        <div
          className={classNames(
            "tw-absolute tw-right-2 tw-top-1/2 tw-flex tw-h-7 tw--translate-y-1/2 tw-transform tw-items-center group-hover:tw-opacity-80",
            disabled && "tw-pointer-events-none"
          )}
        >
          <BackgroundImage
            onChange={handleImageChange}
            disabled={disabled}
            onClick={() => handleSelectBackgroundType("IMAGE")}
          />
        </div>
      </div>
    </div>
  );
};

interface BackgroundImageProps {
  onChange: (config?: BackgroundImageConfig) => void;
  onClick?: (event: React.MouseEvent) => void;
  disabled: boolean;
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({ onChange, onClick, disabled }) => {
  const [loading, setLoading] = React.useState(false);
  const { findAssetById, addAsset } = useAssets();
  const mediaConfig = useActiveMediaConfigState();

  if (!mediaConfig) return null;

  const handleUpload = async (file: File) => {
    try {
      setLoading(true);
      const asset = await uploadAsset({
        file,
        assetType: AssetType.BACKGROUND
      });

      addAsset(asset);

      const backgroundImage: BackgroundImageConfig = {
        visible: true,
        id: asset.id,
        url: asset.publicUrl || asset.s3Uri,
        dimensions: {
          width: asset.width,
          height: asset.height
        }
      };

      onChange(backgroundImage);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    const activeBackgroundImage = mediaConfig.aspectRatio?.backgroundImage;

    if (activeBackgroundImage) {
      try {
        setLoading(true);

        await deleteAsset({ assetId: activeBackgroundImage.id });

        onChange(undefined);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <Loader className="tw-mx-1 tw-h-5 tw-w-5" />;
  }

  const activeBackgroundImage = mediaConfig.aspectRatio?.backgroundImage;
  const backgroundImageUrl = activeBackgroundImage ? findAssetById(activeBackgroundImage.id)?.publicUrl : null;

  if (backgroundImageUrl) {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
        <img className="tw-h-6 tw-w-10 tw-object-contain" src={backgroundImageUrl} />
        <CloseIcon className="tw-h-5 tw-w-5 tw-cursor-pointer" onClick={handleDelete} />
      </div>
    );
  }

  return (
    <FileButton
      disabled={disabled}
      className={classNames(
        "tw-gap-1 tw-rounded-md tw-border tw-border-neutral-200 !tw-px-2 !tw-py-1 tw-text-neutral-700 hover:tw-text-neutral-800"
      )}
      accept="image/png,image/jpg,image/jpeg"
      onChange={handleUpload}
      loading={loading}
      onClick={onClick}
      icon={<ArrowUpTrayIcon className="tw-h-5 tw-w-5" />}
    >
      <p className="tw-text-sm tw-font-medium">Upload</p>
    </FileButton>
  );
};
