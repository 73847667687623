import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { getAccountTeam } from "@frontend/api/account.service";
import { LegacyPlanAlert } from "@frontend/components/alerts/legacy-plan-alert";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import {
  ExclamationTriangleIcon,
  UserPlusIcon as UserPlusOutlineIcon,
  UsersSolidIcon as UsersIcon
} from "@shared/components/icons";
import { LockFeature } from "@shared/components/lock-feature/lock-feature";
import { useAccountMembers } from "@shared/hooks/use-account-members";
import { useAccounts } from "@shared/hooks/use-accounts";
import { useAnalyticsWithAuth } from "@shared/hooks/use-analytics-with-auth";
import { useModal } from "@shared/hooks/use-modal";
import { usePlan } from "@shared/hooks/use-plan";
import { AccountMember } from "@shared/interfaces/account";
import { SublyPlan } from "@shared/interfaces/billing";
import { ModalType } from "@shared/interfaces/modal-type";
import { Button } from "@shared/primitives/button";
import { ToolTip } from "@shared/primitives/tooltip";
import { pluralize } from "@shared/utils/strings";

import { CancelSeatRemovalModal } from "./cancel-seat-removal-modal";
import { MemberRequests } from "./member-requests";
import { MembersTable } from "./members-table";

import styles from "./manage-members.module.scss";

export const ManageMembers: React.FC = () => {
  const { isLoaded, currentAccount, accountTeam, isAdmin } = useAccounts();
  const { totalFilledSeats, totalPossibleSeats, availableSeats, seatsToBeRemoved } = useAccountMembers();
  const { planVersion } = usePlan();
  const hasInitialised = Boolean(isLoaded && accountTeam?.loaded);
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  React.useEffect(() => {
    getAccountTeam();
  }, []);

  // sort owner first
  const sortedMembers = React.useMemo(() => {
    const members = accountTeam?.members ?? [];
    const ownerFirst = (member: AccountMember) => {
      return member.id === currentAccount?.owner ? -1 : 1;
    };
    return [...members].sort(ownerFirst);
  }, [accountTeam?.members, currentAccount?.owner]);

  const [editorRequests, setEditorRequests] = React.useState<AccountMember[]>([]);
  const [loadedEditorRequests, setLoadedEditorRequests] = React.useState<boolean>(false);

  React.useEffect(() => {
    const hasLoadedAccount = Boolean(currentAccount);
    const hasLoadedTeam = Boolean(accountTeam?.loaded);
    const hasLoadedEditorRequests = loadedEditorRequests;

    if (hasLoadedAccount && hasLoadedTeam && !hasLoadedEditorRequests) {
      const hasRequestedRole = (member: AccountMember) => {
        return Boolean(member.requestedRole);
      };
      setEditorRequests(sortedMembers.filter(hasRequestedRole));
      setLoadedEditorRequests(true);
    }
  }, [currentAccount, accountTeam?.loaded]);

  if (!currentAccount) {
    return null;
  }

  if (!hasInitialised) {
    return (
      <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-2">
        <div className="tw-flex tw-flex-col tw-justify-between tw-text-h3">
          <Skeleton width="400px" />
        </div>
        <MembersTable isLoading />
      </div>
    );
  }

  const hasEditorRequests = editorRequests.length > 0;
  const showEditorRequests = isAdmin && hasEditorRequests && hasInitialised;

  return (
    <LockFeature minPermission={planVersion === "2024-01-02" ? SublyPlan.Business : undefined}>
      <div className="tw-mb-2 tw-flex tw-flex-col tw-gap-3">
        <LegacyPlanAlert className="tw-my-2">
          You are currently subscribed to a legacy plan. Visit <Link to={SETTINGS_BILLING_PATH}>Account page</Link> to
          choose a new subscription, or contact Support for advice and help.
        </LegacyPlanAlert>

        <div className={styles.seatsInfo}>
          <div className="tw-flex tw-flex-col">
            <SeatsUsed usedSeats={totalFilledSeats} totalSeats={totalPossibleSeats} />
            {Boolean(seatsToBeRemoved && isAdmin) && <SeatsWillBeRemoved />}
          </div>

          {Boolean(isAdmin && planVersion === "2023-06-01") && (
            <div className="tw-flex tw-gap-2">
              <Button
                variant="primary"
                type="link"
                to={{
                  pathname: SETTINGS_BILLING_PATH,
                  search: "checkout=true"
                }}
                className="tw-mt-2 sm:tw-mt-0"
                size="36"
              >
                <UserPlusOutlineIcon className="hover: tw-mr-1.5 tw-h-5 tw-w-5" />
                Add / remove seats
              </Button>
            </div>
          )}
          {Boolean(isAdmin && planVersion !== "2023-06-01") && (
            <div className="tw-flex tw-gap-2">
              <Button
                variant="primary"
                type="link"
                to={{
                  pathname: SETTINGS_BILLING_PATH,
                  search: "checkout=true"
                }}
                onClick={() => {
                  trackEventWithAuth(`Settings / Add seats`);
                }}
                className="tw-mt-2 sm:tw-mt-0"
                size="32"
                icon={<UserPlusOutlineIcon className="!tw-h-5 !tw-w-5" />}
              >
                Add seats
              </Button>
            </div>
          )}
        </div>
      </div>
      {showEditorRequests && (
        <MemberRequests
          members={editorRequests}
          onChangeMembers={setEditorRequests}
          hasAvailableSeats={availableSeats > 0}
          className="tw-my-4"
        />
      )}
      <MembersTable isLoading={!hasInitialised} members={sortedMembers} invitations={accountTeam?.invitations || []} />
    </LockFeature>
  );
};

interface SeatsUsedProps {
  usedSeats: number;
  totalSeats: number;
}
const SeatsUsed: React.FC<SeatsUsedProps> = ({ usedSeats, totalSeats }) => {
  return (
    <div className="tw-inline-flex tw-w-fit tw-rounded-md tw-bg-[#0B8453] tw-py-1 tw-pl-2 tw-pr-3 tw-text-sm tw-font-semibold tw-text-white">
      <UsersIcon className="tw-mr-1.5 tw-h-5 tw-w-5" />
      Using {usedSeats} of {totalSeats} {pluralize(totalSeats, "seat")}
    </div>
  );
};

const SeatsWillBeRemoved: React.FC = () => {
  const { subscription } = useAccounts();
  const { seatsToBeRemoved } = useAccountMembers();

  const [showCancelSeatRemovalModal, hideCancelSeatRemovalModal] = useModal(ModalType.CancelSeatRemovalModal);

  if (!subscription || !seatsToBeRemoved) {
    return null;
  }

  const onClickCancelSeatRemoval = () => {
    showCancelSeatRemovalModal(<CancelSeatRemovalModal onDismiss={hideCancelSeatRemovalModal} />);
  };

  const seatsLabel = pluralize(seatsToBeRemoved, "seat");

  return (
    <ToolTip text="Click to cancel removing of seats" tipClassName={styles.toolTip}>
      <Button
        variant="ghost"
        className="tw-mt-1 tw-inline-flex tw-w-fit tw-rounded-md tw-border-[1px] tw-border-solid tw-border-warning-100 tw-bg-warning-50 tw-py-1 tw-pl-2 tw-pr-3 hover:tw-bg-warning-100"
        onClick={onClickCancelSeatRemoval}
        size="36"
      >
        <ExclamationTriangleIcon className="tw-relative tw-mr-1 tw-w-4 tw-text-warning-500" strokeWidth="1.6" />
        <p className="tw-text-sm tw-font-semibold tw-text-warning-800">
          {seatsToBeRemoved} {seatsLabel} removed - click to add {seatsLabel} back
        </p>
      </Button>
    </ToolTip>
  );
};
