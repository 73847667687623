import React from "react";
import classNames from "classnames";

import { deleteAsset, uploadAsset } from "@frontend/api/assets.service";

import { LogoAppearanceControl } from "@shared/components/controls/logo-appearance-control";
import { LogoIconControl } from "@shared/components/controls/logo-icon-control";
import { LogoPositionControl } from "@shared/components/controls/logo-position-control";
import { LockFeature } from "@shared/components/lock-feature/lock-feature";
import { useAssets } from "@shared/hooks/use-assets";
import { useModal } from "@shared/hooks/use-modal";
import { Asset, AssetType } from "@shared/interfaces/asset";
import { SublyPlan } from "@shared/interfaces/billing";
import { ModalType } from "@shared/interfaces/modal-type";
import { Avatar } from "@shared/primitives/avatar/avatar";
import { Button } from "@shared/primitives/button";
import { NewModal } from "@shared/primitives/new-modal";
import { useAssetsUploadingState } from "@shared/state/assets/assets.hooks";
import { assetsStore } from "@shared/state/assets/assets.store";

import { BurnPosition, CustomLogoConfig, CustomLogoSize, MediaConfig } from "@getsubly/common";
import { RiArrowRightLine, RiImageFill } from "@remixicon/react";

import { Preview } from "./ui/Preview";
import { SectionContent } from "./ui/section-content";
import { SectionIcon } from "./ui/section-icon";

type DefaultLogoProps = {
  className?: string;
  defaultMediaConfig: MediaConfig;
  onSave: (mediaConfig?: MediaConfig) => void;
};
export const DefaultLogo: React.FC<DefaultLogoProps> = (props) => {
  const [showModal, hideModal] = useModal(ModalType.DefaultLogoModal);

  const handleSave = async (mediaConfig?: MediaConfig) => {
    await props.onSave(mediaConfig);
    hideModal();
  };

  const openModal = () => {
    showModal(
      <DefaultLogoModal closeModal={hideModal} defaultMediaConfig={props.defaultMediaConfig} onSave={handleSave} />
    );
  };

  const { logoAssets } = useAssets();

  const logoUrl = React.useMemo(() => {
    return logoAssets.find((logoAsset) => logoAsset.id === props.defaultMediaConfig.customLogo?.id)?.publicUrl;
  }, [logoAssets, props.defaultMediaConfig.customLogo]);

  return (
    <Button
      variant="ghost"
      className={classNames("tw-flex !tw-h-min tw-items-center !tw-gap-0 !tw-p-2", props.className)}
      onClick={openModal}
    >
      <SectionIcon className="tw-h-10 tw-w-10">
        {logoUrl ? (
          <Avatar image={logoUrl} size="20" withBorder={false} />
        ) : (
          <RiImageFill className="tw-h-5 tw-w-5 tw-text-neutral-500" />
        )}
      </SectionIcon>
      <SectionContent className="tw-text-left">
        <h3 className="tw-font-medium tw-text-neutral-900">Logo</h3>
        {logoUrl ? (
          <p className="tw-text-sm tw-text-success-600">Set</p>
        ) : (
          <p className="tw-text-sm tw-text-neutral-500">Not set</p>
        )}
      </SectionContent>
      <div className="tw-pointer-events-none tw-inline-flex tw-p-2">
        <RiArrowRightLine className="tw-h-4 tw-w-4" />
      </div>
    </Button>
  );
};

interface DefaultLogoModalProps {
  closeModal: () => void;
  onSave: (mediaConfig?: MediaConfig) => void;
  defaultMediaConfig: MediaConfig;
}

export const DefaultLogoModal: React.FC<DefaultLogoModalProps> = (props) => {
  const [isSaving, setSaving] = React.useState<boolean>(false);
  const [customLogo, setCustomLogo] = React.useState<CustomLogoConfig | undefined>(props.defaultMediaConfig.customLogo);

  const handleSubmit = async () => {
    setSaving(true);
    await props.onSave({ ...props.defaultMediaConfig, customLogo });
    setSaving(false);
  };

  const { logoAssets } = useAssets();
  const isUploading = useAssetsUploadingState();

  const disabled = !Boolean(customLogo?.id) || logoAssets.length < 1;

  // Logo utils

  const findLogo = (id?: string) => logoAssets.find((asset) => asset.id === id);

  const setSelectedLogo = (asset?: Asset) => {
    if (asset) {
      const _customLogo = customLogo ??
        props.defaultMediaConfig.customLogo ?? {
          visible: true,
          id: "",
          url: "",
          position: BurnPosition.TopRight,
          opacity: 75,
          size: CustomLogoSize.Medium,
          dimensions: { width: 0, height: 0 },
          padding: { x: 10, y: 10 }
        };

      if (_customLogo) {
        setCustomLogo({
          ..._customLogo,
          id: asset.id,
          url: asset.s3Uri,
          visible: Boolean(asset.s3Uri),
          dimensions: { ..._customLogo?.dimensions, width: asset.width, height: asset.height }
        });
      }
    } else {
      setCustomLogo(undefined);
    }
  };

  const uploadLogo = async (file: File) => {
    const uploadedAsset = await uploadAsset({ file, assetType: AssetType.LOGO });
    setSelectedLogo(uploadedAsset);
  };

  const deleteLogo = async (assetId: string) => {
    if (assetId === customLogo?.id) {
      setCustomLogo(undefined);
    }

    await deleteAsset({ assetId });
  };

  // Media config utils

  // handlers

  const handleLogoImageError = (id: string) => {
    assetsStore.remove(id);
    if (customLogo?.id === id) {
      setSelectedLogo();
    }
  };

  const previewCustomLogo = React.useMemo(() => {
    if (!customLogo) return undefined;
    const logoAsset = logoAssets.find((logo) => logo.id === customLogo?.id);
    if (!logoAsset?.publicUrl) return customLogo;
    return { ...customLogo, url: logoAsset?.publicUrl };
  }, [customLogo, logoAssets]);

  return (
    <NewModal
      title="Default logo"
      description="Adjust how logo will look on the videos"
      showCloseButton
      tertiaryAction="Cancel"
      onClickTertiary={props.closeModal}
      primaryAction="Save"
      onClickPrimary={handleSubmit}
      primaryActionLoading={isSaving}
      onDismiss={props.closeModal}
      size="930"
      className="tw-max-w-[568px] min-[978px]:tw-max-w-[930px]"
    >
      <div className="tw-mb-6 tw-flex tw-gap-4 tw-overflow-auto">
        <div className="tw-flex-1 tw-overflow-auto">
          <LockFeature minPermission={SublyPlan.Pro}>
            <div className="tw-mb-6 tw-flex tw-flex-col tw-gap-2">
              <LogoIconControl
                assets={logoAssets}
                selectedId={customLogo?.id}
                setSelectedId={(id) => setSelectedLogo(findLogo(id))}
                onAdd={uploadLogo}
                isAdding={isUploading}
                onRemove={deleteLogo}
                onImageError={handleLogoImageError}
              />
            </div>

            <div
              className={classNames("tw-flex tw-flex-col tw-gap-6", {
                "tw-pointer-events-none tw-cursor-not-allowed tw-opacity-30": disabled
              })}
            >
              {customLogo && (
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <p className="tw-text-md tw-font-semibold">Appearance</p>
                  <LogoAppearanceControl customLogo={customLogo} onChange={setCustomLogo} />
                </div>
              )}
              {customLogo && (
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <p className="tw-text-md tw-font-semibold">Position</p>
                  <LogoPositionControl customLogo={customLogo} onChange={setCustomLogo} />
                </div>
              )}
            </div>
          </LockFeature>
        </div>
        <div className="tw-hidden tw-h-fit tw-w-[343px] min-[978px]:tw-flex">
          <Preview mediaConfig={{ ...props.defaultMediaConfig, customLogo: previewCustomLogo }} showLogo />
        </div>
      </div>
    </NewModal>
  );
};
