import React from "react";

interface PlatFormLogoProps extends React.SVGProps<SVGSVGElement> {
  variant: "greyscale" | "colour";
}

export const OriginalLogo: React.FC<PlatFormLogoProps> = ({ variant = "colour", ...props }) => {
  const fill = {
    greyscale: "#6b7280",
    colour: "#691FF5"
  }[variant];

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5004 21.6004C17.8023 21.6004 22.1004 17.3023 22.1004 12.0004C22.1004 6.69846 17.8023 2.40039 12.5004 2.40039C7.19846 2.40039 2.90039 6.69846 2.90039 12.0004C2.90039 17.3023 7.19846 21.6004 12.5004 21.6004ZM11.966 8.60193C11.5978 8.35645 11.1243 8.33356 10.7342 8.54238C10.344 8.75121 10.1004 9.15784 10.1004 9.60039V14.4004C10.1004 14.8429 10.344 15.2496 10.7342 15.4584C11.1243 15.6672 11.5978 15.6443 11.966 15.3989L15.566 12.9989C15.8999 12.7763 16.1004 12.4016 16.1004 12.0004C16.1004 11.5992 15.8999 11.2245 15.566 11.0019L11.966 8.60193Z"
        fill={fill}
      />
    </svg>
  );
};

export const InstagramLogo: React.FC<PlatFormLogoProps> = ({ variant = "colour", ...props }) => {
  const fill1 = {
    greyscale: "#6b7280",
    colour: "url(#paint0_radial_1937_394102)"
  }[variant];

  const fill2 = {
    greyscale: "#6b7280",
    colour: "url(#paint1_radial_1937_394102)"
  }[variant];

  const fill3 = {
    greyscale: "#6b7280",
    colour: "url(#paint2_radial_1937_394102)"
  }[variant];

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="1.5" y="1.5" width="21" height="21" rx="6" fill={fill1} />
      <rect x="1.5" y="1.5" width="21" height="21" rx="6" fill={fill2} />
      <rect x="1.5" y="1.5" width="21" height="21" rx="6" fill={fill3} />
      <path
        d="M17.25 7.875C17.25 8.49632 16.7463 9 16.125 9C15.5037 9 15 8.49632 15 7.875C15 7.25368 15.5037 6.75 16.125 6.75C16.7463 6.75 17.25 7.25368 17.25 7.875Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75ZM12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 11.7C4.5 9.17976 4.5 7.91965 4.99047 6.95704C5.4219 6.11031 6.11031 5.4219 6.95704 4.99047C7.91965 4.5 9.17976 4.5 11.7 4.5H12.3C14.8202 4.5 16.0804 4.5 17.043 4.99047C17.8897 5.4219 18.5781 6.11031 19.0095 6.95704C19.5 7.91965 19.5 9.17976 19.5 11.7V12.3C19.5 14.8202 19.5 16.0804 19.0095 17.043C18.5781 17.8897 17.8897 18.5781 17.043 19.0095C16.0804 19.5 14.8202 19.5 12.3 19.5H11.7C9.17976 19.5 7.91965 19.5 6.95704 19.0095C6.11031 18.5781 5.4219 17.8897 4.99047 17.043C4.5 16.0804 4.5 14.8202 4.5 12.3V11.7ZM11.7 6H12.3C13.5849 6 14.4583 6.00117 15.1334 6.05633C15.7911 6.11006 16.1274 6.20745 16.362 6.32698C16.9265 6.6146 17.3854 7.07354 17.673 7.63803C17.7926 7.87263 17.8899 8.20893 17.9437 8.86656C17.9988 9.54169 18 10.4151 18 11.7V12.3C18 13.5849 17.9988 14.4583 17.9437 15.1334C17.8899 15.7911 17.7926 16.1274 17.673 16.362C17.3854 16.9265 16.9265 17.3854 16.362 17.673C16.1274 17.7926 15.7911 17.8899 15.1334 17.9437C14.4583 17.9988 13.5849 18 12.3 18H11.7C10.4151 18 9.54169 17.9988 8.86656 17.9437C8.20893 17.8899 7.87263 17.7926 7.63803 17.673C7.07354 17.3854 6.6146 16.9265 6.32698 16.362C6.20745 16.1274 6.11006 15.7911 6.05633 15.1334C6.00117 14.4583 6 13.5849 6 12.3V11.7C6 10.4151 6.00117 9.54169 6.05633 8.86656C6.11006 8.20893 6.20745 7.87263 6.32698 7.63803C6.6146 7.07354 7.07354 6.6146 7.63803 6.32698C7.87263 6.20745 8.20893 6.11006 8.86656 6.05633C9.54169 6.00117 10.4151 6 11.7 6Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1937_394102"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9 17.25) rotate(-55.3758) scale(19.1397)"
        >
          <stop stopColor="#B13589" />
          <stop offset="0.79309" stopColor="#C62F94" />
          <stop offset="1" stopColor="#8A3AC8" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1937_394102"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.25 23.25) rotate(-65.1363) scale(16.9457)"
        >
          <stop stopColor="#E0E8B7" />
          <stop offset="0.444662" stopColor="#FB8A2E" />
          <stop offset="0.71474" stopColor="#E2425C" />
          <stop offset="1" stopColor="#E2425C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1937_394102"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0.375001 2.25) rotate(-8.1301) scale(29.1682 6.23877)"
        >
          <stop offset="0.156701" stopColor="#406ADC" />
          <stop offset="0.467799" stopColor="#6A45BE" />
          <stop offset="1" stopColor="#6A45BE" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export const TikTokLogo: React.FC<PlatFormLogoProps> = ({ variant = "colour", ...props }) => {
  const fill1 = {
    greyscale: "#4b5563",
    colour: "#ee1d52"
  }[variant];

  const fill2 = {
    greyscale: "#6b7280",
    colour: "#000"
  }[variant];

  const fill3 = {
    greyscale: "#d1d5db",
    colour: "#69c9d0"
  }[variant];

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.33809 14.8444C6.4553 13.8739 6.8533 13.3306 7.60332 12.7736C8.67646 12.0193 10.017 12.446 10.017 12.446V9.91462C10.3429 9.90629 10.6689 9.92561 10.9912 9.97235V13.23C10.9912 13.23 9.65112 12.8033 8.57798 13.558C7.82837 14.1146 7.42955 14.6583 7.31275 15.6287C7.30909 16.1557 7.40798 16.8445 7.86337 17.4401C7.75077 17.3824 7.63601 17.3166 7.51908 17.2427C6.51593 16.5691 6.33321 15.5585 6.33809 14.8444ZM16.5262 5.23411C15.788 4.42517 15.5089 3.60842 15.4079 3.03467H16.3366C16.3366 3.03467 16.1514 4.53906 17.5009 6.01849L17.5196 6.03838C17.1559 5.8096 16.8224 5.53956 16.5262 5.23411ZM20.9999 7.52756V10.7197C20.9999 10.7197 19.8149 10.6733 18.9379 10.4501C17.7133 10.1381 16.9263 9.65953 16.9263 9.65953C16.9263 9.65953 16.3826 9.31824 16.3386 9.29445V15.8862C16.3386 16.2532 16.2381 17.1698 15.9317 17.9343C15.5316 18.9343 14.9143 19.5908 14.8007 19.725C14.8007 19.725 14.0499 20.6123 12.7253 21.2098C11.5313 21.7489 10.4829 21.7352 10.1696 21.7489C10.1696 21.7489 8.35741 21.8207 6.72674 20.7609C6.37411 20.5273 6.04496 20.2628 5.74353 19.9707L5.75167 19.9765C7.38275 21.0363 9.19452 20.9645 9.19452 20.9645C9.50828 20.9509 10.5566 20.9645 11.7502 20.4255C13.0736 19.8279 13.8257 18.9406 13.8257 18.9406C13.938 18.8064 14.5582 18.15 14.9566 17.1495C15.2622 16.3854 15.3636 15.4684 15.3636 15.1014V8.51047C15.4075 8.53465 15.9508 8.87594 15.9508 8.87594C15.9508 8.87594 16.7383 9.35491 17.9628 9.66655C18.8402 9.88966 20.0248 9.93607 20.0248 9.93607V7.43473C20.4302 7.52561 20.7757 7.55018 20.9999 7.52756Z"
        fill={fill1}
      />
      <path
        d="M20.0253 7.43473V9.93529C20.0253 9.93529 18.8406 9.88888 17.9632 9.66577C16.7387 9.35374 15.9512 8.87516 15.9512 8.87516C15.9512 8.87516 15.408 8.53387 15.364 8.50969V15.1022C15.364 15.4692 15.2635 16.3862 14.957 17.1503C14.557 18.1507 13.9397 18.8072 13.8261 18.9414C13.8261 18.9414 13.0749 19.8287 11.7506 20.4263C10.557 20.9653 9.50871 20.9516 9.19495 20.9653C9.19495 20.9653 7.38318 21.0371 5.7521 19.9773L5.74396 19.9715C5.57175 19.8047 5.40965 19.6286 5.25846 19.4441C4.73796 18.8095 4.41891 18.0591 4.33874 17.845C4.3386 17.844 4.3386 17.8431 4.33874 17.8422C4.20973 17.4701 3.9387 16.5765 3.97573 15.711C4.04125 14.184 4.57844 13.2467 4.72046 13.0119C5.09661 12.3716 5.58584 11.7987 6.16638 11.3188C6.67867 10.9046 7.25933 10.5751 7.88455 10.3437C8.56043 10.072 9.28418 9.92637 10.017 9.91462V12.446C10.017 12.446 8.67649 12.0208 7.60375 12.7736C6.85373 13.3306 6.45573 13.8739 6.33852 14.8444C6.33364 15.5585 6.51636 16.5691 7.5187 17.2431C7.63563 17.3172 7.75039 17.383 7.86298 17.4405C8.03809 17.6681 8.25122 17.8664 8.49376 18.0275C9.4729 18.6473 10.2933 18.6906 11.3425 18.288C12.042 18.0189 12.5686 17.4124 12.8128 16.7404C12.9662 16.3207 12.9642 15.8983 12.9642 15.4614V3.03467H15.4059C15.5068 3.60842 15.786 4.42517 16.5242 5.23411C16.8204 5.53956 17.1539 5.8096 17.5176 6.03838C17.625 6.14954 18.1744 6.69911 18.8797 7.0365C19.2444 7.21089 19.6288 7.34452 20.0253 7.43473V7.43473Z"
        fill={fill2}
      />
      <path
        d="M3.36694 17.0676V17.0696L3.42753 17.2338C3.42057 17.2147 3.39806 17.1566 3.36694 17.0676Z"
        fill={fill3}
      />
      <path
        d="M7.88461 10.3437C7.25939 10.5751 6.67873 10.9047 6.16644 11.3188C5.58572 11.7999 5.09661 12.374 4.72094 13.0155C4.57891 13.2495 4.04172 14.1876 3.9762 15.7146C3.93917 16.5801 4.2102 17.4737 4.33921 17.8458C4.33907 17.8467 4.33907 17.8476 4.33921 17.8485C4.4206 18.0607 4.73843 18.8112 5.25893 19.4477C5.41012 19.6322 5.57222 19.8083 5.74443 19.975C5.19268 19.6093 4.7006 19.1671 4.28427 18.6629C3.76825 18.0338 3.45001 17.2912 3.36699 17.072C3.36689 17.0704 3.36689 17.0688 3.36699 17.0673V17.0645C3.23758 16.6928 2.96573 15.7989 3.00358 14.9322C3.0691 13.4052 3.60628 12.4679 3.74831 12.2331C4.12388 11.5915 4.613 11.0174 5.19382 10.5364C5.706 10.1221 6.28669 9.79254 6.91199 9.56131C7.30202 9.40621 7.70833 9.29179 8.1239 9.22002C8.75019 9.11524 9.38984 9.10616 10.0191 9.19311V9.91469C9.2856 9.92619 8.56113 10.0718 7.88461 10.3437Z"
        fill={fill3}
      />
      <path
        d="M15.408 3.03476H12.9662V15.4619C12.9662 15.8987 12.9662 16.32 12.8148 16.7408C12.5682 17.4125 12.0437 18.019 11.3445 18.2881C10.295 18.6922 9.47454 18.6474 8.49581 18.0276C8.25285 17.8673 8.03917 17.6696 7.8634 17.4425C8.69726 17.8688 9.44361 17.8614 10.3682 17.5057C11.067 17.2366 11.5923 16.6301 11.8381 15.958C11.992 15.5383 11.9899 15.1159 11.9899 14.6795V2.25H15.3616C15.3616 2.25 15.3237 2.55891 15.408 3.03476ZM20.0253 6.74328V7.43483C19.6295 7.34447 19.2458 7.21085 18.8817 7.0366C18.1765 6.69921 17.6271 6.14964 17.5197 6.03848C17.6443 6.11693 17.7737 6.1883 17.9071 6.25222C18.7645 6.66255 19.609 6.78502 20.0253 6.74328Z"
        fill={fill3}
      />
    </svg>
  );
};

export const FacebookLogo: React.FC<PlatFormLogoProps> = ({ variant = "colour", ...props }) => {
  const fill = {
    greyscale: "#6b7280",
    colour: "url(#paint0_linear_2912_63684)"
  }[variant];

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12.5" r="10.5" fill={fill} />
      <path
        d="M15.9103 15.7112L16.3767 12.7476H13.4589V10.8252C13.4589 10.0143 13.8657 9.2233 15.1726 9.2233H16.5V6.70024C16.5 6.70024 15.2959 6.5 14.1452 6.5C11.7411 6.5 10.1712 7.9197 10.1712 10.4888V12.7476H7.5V15.7112H10.1712V22.8759C10.7075 22.958 11.2562 23 11.8151 23C12.374 23 12.9226 22.958 13.4589 22.8759V15.7112H15.9103Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2912_63684"
          x1="12"
          y1="2"
          x2="12"
          y2="22.9377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18ACFE" />
          <stop offset="1" stopColor="#0163E0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const YouTubeLogo: React.FC<PlatFormLogoProps> = ({ variant = "colour", ...props }) => {
  const fill = {
    greyscale: "#6b7280",
    colour: "#FC0D1B"
  }[variant];

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.68338 7.95583C1.77978 6.47175 2.97296 5.30867 4.45835 5.23508C6.60929 5.12852 9.6836 5 12 5C14.3164 5 17.3907 5.12852 19.5417 5.23508C21.027 5.30867 22.2202 6.47175 22.3166 7.95583C22.409 9.3777 22.5 11.1272 22.5 12.5C22.5 13.8728 22.409 15.6223 22.3166 17.0442C22.2202 18.5283 21.027 19.6913 19.5417 19.7649C17.3907 19.8715 14.3164 20 12 20C9.6836 20 6.60929 19.8715 4.45835 19.7649C2.97296 19.6913 1.77978 18.5283 1.68338 17.0442C1.59102 15.6223 1.5 13.8728 1.5 12.5C1.5 11.1272 1.59102 9.37769 1.68338 7.95583Z"
        fill={fill}
      />
      <path d="M9.75 9.5V15.5L15.75 12.5L9.75 9.5Z" fill="white" />
    </svg>
  );
};

export const LinkedInLogo: React.FC<PlatFormLogoProps> = ({ variant = "colour", ...props }) => {
  const fill = {
    greyscale: "#6b7280",
    colour: "#1275B1"
  }[variant];

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="1.5" y="2" width="21" height="21" rx="10.5" fill={fill} />
      <path
        d="M9.46392 7.76911C9.46392 8.47002 8.85639 9.03822 8.10696 9.03822C7.35753 9.03822 6.75 8.47002 6.75 7.76911C6.75 7.0682 7.35753 6.5 8.10696 6.5C8.85639 6.5 9.46392 7.0682 9.46392 7.76911Z"
        fill="white"
      />
      <path d="M6.93557 9.97107H9.25515V17H6.93557V9.97107Z" fill="white" />
      <path
        d="M12.9897 9.97107H10.6701V17H12.9897C12.9897 17 12.9897 14.7872 12.9897 13.4036C12.9897 12.5732 13.2732 11.7392 14.4046 11.7392C15.6833 11.7392 15.6756 12.8259 15.6696 13.6678C15.6618 14.7683 15.6804 15.8914 15.6804 17H18V13.2903C17.9804 10.9215 17.3631 9.83006 15.3325 9.83006C14.1265 9.83006 13.379 10.3775 12.9897 10.8729V9.97107Z"
        fill="white"
      />
    </svg>
  );
};

export const VimeoLogo: React.FC<PlatFormLogoProps> = ({ variant = "colour", ...props }) => {
  const fill = {
    greyscale: "#6b7280",
    colour: "#3CB9E6"
  }[variant];

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.3481 5.74684L1.53418 8.29748L2.50782 9.52344C2.94141 9.25391 3.8086 8.55078 4.19532 8.55078C5.06251 8.55078 5.84208 10.9004 6.58595 13.4844C7.25391 15.8047 7.94532 21.5703 11.1328 21.5703C14.8008 21.5703 22.5117 11.668 22.5117 7.26076C22.5117 5.66605 22.1953 3.37109 19.0195 3.37109C15.6164 3.37109 13.9421 6.65535 13.5304 8.29748C13.8266 8.1 14.6329 7.96835 15.0772 7.96835C16.8873 7.96835 16.5007 9.8446 16.2539 10.6016C16.0071 11.3585 14.1094 15.0443 13.0861 15.0443C11.8355 15.0443 11.0949 9.61392 10.7329 7.26076C10.3709 4.9076 9.43292 3.62405 8.00127 3.62405C6.85596 3.62405 5.08861 5.03925 4.3481 5.74684Z"
        fill={fill}
      />
    </svg>
  );
};
