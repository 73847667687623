import { BoldIcon, ItalicsIcon, UnderlineIcon } from "@shared/components/icons";
import { ToolTip } from "@shared/primitives/tooltip";

import { AssStyle } from "@getsubly/common";
import { ToolbarButton } from "@media-editor/components/buttons";

interface EmphasisTextControlProps {
  value: Partial<AssStyle>;
  onUpdate: (value: Partial<AssStyle>) => void;
  disableTabIndex?: boolean;
  hideLabels?: boolean;
}
export const TextEmphasisControl: React.FC<EmphasisTextControlProps> = ({
  value,
  onUpdate,
  disableTabIndex,
  hideLabels
}) => {
  return (
    <div className="tw-flex tw-flex-col">
      {!hideLabels && <p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Emphasis</p>}
      <div className="tw-flex tw-flex-row tw-gap-1 tw-rounded-md tw-border tw-border-neutral-200 tw-p-1">
        <ToolTip text="Text style bold" tipClassName="tw-flex-1">
          <BoldInput
            aria-labelledby="Text style bold"
            value={value.bold}
            disableTabIndex={disableTabIndex}
            onChange={(v) => onUpdate({ bold: v })}
          />
        </ToolTip>
        <ToolTip text="Text style italic" tipClassName="tw-flex-1">
          <ItalicInput
            aria-labelledby="Text style italic"
            value={value.italic}
            disableTabIndex={disableTabIndex}
            onChange={(v) => onUpdate({ italic: v })}
          />
        </ToolTip>
        <ToolTip text="Text style underline" tipClassName="tw-flex-1">
          <UnderlineInput
            aria-labelledby="Text style underline"
            value={value.underline}
            disableTabIndex={disableTabIndex}
            onChange={(v) => onUpdate({ underline: v })}
          />
        </ToolTip>
      </div>
    </div>
  );
};

interface BoldInputProps {
  "aria-labelledby"?: string;
  value?: 0 | -1;
  disableTabIndex?: boolean;
  onChange: (value: 0 | -1) => void;
}

export const BoldInput: React.FC<BoldInputProps> = (props) => {
  return (
    <ToolbarButton
      className="tw-min-w-full"
      aria-labelledby={props["aria-labelledby"]}
      active={props.value === -1}
      disableTabIndex={props.disableTabIndex}
      onClick={() => props.onChange(props.value === -1 ? 0 : -1)}
    >
      <BoldIcon className="tw-min-h-[24px] tw-min-w-[24px]" />
    </ToolbarButton>
  );
};

interface ItalicInputProps {
  "aria-labelledby"?: string;
  value?: 0 | -1;
  disableTabIndex?: boolean;
  onChange: (value: 0 | -1) => void;
}

export const ItalicInput: React.FC<ItalicInputProps> = (props) => {
  return (
    <ToolbarButton
      className="tw-min-w-full"
      aria-labelledby={props["aria-labelledby"]}
      active={props.value === -1}
      disableTabIndex={props.disableTabIndex}
      onClick={() => props.onChange(props.value === -1 ? 0 : -1)}
    >
      <ItalicsIcon className="tw-min-h-[24px] tw-min-w-[24px]" />
    </ToolbarButton>
  );
};

interface UnderlineInputProps {
  "aria-labelledby"?: string;
  value?: 0 | -1;
  disableTabIndex?: boolean;
  onChange: (value: 0 | -1) => void;
}

export const UnderlineInput: React.FC<UnderlineInputProps> = (props) => {
  return (
    <ToolbarButton
      className="tw-min-w-full"
      aria-labelledby={props["aria-labelledby"]}
      active={props.value === -1}
      disableTabIndex={props.disableTabIndex}
      onClick={() => props.onChange(props.value === -1 ? 0 : -1)}
    >
      <UnderlineIcon className="tw-min-h-[24px] tw-min-w-[24px]" />
    </ToolbarButton>
  );
};
