import React from "react";
import classNames from "classnames";

import { ColorContrastChecker } from "@shared/components/color-contrast-checker";
import { ColorPicker } from "@shared/components/color-picker/color-picker";
import { EffectPresetDropdown } from "@shared/components/effect-preset-dropdown/effect-preset-dropdown";
import { EffectPresetGalleryPicker } from "@shared/components/effect-preset-gallery-picker/effect-preset-gallery-picker";
import { Avatar } from "@shared/primitives/avatar/avatar";
import { Button } from "@shared/primitives/button";
import { Dropdown, DropdownButton, DropdownMenu } from "@shared/primitives/dropdown";
import { NumberInput } from "@shared/primitives/number-input";

import { assColorToHex, AssStyle, BackgroundStyle, hexToAssColor, MediaConfig } from "@getsubly/common";

import { Sidecar } from "../../../../media-editor/src/components/sidecar/sidecar";
import { SidecarProvider, useSidecar } from "../../../../media-editor/src/components/sidecar/sidecar.context";

interface TextEffectControlProps {
  value: AssStyle;
  onUpdateStyleValue: (value: Partial<MediaConfig>) => void;
  primaryColors?: string[];
  outlineColors?: string[];
  addColor?: (type: "primaryColors" | "outlineColors", color: string) => void;
  removeColor?: (type: "primaryColors" | "outlineColors", color: string) => void;
  className?: string;
  inputType?: "gallery-picker" | "dropdown";
  effectPickerClassName?: string;
  effectContentClassName?: string;
  colorContrastCheckerClassName?: string;
  includeFullWidthTextEffect?: boolean;
  showInPortal?: boolean;
}

export const TextEffectControl: React.FC<TextEffectControlProps> = (props) => {
  const [activeSidecarInput] = React.useState<SidecarInput | null>(null);

  const backgroundStyle = props.value?.backgroundStyle ?? BackgroundStyle.DropShadow;
  const showSize = [BackgroundStyle.DropShadow, BackgroundStyle.Outline].includes(props.value.backgroundStyle);
  const showBoxHeight = props.value.backgroundStyle === BackgroundStyle.FullWidth;

  const handleChangeEffect = ({ style, outline }: { style: BackgroundStyle; outline: number }) => {
    props.onUpdateStyleValue({ ...props.value, outline, subtitles: { boxHeight: 300, fullWidth: false } });
    if (props.value?.backgroundStyle !== style) {
      props.onUpdateStyleValue({ ...props.value, ...EFFECT_DEFAULTS[style], backgroundStyle: style });
    }
  };

  const handleChangeOutline = (outline: number) => {
    if (outline >= 1) {
      props.onUpdateStyleValue({ ...props.value, outline: Math.round(outline) });
    }
  };

  const handleChangeBoxHeight = (boxHeight: number) => {
    if (boxHeight >= 1) {
      props.onUpdateStyleValue({ ...props.value, subtitles: { fullWidth: true, ...props.value.subtitles, boxHeight } });
    }
  };

  const handleChangeTextColor = (color: string) => {
    props.onUpdateStyleValue({
      ...props.value,
      colorPrimary: hexToAssColor(color)
    });
  };

  const handleChangeEffectColor = (color: string) => {
    const colorValue = hexToAssColor(color);
    props.onUpdateStyleValue({
      ...props.value,
      colorBack: colorValue,
      colorOutline: colorValue,
      colorSecondary: colorValue
    });
  };

  const inputType = props.inputType ?? "gallery-picker";

  return (
    <div className={classNames("tw-flex tw-flex-col", props.className)}>
      {inputType === "gallery-picker" ? (
        <EffectPresetGalleryPicker
          effect={{ style: backgroundStyle, outline: props.value.outline ?? 1 }}
          onChange={handleChangeEffect}
          className={classNames("tw-mb-4", props.effectPickerClassName)}
          includeFullWidthEffect={props.includeFullWidthTextEffect}
        />
      ) : (
        <EffectPresetDropdown
          effect={{ style: backgroundStyle, outline: props.value.outline ?? 1 }}
          onChange={handleChangeEffect}
          className={classNames("tw-mb-4", props.effectPickerClassName)}
          includeFullWidthEffect={props.includeFullWidthTextEffect}
        />
      )}

      <div className={classNames("tw-flex tw-w-full tw-flex-row tw-gap-2", props.effectContentClassName)}>
        <Dropdown>
          <DropdownButton
            size="40"
            className={classNames("tw-flex-1", {
              "tw-shadow-focus-ring": activeSidecarInput === SidecarInput.TextColour
            })}
            variant="secondary"
            showArrow={false}
          >
            <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between">
              <p className="tw-text-sm">Text</p>
              <Avatar color={assColorToHex(props.value.colorPrimary) || "#000"} size="20" />
            </div>
          </DropdownButton>
          <DropdownMenu className="tw-z-[99999] tw-w-full tw-overflow-y-auto" showInPortal={props.showInPortal}>
            <ColorPicker
              color={assColorToHex(props.value.colorPrimary)}
              onChange={handleChangeTextColor}
              showAlpha
              showCustomColors={Boolean(props.primaryColors)}
              customColors={props.primaryColors}
              addCustomColor={(color) => props.addColor?.("primaryColors", color)}
              deleteCustomColor={(color) => props.removeColor?.("primaryColors", color)}
            />
          </DropdownMenu>
        </Dropdown>

        {backgroundStyle !== BackgroundStyle.None && (
          <Dropdown>
            <DropdownButton
              size="40"
              className={classNames("tw-flex-1", {
                "tw-shadow-focus-ring": activeSidecarInput === SidecarInput.EffectColour
              })}
              variant="secondary"
              showArrow={false}
            >
              <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between">
                <p className="tw-text-sm">Effect</p>
                <Avatar color={assColorToHex(props.value.colorBack) || "#000"} size="20" />
              </div>
            </DropdownButton>
            <DropdownMenu className="tw-z-[99999] tw-w-full tw-overflow-y-auto" showInPortal={props.showInPortal}>
              <ColorPicker
                color={assColorToHex(props.value.colorBack)}
                onChange={handleChangeEffectColor}
                showAlpha
                showCustomColors={Boolean(props.outlineColors)}
                customColors={props.outlineColors}
                addCustomColor={(color) => props.addColor?.("outlineColors", color)}
                deleteCustomColor={(color) => props.removeColor?.("outlineColors", color)}
              />
            </DropdownMenu>
          </Dropdown>
        )}

        {showSize && (
          <NumberInput value={props.value.outline ?? 1} onChange={handleChangeOutline} className="!tw-w-24" hasArrows />
        )}

        {showBoxHeight && (
          <NumberInput
            value={props.value.subtitles?.boxHeight ?? 300}
            onChange={handleChangeBoxHeight}
            className="!tw-w-24"
            hasArrows
          />
        )}
      </div>
    </div>
  );
};

interface TextEffectWithSidecarControlProps {
  value: AssStyle;
  onUpdateStyleValue: (value: Partial<MediaConfig>) => void;
  primaryColors?: string[];
  outlineColors?: string[];
  addColor?: (type: "primaryColors" | "outlineColors", color: string) => void;
  removeColor?: (type: "primaryColors" | "outlineColors", color: string) => void;
  className?: string;
  effectPickerClassName?: string;
  effectContentClassName?: string;
  colorContrastCheckerClassName?: string;
  includeFullWidthTextEffect?: boolean;
}

export const TextEffectWithSidecarControl: React.FC<TextEffectWithSidecarControlProps> = (props) => {
  return (
    <SidecarProvider>
      <TextEffectToolWithSidecarContent {...props} />
    </SidecarProvider>
  );
};

enum SidecarInput {
  TextColour = "TextColour",
  EffectColour = "EffectColour"
}

const EFFECT_DEFAULTS: Record<BackgroundStyle, Partial<AssStyle>> = {
  [BackgroundStyle.DropShadow]: {
    hasBackground: false,
    outline: 2,
    shadow: 4,
    borderStyle: 1,
    subtitles: undefined
  },
  [BackgroundStyle.Background]: {
    hasBackground: true,
    outline: 10,
    shadow: 0.5,
    borderStyle: 3,
    subtitles: undefined
  },
  [BackgroundStyle.Outline]: {
    hasBackground: false,
    outline: 4,
    shadow: 0.5,
    borderStyle: 1,
    subtitles: undefined
  },
  [BackgroundStyle.FullWidth]: {
    hasBackground: true,
    outline: 0,
    shadow: 0,
    borderStyle: 0,
    subtitles: { boxHeight: 300, fullWidth: true }
  },
  [BackgroundStyle.None]: {
    hasBackground: false,
    outline: 0,
    shadow: 0,
    borderStyle: "\r" as const
  }
};

export const TextEffectToolWithSidecarContent: React.FC<TextEffectWithSidecarControlProps> = (props) => {
  const { showSidecar, setShowSidecar, setReference } = useSidecar();
  const [activeSidecarInput, setActiveSidecarInput] = React.useState<SidecarInput | null>(null);

  const backgroundStyle = props.value?.backgroundStyle ?? BackgroundStyle.DropShadow;
  const showSize = [BackgroundStyle.DropShadow, BackgroundStyle.Outline].includes(props.value.backgroundStyle);
  const showBoxHeight = props.value.backgroundStyle === BackgroundStyle.FullWidth;

  const handleChangeEffect = ({ style, outline }: { style: BackgroundStyle; outline: number }) => {
    props.onUpdateStyleValue({ ...props.value, outline, subtitles: { boxHeight: 300, fullWidth: false } });
    if (props.value?.backgroundStyle !== style) {
      props.onUpdateStyleValue({ ...props.value, ...EFFECT_DEFAULTS[style], backgroundStyle: style });
    }
  };

  const handleChangeOutline = (outline: number) => {
    if (outline >= 1) {
      props.onUpdateStyleValue({ ...props.value, outline: Math.round(outline) });
    }
  };

  const handleChangeBoxHeight = (boxHeight: number) => {
    if (boxHeight >= 1) {
      props.onUpdateStyleValue({ ...props.value, subtitles: { fullWidth: true, ...props.value.subtitles, boxHeight } });
    }
  };

  const handleChangeTextColor = (color: string) => {
    props.onUpdateStyleValue({
      ...props.value,
      colorPrimary: hexToAssColor(color)
    });
  };

  const handleChangeEffectColor = (color: string) => {
    const colorValue = hexToAssColor(color);
    props.onUpdateStyleValue({
      ...props.value,
      colorBack: colorValue,
      colorOutline: colorValue,
      colorSecondary: colorValue
    });
  };

  const handleOpenSidecar = (input: SidecarInput) => {
    setShowSidecar(true);
    setActiveSidecarInput(input);
  };

  const handleCloseSidecar = () => {
    setActiveSidecarInput(null);
  };

  return (
    <div className={classNames("tw-flex tw-flex-col", props.className)} ref={setReference}>
      <Sidecar show={showSidecar} onClose={handleCloseSidecar}>
        {activeSidecarInput === SidecarInput.TextColour && (
          <ColorPicker
            color={assColorToHex(props.value.colorPrimary)}
            onChange={handleChangeTextColor}
            showAlpha
            showCustomColors={Boolean(props.primaryColors)}
            customColors={props.primaryColors}
            addCustomColor={(color) => props.addColor?.("primaryColors", color)}
            deleteCustomColor={(color) => props.removeColor?.("primaryColors", color)}
          />
        )}
        {activeSidecarInput === SidecarInput.EffectColour ? (
          <ColorPicker
            color={assColorToHex(props.value.colorBack)}
            onChange={handleChangeEffectColor}
            showAlpha
            showCustomColors={Boolean(props.outlineColors)}
            customColors={props.outlineColors}
            addCustomColor={(color) => props.addColor?.("outlineColors", color)}
            deleteCustomColor={(color) => props.removeColor?.("outlineColors", color)}
          />
        ) : null}
      </Sidecar>

      <EffectPresetGalleryPicker
        effect={{ style: backgroundStyle, outline: props.value.outline ?? 1 }}
        onChange={handleChangeEffect}
        className={classNames("tw-mb-4", props.effectPickerClassName)}
        includeFullWidthEffect={props.includeFullWidthTextEffect}
      />

      <div className={classNames("tw-flex tw-w-full tw-flex-row tw-gap-2", props.effectContentClassName)}>
        <Button
          className={classNames("tw-flex-1", {
            "tw-shadow-focus-ring": activeSidecarInput === SidecarInput.TextColour
          })}
          variant="secondary"
          onClick={() => handleOpenSidecar(SidecarInput.TextColour)}
        >
          <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between">
            <p className="tw-text-sm">Text</p>
            <Avatar color={assColorToHex(props.value.colorPrimary) || "#000"} size="20" />
          </div>
        </Button>
        {backgroundStyle !== BackgroundStyle.None && (
          <Button
            className={classNames("tw-flex-1", {
              "tw-shadow-focus-ring": activeSidecarInput === SidecarInput.EffectColour
            })}
            variant="secondary"
            onClick={() => handleOpenSidecar(SidecarInput.EffectColour)}
          >
            <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between">
              <p className="tw-text-sm">Effect</p>
              <Avatar color={assColorToHex(props.value.colorBack) || "#000"} size="20" />
            </div>
          </Button>
        )}
        {showSize && (
          <NumberInput value={props.value.outline ?? 1} onChange={handleChangeOutline} className="!tw-w-24" hasArrows />
        )}
        {showBoxHeight && (
          <NumberInput
            value={props.value.subtitles?.boxHeight ?? 300}
            onChange={handleChangeBoxHeight}
            className="!tw-w-24"
            hasArrows
          />
        )}
      </div>
      {backgroundStyle !== BackgroundStyle.None && (
        <div className={classNames("tw-mt-6", props.colorContrastCheckerClassName)}>
          <p className="tw-mb-2 tw-font-semibold tw-text-neutral-900">WCAG Rating</p>
          <ColorContrastChecker
            textColor={assColorToHex(props.value.colorPrimary)?.slice(0, 7)}
            backgroundColor={assColorToHex(props.value.colorBack)?.slice(0, 7) || "#000"}
          />
        </div>
      )}
    </div>
  );
};
