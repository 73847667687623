import React from "react";

import { updateAccountColours } from "@frontend/api/account.service";

import { ColorContrastChecker } from "@shared/components/color-contrast-checker";
import {
  TextAlignmentControl,
  TextAngleControl,
  TextEffectControl,
  TextEmphasisControl,
  TextPositionControl
} from "@shared/components/controls";
import { ArrowLeftIcon, HeroPlusIcon as PlusIcon } from "@shared/components/icons";
import { LockFeature } from "@shared/components/lock-feature/lock-feature";
import { AccountTemplateStyles } from "@shared/interfaces/account";
import { Button, IconButton } from "@shared/primitives/button";
import { Divider } from "@shared/primitives/divider";
import { Textarea } from "@shared/primitives/textarea";
import {
  useAccountSettingsAspectRatioColorsState,
  useAccountSettingsOutlineColorsState,
  useAccountSettingsPrimaryColorsState
} from "@shared/state/account";
import { useActiveMediaConfigState, useActiveMediaIdState } from "@shared/state/editor/editor.hooks";
import { timelineStore } from "@shared/state/timeline/timeline.store";
import { pluralize } from "@shared/utils/strings";
import { msToSec } from "@shared/utils/time";

import { assColorToHex, BackgroundStyle } from "@getsubly/common";
import { AssStyle, TitleConfig } from "@getsubly/common/dist/interfaces/media-config";
import { FeaturePaywallAlert } from "@media-editor/components/alerts/feature-paywall-alert";
import { FontDropdown } from "@media-editor/components/dropdowns/font-dropdown";
import { FontSizeDropdown } from "@media-editor/components/dropdowns/font-size-dropdown";
import { SavedTitlesSection } from "@media-editor/components/editor-sidebar/text-panel/saved-texts-section";
import { TextTimestamp } from "@media-editor/components/editor-sidebar/text-panel/text-timestamp";
import { EDITOR_MENUBAR_ITEM } from "@media-editor/contexts/editor-menubar.context";
import { useMediaEditor } from "@media-editor/contexts/media-editor.context";
import { useEditorSelectedTitleIdState } from "@media-editor/state/media-editor.hooks";
import { mediaPlayerRepository, useMediaPlayer, useMediaPlayerDurationMSState } from "@media-player";

export interface TitleData {
  text?: string;
  start?: number;
  end?: number;
  showFullTime?: boolean;
}

export const TextPanel = () => {
  const { mediaPlayerRef } = useMediaPlayer();

  const { settings } = useMediaEditor();
  const canUploadFont = !settings.features.isIframe;

  const durationInMs = useMediaPlayerDurationMSState();

  const { handleUpdateTitle, handleUpdateConfig, addTitle } = useMediaEditor();
  const mediaConfig = useActiveMediaConfigState();
  const mediaId = useActiveMediaIdState();

  const primaryColors = useAccountSettingsPrimaryColorsState();
  const outlineColors = useAccountSettingsOutlineColorsState();
  const aspectRatioColors = useAccountSettingsAspectRatioColorsState();

  const { selectedTitleId, setSelectedTitleId } = useEditorSelectedTitleIdState();

  const titles = mediaConfig?.titles ?? [];
  const selectedTitle = mediaConfig?.titles?.find((title) => title.id === selectedTitleId);
  const topRef = React.useRef<HTMLDivElement | null>(null);
  const editMode = selectedTitleId && selectedTitle;
  const showSavedTitles = titles.length > 0;

  if (!mediaConfig) {
    return null;
  }

  const addColor = (color: string) => {
    const filterUnique = (colours: string[]) => [...new Set(colours)];

    updateAccountColours({
      primaryColors: filterUnique([...(primaryColors || []), color]),
      outlineColors: filterUnique([...(outlineColors || []), color]),
      aspectRatioColors: filterUnique([...(aspectRatioColors || []), color])
    });
  };

  const removeColor = (type: "primaryColors" | "outlineColors", color: string) => {
    switch (type) {
      case "primaryColors": {
        const filtered = (primaryColors || []).filter((_color) => _color !== color);
        updateAccountColours({ primaryColors: [...filtered] });
        break;
      }
      case "outlineColors": {
        const filtered = (outlineColors || []).filter((_color) => _color !== color);
        updateAccountColours({ outlineColors: [...filtered] });
        break;
      }
    }
  };

  const updateSelectedTitleStyles = (newStyle: Partial<AccountTemplateStyles>) => {
    if (!mediaConfig.titles || !selectedTitle) {
      return;
    }

    handleUpdateTitle({
      ...selectedTitle,
      style: { ...selectedTitle.style, ...newStyle }
    });
  };

  const updateSelectedTitleData = (data: TitleData) => {
    if (!mediaConfig.titles || !selectedTitle) {
      return;
    }

    handleUpdateTitle({ ...selectedTitle, ...data });
  };

  const onTitleClick = (id: string) => {
    setSelectedTitleId(id);
    topRef.current?.scrollIntoView();
    const selectedTitle = mediaConfig?.titles?.find((title) => title.id === id);
    const startSec = msToSec(selectedTitle?.start);

    if (mediaId && mediaPlayerRef.current && startSec >= 0) {
      mediaPlayerRepository.updateState({
        currentTime: startSec
      });
      mediaPlayerRef.current.setTime(startSec);
      timelineStore.toggleTimeline$.next(true);
    }
  };

  const onDeleteTitle = (id: string) => {
    const existingTitles = mediaConfig?.titles ?? [];
    const titles = existingTitles.filter((el) => el.id !== id);

    handleUpdateConfig({ ...mediaConfig, titles });
    return;
  };

  return (
    <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-pt-4">
      <div className="tw-mb-4 tw-flex tw-flex-col tw-px-4 tw-py-0">
        <h6 className="tw-text-lg tw-font-semibold">Text</h6>
      </div>
      <FeaturePaywallAlert
        className="tw-mx-4 tw-mb-6 tw-w-[calc(100%-40px)]"
        minPermission={EDITOR_MENUBAR_ITEM.Text.plan}
        heading={EDITOR_MENUBAR_ITEM.Text.planMessage().heading}
        description={EDITOR_MENUBAR_ITEM.Text.planMessage().description}
      />
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-overflow-y-auto tw-overflow-x-hidden">
        <div ref={topRef} />
        <LockFeature minPermission={EDITOR_MENUBAR_ITEM.Subtitles.plan}>
          {editMode ? (
            <div className="tw-mb-6 tw-flex tw-w-full tw-flex-col tw-gap-8">
              <div className="tw-flex tw-flex-col tw-gap-6">
                <div
                  className="tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-start tw-gap-1 tw-px-4 hover:tw-underline"
                  onClick={() => setSelectedTitleId("")}
                >
                  <IconButton
                    variant="ghost"
                    onClick={() => setSelectedTitleId("")}
                    size="32"
                    icon={<ArrowLeftIcon className="!tw-h-5 !tw-w-5" />}
                  />
                  Back to saved {pluralize(titles.length, "text")}
                </div>
                <div className="tw-flex tw-w-full tw-flex-col tw-gap-5 tw-px-4">
                  <Textarea
                    className="tw-w-full"
                    placeholder="Type text here"
                    value={selectedTitle?.text ?? ""}
                    onChange={(e) => updateSelectedTitleData({ text: e.target.value })}
                  />
                  <TextTimestamp
                    start={selectedTitle.start}
                    end={selectedTitle.end}
                    showFullTime={selectedTitle.showFullTime}
                    onChangeTime={updateSelectedTitleData}
                  />
                </div>
              </div>
              <Divider className="tw-m-0" />
              <div className="tw-flex tw-w-full tw-flex-col tw-gap-5 tw-px-4">
                <div className="tw-flex tw-flex-col tw-gap-2">
                  {selectedTitle.style && (
                    <div className="tw-flex tw-flex-col">
                      <div className="tw-mb-4 tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2">
                        <div className="tw-flex tw-flex-grow tw-flex-col">
                          {<p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Font</p>}
                          <FontDropdown
                            value={selectedTitle.style.font}
                            onChange={(values: Partial<AssStyle>) =>
                              updateSelectedTitleStyles({ ...selectedTitle.style, ...values })
                            }
                            canUpload={canUploadFont}
                          />
                        </div>
                        <div className="tw-flex tw-flex-col">
                          {<p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Size</p>}
                          <FontSizeDropdown
                            value={selectedTitle.style.fontSize}
                            onChange={(fontSize: number) =>
                              updateSelectedTitleStyles({ ...selectedTitle.style, fontSize })
                            }
                          />
                        </div>
                      </div>
                      <div className="tw-flex tw-flex-row tw-gap-6">
                        <TextEmphasisControl
                          value={selectedTitle.style}
                          onUpdate={(values: Partial<AssStyle>) =>
                            updateSelectedTitleStyles({ ...selectedTitle.style, ...values })
                          }
                        />
                        <TextAlignmentControl
                          value={selectedTitle.style}
                          onUpdate={(values: Partial<AssStyle>) =>
                            updateSelectedTitleStyles({ ...selectedTitle.style, ...values })
                          }
                        />
                        <TextAngleControl
                          value={selectedTitle.style}
                          onUpdate={(values: Partial<AssStyle>) =>
                            updateSelectedTitleStyles({ ...selectedTitle.style, ...values })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="tw-flex tw-flex-col tw-gap-2">
                  <p className="tw-font-semibold">Effects</p>
                  {selectedTitle.style && (
                    <TextEffectControl
                      value={selectedTitle.style}
                      onUpdateStyleValue={updateSelectedTitleStyles}
                      primaryColors={primaryColors}
                      outlineColors={outlineColors}
                      addColor={addColor}
                      removeColor={removeColor}
                    />
                  )}
                  {selectedTitle.style && selectedTitle.style.backgroundStyle !== BackgroundStyle.None && (
                    <div className="tw-mt-6">
                      <p className="tw-mb-2 tw-font-semibold tw-text-neutral-900">WCAG Rating</p>
                      <ColorContrastChecker
                        textColor={assColorToHex(selectedTitle.style.colorPrimary)?.slice(0, 7)}
                        backgroundColor={assColorToHex(selectedTitle.style.colorBack)?.slice(0, 7) || "#000"}
                      />
                    </div>
                  )}
                </div>
              </div>
              <Divider className="tw-m-0" />
              <div className="tw-flex tw-w-full tw-flex-col tw-gap-5 tw-px-4">
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <p className="tw-font-semibold">Text Position</p>
                  <TextPositionControl value={selectedTitle.style} onUpdate={updateSelectedTitleStyles} />
                </div>
              </div>
            </div>
          ) : (
            <>
              <AddTextButton primary={!showSavedTitles} onClick={addTitle} titlesExist={showSavedTitles} />
              {showSavedTitles ? (
                <SavedTitlesSection
                  titles={titles}
                  mediaConfig={mediaConfig}
                  mediaLength={durationInMs}
                  activeTitleId={selectedTitleId}
                  onClickTitle={onTitleClick}
                  onDeleteTitle={onDeleteTitle}
                />
              ) : (
                <TextsEmptyList />
              )}
            </>
          )}
        </LockFeature>
      </div>
    </div>
  );
};

export const DEFAULT_TITLE_CONFIG: Omit<TitleConfig, "id" | "end"> = {
  start: 0,
  showFullTime: false,
  text: "",
  style: {
    font: "Arial",
    fontSize: 104,
    colorPrimary: "FFFFFF",
    colorSecondary: "0000FF",
    colorOutline: "00000000",
    colorBack: "000000",
    bold: -1,
    italic: 0,
    underline: 0,
    strikeOut: 0,
    scaleX: 100,
    scaleY: 100,
    spacing: 0,
    angle: 0,
    borderStyle: 3,
    outline: 10,
    shadow: 0.5,
    alignment: 5,
    marginL: 100,
    marginR: 100,
    marginV: 200,
    encoding: 0,
    hasBackground: false,
    backgroundStyle: BackgroundStyle.Outline
  }
};

interface AddTextButtonProps {
  primary: boolean;
  titlesExist: boolean;
  onClick: () => void;
}

export const AddTextButton: React.FC<AddTextButtonProps> = ({ primary, titlesExist, onClick }) => {
  return (
    <div className="tw-mb-6 tw-flex tw-w-full tw-flex-col tw-px-4">
      <Button variant={primary ? "primary" : "secondary"} onClick={onClick} size="40">
        <PlusIcon className="tw-h-[18px] tw-w-[18px]" strokeWidth={2.8} />{" "}
        <p>Add {titlesExist ? "another" : ""} text</p>
      </Button>
    </div>
  );
};

export const TextsEmptyList: React.FC = () => {
  return (
    <section className="tw-mt-56 tw-h-full tw-text-neutral-500">
      <p className="tw-h-full tw-text-center">
        Start adding texts by clicking on <br />
        "Add text" button above
      </p>
    </section>
  );
};
