import { Input } from "@shared/primitives/input";

import { AssStyle, MediaConfig } from "@getsubly/common";

interface TextAngleControlProps {
  value: AssStyle;
  onUpdate: (value: Partial<MediaConfig>) => void;
  hideLabels?: boolean;
}
export const TextAngleControl: React.FC<TextAngleControlProps> = ({ value, onUpdate, hideLabels }) => {
  const handleChange = (angle: number | string) => {
    angle = Number(angle);

    if (angle > 360) angle = 360;
    if (angle < -360) angle = -360;

    onUpdate({ ...value, angle: angle * -1 });
  };

  return (
    <div className="tw-flex tw-flex-col">
      {hideLabels !== true ? <p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Angle (°)</p> : null}

      <Input
        leftIcon={
          <div className="tw-flex tw-flex-row tw-items-center">
            <div
              className="tw-flex tw-h-4 tw-w-4 tw-rounded-full tw-border-2 tw-border-neutral-700 tw-bg-neutral-700"
              style={{
                transform: `rotate(${value.angle * -1 - 90}deg)`
              }}
            >
              <div className="tw-absolute tw-left-1/2 tw-top-1/2 tw-h-0.5 tw-w-1/2 tw-translate-y-[-1px] tw-bg-white" />
            </div>
          </div>
        }
        type="number"
        hasArrows
        value={value.angle * -1}
        onChange={({ target }) => handleChange(Number(target.value))}
        min={-360}
        max={360}
      />
    </div>
  );
};
