import React from "react";
import classNames from "classnames";

type TabPropsTheme = "neutral" | "primary";

interface TabsProps {
  gap?: string;
  className?: string;
  children: React.ReactNode;
}
const Container: React.FC<TabsProps> = ({ gap, className, children }) => {
  return (
    <div
      className={classNames(
        "tw-flex tw-w-fit tw-select-none tw-flex-row tw-items-center tw-overflow-x-auto",
        className
      )}
      style={{ gap }}
    >
      {children}
    </div>
  );
};

interface TabProps {
  label?: string;
  icon?: JSX.Element;
  selected?: boolean;
  theme?: TabPropsTheme;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
}
const Tab: React.FC<TabProps> = ({ label, icon, selected, theme = "neutral", onClick, children, className }) => {
  const renderIcon = (icon: JSX.Element, className: string) => {
    className = classNames(icon.props.className, className);
    const props = { ...icon.props, className };
    return <icon.type {...props} />;
  };

  const Icon = icon ? renderIcon(icon, "tw-w-4 tw-h-4") : null;

  const isNeutral = theme === "neutral" && !selected;
  const isSelectedNeutral = theme === "neutral" && selected;

  const isPrimary = theme === "primary" && !selected;
  const isSelectedPrimary = theme === "primary" && selected;

  return (
    <a
      href="#"
      role="button"
      className={classNames(
        "tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-border-b-2 tw-px-4 tw-py-3 tw-text-sm",
        {
          "tw-border-b-transparent tw-text-neutral-600 hover:tw-border-neutral-200 hover:tw-text-neutral-900": isNeutral
        },
        {
          "!tw-border-b-neutral-900 !tw-text-neutral-900 hover:tw-border-neutral-900 hover:tw-text-neutral-900":
            isSelectedNeutral
        },
        {
          "tw-border-b-transparent tw-text-neutral-600 hover:tw-border-neutral-200 hover:tw-text-neutral-900": isPrimary
        },
        {
          "!tw-border-b-primary-500 !tw-text-primary-500 hover:tw-border-primary-500 hover:tw-text-primary-500":
            isSelectedPrimary
        },
        className
      )}
      onClick={(e) => {
        e.preventDefault();
        onClick?.();
      }}
    >
      {Icon}
      {label && <p className="tw-whitespace-nowrap tw-text-sm tw-font-medium">{label}</p>}
      {children}
    </a>
  );
};

export const Tabs = Object.assign(Container, { Tab });
