import { Input } from "@shared/primitives/input";

interface MaxDurationControlProps {
  value: number;
  onChange: (value: number) => void;
}

export const MaxDurationControl = ({ value, onChange }: MaxDurationControlProps) => {
  return <Input type="number" value={value} onChange={({ target }) => onChange(Number(target.value))} />;
};
