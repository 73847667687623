import React from "react";
import { useLocation } from "react-router-dom";

import { sublyClickInstallCallback } from "@frontend/api/apps.service";

import { Loader } from "@shared/primitives/loader";

const SUCCESS_REDIRECT_URL = "https://onedrive.live.com/login";

export const SublyClickInstallPage: React.FC = () => {
  const { search } = useLocation();
  const [error, setError] = React.useState("");
  const [isInstalled, setIsInstalled] = React.useState(false);
  const isFirstRun = React.useRef(true);

  const processInstall = async (searchVal: string) => {
    try {
      await sublyClickInstallCallback(searchVal);

      setIsInstalled(true);

      setTimeout(() => {
        window.location.href = SUCCESS_REDIRECT_URL;
      }, 2000);
    } catch (error) {
      console.error("ERROR", error);
      setError(error?.message);
    }
  };

  React.useEffect(() => {
    if (!search) {
      return;
    }

    if (isFirstRun.current) {
      isFirstRun.current = false;
      processInstall(search);
    }

    return () => {
      // clean up effect
    };
  }, []);

  return (
    <div className="tw-flex tw-max-w-[720px] tw-flex-col tw-gap-6 tw-rounded-8 tw-border tw-border-neutral-100 tw-bg-white tw-p-6">
      <div className="tw-flex tw-flex-col tw-gap-2">
        {!isInstalled && !error && (
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <Loader />
            <p className="tw-text-lg tw-font-medium">Installing...</p>
          </div>
        )}
        {isInstalled && (
          <div className="tw-items-center tw-gap-2">
            <p className="tw-text-lg tw-font-medium">Installed!</p>
            <p className="tw-text-lg tw-font-medium">Redirecting to OneDrive</p>
          </div>
        )}
        {Boolean(error) && <p className="tw-text-sm tw-text-destructive-500">{error}</p>}
      </div>
    </div>
  );
};
