import React from "react";
import classNames from "classnames";
import { debounce } from "lodash-es";

import { PositionAlignmentBottom, PositionAlignmentMiddle, PositionAlignmentTop } from "@shared/components/icons";
import { PositionLabel } from "@shared/interfaces/position";
import { Button } from "@shared/primitives/button";
import { Dropdown, DropdownButton, DropdownMenu } from "@shared/primitives/dropdown";
import { Input } from "@shared/primitives/input";
import { NewFieldset } from "@shared/primitives/new-fieldset";

import { AssStyle } from "@getsubly/common";
import { DEFAULT_STYLE } from "@getsubly/common/dist/assParser/subtitle.text";
import { ToolbarButton } from "@media-editor/components/buttons";

const getPosition = (alignment: number): number => {
  return Math.floor((alignment - 1) / 3);
};

const getAlignment = (alignment: number): number => {
  return alignment - getPosition(alignment) * 3;
};

type PositionProps = Pick<AssStyle, "alignment" | "marginL" | "marginR" | "marginV" | "hasDefaultCustomPosition">;

interface TextPositionControlProps {
  value?: PositionProps;
  onUpdate: (value: Partial<PositionProps>) => void;
  disableTabIndex?: boolean;
}

export const TextPositionControl: React.FC<TextPositionControlProps> = ({ value, onUpdate, disableTabIndex }) => {
  if (!value) {
    return null;
  }

  const alignment = getAlignment(value.alignment);
  const position = getPosition(value.alignment);

  const handlePosition = (location: number) => {
    const newPosition = alignment + location * 3;

    onUpdate({ alignment: newPosition });
  };

  return (
    <div className="tw-flex tw-w-full tw-gap-2">
      <div className="tw-flex tw-gap-1 tw-rounded-md tw-border tw-border-neutral-200 tw-p-1">
        <ToolbarButton onClick={() => handlePosition(2)} active={position === 2} disableTabIndex={disableTabIndex}>
          <PositionAlignmentTop />
        </ToolbarButton>
        <ToolbarButton onClick={() => handlePosition(1)} active={position === 1} disableTabIndex={disableTabIndex}>
          <PositionAlignmentMiddle />
        </ToolbarButton>
        <ToolbarButton onClick={() => handlePosition(0)} active={position === 0} disableTabIndex={disableTabIndex}>
          <PositionAlignmentBottom />
        </ToolbarButton>
      </div>
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">
        <Dropdown>
          <DropdownButton
            variant="secondary"
            // className="tw-flex tw-h-[36px] tw-w-full tw-items-center tw-whitespace-nowrap tw-px-3 tw-py-2 tw-font-medium tw-text-neutral-700"
            // bordered
            showArrow={false}
          >
            Adjust position
          </DropdownButton>
          <DropdownMenu placement="bottom-start">
            <PositionOptions value={value} onUpdate={onUpdate} className="tw-p-4" />
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

interface PositionOptionsProps {
  value: PositionProps;
  onUpdate: (value: Partial<PositionProps>) => void;
  className?: string;
  hideReset?: boolean;
}

export const PositionOptions: React.FC<PositionOptionsProps> = ({ value, onUpdate, className, hideReset }) => {
  const position = getPosition(value.alignment);

  const isMiddleAlignment = position === 1;

  const marginL = React.useMemo(() => {
    return value.hasDefaultCustomPosition ? DEFAULT_STYLE.marginL : value.marginL;
  }, [value.marginL]);

  const marginR = React.useMemo(() => {
    return value.hasDefaultCustomPosition ? DEFAULT_STYLE.marginR : value.marginR;
  }, [value.marginR]);

  const marginV = React.useMemo(() => {
    return value.hasDefaultCustomPosition ? DEFAULT_STYLE.marginV : value.marginV;
  }, [value.marginV]);

  const handleChange = (value: Partial<PositionProps>) => {
    const position: Partial<PositionProps> = {
      marginL,
      marginR,
      marginV,
      ...value
    };

    const matchDefault =
      position.marginL === DEFAULT_STYLE.marginL &&
      position.marginR === DEFAULT_STYLE.marginR &&
      position.marginV === DEFAULT_STYLE.marginV;

    onUpdate({
      ...position,
      hasDefaultCustomPosition: matchDefault
    });
  };

  const handleChangeMarginL = (value: number | string) => {
    handleChange({ marginL: Number(value) });
  };

  const handleChangeMarginR = (value: number | string) => {
    handleChange({ marginR: Number(value) });
  };

  const handleChangeMarginV = (value: number | string) => {
    handleChange({ marginV: Number(value) });
  };

  const debouncedHandleChangeMarginL = debounce(handleChangeMarginL, 50);
  const debouncedHandleChangeMarginR = debounce(handleChangeMarginR, 50);
  const debouncedHandleChangeMarginV = debounce(handleChangeMarginV, 50);

  const resetAdjustment = () => {
    onUpdate({
      marginL: DEFAULT_STYLE.marginL,
      marginR: DEFAULT_STYLE.marginR,
      marginV: DEFAULT_STYLE.marginV,
      hasDefaultCustomPosition: true
    });
  };

  return (
    <div className={classNames("tw-flex tw-flex-col tw-gap-5", className)}>
      <div className="tw-flex tw-flex-row tw-gap-6">
        <NewFieldset title="Left" tooltipText="Margin left">
          <Input
            type="number"
            hasArrows
            step={10}
            value={marginL}
            onChange={({ target }) => debouncedHandleChangeMarginL(Number(target.value))}
            after="px"
            className="!tw-w-[128px]"
          />
        </NewFieldset>
        <NewFieldset title="Right" tooltipText="Margin right">
          <Input
            type="number"
            hasArrows
            step={10}
            value={marginR}
            onChange={({ target }) => debouncedHandleChangeMarginR(Number(target.value))}
            after="px"
            className="!tw-w-[128px]"
          />
        </NewFieldset>
        {!isMiddleAlignment && (
          <NewFieldset
            title={PositionLabel[position]}
            tooltipText={`Margin ${PositionLabel[position]?.toLowerCase() || ""}`}
          >
            <Input
              type="number"
              hasArrows
              step={10}
              value={marginV}
              onChange={({ target }) => debouncedHandleChangeMarginV(Number(target.value))}
              after="px"
              className="!tw-w-[128px]"
            />
          </NewFieldset>
        )}
      </div>

      <div className="tw-flex tw-flex-col tw-gap-2">
        {!hideReset && (
          <Button variant="ghost" onClick={resetAdjustment} size="36">
            Reset to default
          </Button>
        )}
      </div>
    </div>
  );
};
