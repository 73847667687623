import { AlignBottomLeftIcon, AlignBottomRightIcon, AlignTopLeftIcon, AlignTopRightIcon } from "./icons";

export enum Position {
  TopLeft = "Top Left",
  TopRight = "Top Right",
  BottomLeft = "Bottom Left",
  BottomRight = "Bottom Right",
  BottomCenter = "Bottom Center"
}

interface PositionIconProps {
  position: Position;
}
export const PositionIcon: React.FC<PositionIconProps> = ({ position }) => {
  switch (position) {
    case Position.TopLeft:
      return <AlignTopLeftIcon />;

    case Position.TopRight:
      return <AlignTopRightIcon />;

    case Position.BottomLeft:
      return <AlignBottomLeftIcon />;

    case Position.BottomRight:
      return <AlignBottomRightIcon />;
    default:
      return null;
  }
};
