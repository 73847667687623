import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import { AppId, Integration } from "@frontend/api/apps.service";
import { AppModal } from "@frontend/components/modals/app-modal/app-modal";
import { OneDriveModal } from "@frontend/components/modals/onedrive-modal";
import { AppCard } from "@frontend/components/settings/apps/app-card/app-card";
import { SuggestionAppCard } from "@frontend/components/settings/apps/app-card/suggest-app-card";

import { useModal } from "@shared/hooks/use-modal";
import { App } from "@shared/interfaces/app";
import { ModalType } from "@shared/interfaces/modal-type";

import styles from "./app-list.module.scss";

interface AppListProps {
  apps: App[];
  integrations: Integration[];
  isLoading: boolean;
  showSuggestionCard: boolean;
  onReload?: () => void;
}
export const AppList: React.FC<AppListProps> = ({ apps, integrations, isLoading, showSuggestionCard, onReload }) => {
  const [showModal, hideModal] = useModal(ModalType.AppModal);
  const navigate = useNavigate();

  const oneDriveApp = React.useMemo(() => apps.find((a) => a.appId === AppId.OneDrive), [apps]);
  const oneDriveConnections = React.useMemo(
    () => integrations.filter((a) => a.appId === AppId.OneDrive),
    [integrations]
  );

  const otherApps = React.useMemo(() => apps.filter((a) => ![AppId.OneDrive].includes(a.appId) && !a.hidden), [apps]);

  if (isLoading) {
    return (
      <div className="tw-grid tw-grid-cols-2 tw-gap-4 md:tw-grid-cols-3 lg:tw-grid-cols-4">
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </div>
    );
  }

  return (
    <div className="tw-grid tw-grid-cols-2 tw-gap-4 md:tw-grid-cols-3 lg:tw-grid-cols-4">
      {oneDriveApp && (
        <AppCard
          app={oneDriveApp}
          onClick={() => {
            if (oneDriveConnections.length) {
              showModal(
                <OneDriveModal
                  app={oneDriveApp}
                  connections={oneDriveConnections}
                  closeModal={(reload) => {
                    if (reload) {
                      onReload?.();
                    }
                    hideModal();
                  }}
                />
              );
            } else {
              showModal(<AppModal app={oneDriveApp} closeModal={hideModal} />);
            }
          }}
        />
      )}
      {otherApps.length &&
        otherApps.map((app, i) => (
          <AppCard
            key={i}
            app={app}
            onClick={() => {
              const connected = integrations.find((a) => a.appId === app.appId);
              if (connected) {
                navigate(app.link);
              } else {
                showModal(<AppModal app={app} closeModal={hideModal} />);
              }
            }}
          />
        ))}
      {showSuggestionCard && <SuggestionAppCard />}
    </div>
  );
};

const SkeletonCard: React.FC = () => {
  return (
    <div className={styles.skeletonCard}>
      <Skeleton height={30} width={30} className="tw-mb-4" />
      <Skeleton height={30} className="tw-mb-2" />
      <Skeleton width="400px" count={2} />
    </div>
  );
};
